const sortByDescAndGroup = (a, b) => {
  const groupNameComparison = a.groupName.localeCompare(b.groupName);

  if (groupNameComparison !== 0) {
    return groupNameComparison;
  } else {
    return a.description.localeCompare(b.description);
  }
}

export { sortByDescAndGroup }
