import ReactEChartsCore from "echarts-for-react";
import * as echarts from "echarts/core";
import { useEffect, useState } from "react";
import { getTime } from "date-fns";
import tinycolor from "tinycolor2";
import { COLORS_MAP } from "../../../constants";
import EmptyChart from "./EmptyChart";
import { SettingsSizeOptions } from "../../../utils/constants/selectOptions";
import { useSelector } from "react-redux";
import { ScaleOptionsEnum } from "../../create-widget/regular-chart/constants";

const randomColors = [];

for (let i = 0; i < 100; i++) {
  randomColors.push(tinycolor.random().toHexString());
}

const ChartStaticTable = (
  {
    fgColor,
    series,
    columns = [],
    chartChartType,
    chartSmoothType,
    chartLineWidth,
    settingsChartSize,
    settingsYaxisScale,
    settingsMaximum,
    settingsMinimum,
    settingsFormat,
    settingsShowRefreshButtons,
    settingsShowTitle
  },
) => {
  const isAutoScale = settingsYaxisScale === ScaleOptionsEnum.auto;
  const [chartInstance, setChartInstance] = useState(null);
  const timeZone = useSelector(
    (state) => state.settings.timezone
  );

  const transformForChart = (before) => {
    const temp = {};

    const checkValue = (value) => {
      if (value === "n/a") {
        return null;
      }

      return value;
    };

    const getLabel = (value) => {
      if (!value) {
        return "n/a";
      }

      const column = columns.find(column => column.value.value === value);

      if (column?.alias) {
        return `${column.alias} (${column.mode})`;
      }

      return `${column?.value?.title.split("/")[1] || "n/a"} (${column?.mode})`;
    };

    const getColor = (index) => {
      const preDefinedColors = Object.values(COLORS_MAP);

      if (index < preDefinedColors.length) {
        return preDefinedColors[index];
      } else {
        return randomColors[index];
      }
    };

    before.forEach((item) => {
      item.data.forEach((dataItem, index) => {
        if (!temp[dataItem.propertyId]) {
          temp[dataItem.propertyId] = {
            data: [],
            name: getLabel(dataItem.propertyId),
            color: getColor(index),
            label: getLabel(dataItem.propertyId),
          };
        }

        temp[dataItem.propertyId].data.push({
          x: getTime(new Date(item?.timestamp || item?.time)),
          y: checkValue(dataItem.value),
        });
      });
    });

    return Object.values(temp);
  };

  const [data, setData] = useState(transformForChart(series));

  let step = "";
  let smooth = true;

  switch (chartSmoothType) {
    case "linear":
      smooth = false;
      break;
    case "stepwise":
      step = "start";
      break;
  }

  const getChartHeight = () => {
    if (settingsFormat === 'chart') {
      if (settingsShowTitle && settingsShowRefreshButtons) {
        return 'calc(100% - 98px)'
      }

      if (settingsShowTitle || settingsShowRefreshButtons) {
        return 'calc(100% - 48px)'
      }

      return '100%'
    }
    switch (settingsChartSize) {
      case SettingsSizeOptions.small:
        return "96px";
      case SettingsSizeOptions.large:
        return "396px";
      default:
        return "196px";
    }
  };

  const chartOptions = {
    useUTC: false,
    animation: false,
    color: fgColor,
    grid: {
      top: settingsChartSize === SettingsSizeOptions.small ? 10 : 35,
      right: 6,
      bottom: settingsChartSize === SettingsSizeOptions.small ? -12 : 6,
      left: 6,
      containLabel: true,
    },
    xAxis: {
      animationThreshold: 500,
      show: settingsChartSize !== SettingsSizeOptions.small,
      type: "time",
      axisTick: {
        lineStyle: { color: fgColor, opacity: 0.3 },
        show: true,
      },
      splitLine: {
        lineStyle: { color: fgColor, opacity: 0.3 },
        show: true,
      },
      nameLocation: "center",
      nameTextStyle: {
        align: "center",
      },
      axisLabel: {
        hideOverlap: true,
        // formatter(valueAxis) {
        //   // return axisLabelFormatter(valueAxis, settingsTimeInterval);
        // },
      },
      axisLine: {
        lineStyle: { color: fgColor, opacity: 0.3 },
        show: true,
      },
    },
    barMaxWidth: 40,
    barMinWidth: 10,
    yAxis: {
      min: isAutoScale ? undefined : settingsMinimum,
      max: isAutoScale ? undefined : settingsMaximum,
      animationThreshold: 500,
      nameLocation: "center",
      // axisLabel: {
      //   // formatter: `{value} ${styleChartYaxisLabel || ""}`,
      // },
      type: "value",
      axisTick: {
        lineStyle: { color: fgColor, opacity: 0.3 },
        show: true,
      },
      splitLine: {
        lineStyle: { color: fgColor, opacity: 0.3 },
        show: true,
      },
      scale: false,
      axisLine: {
        lineStyle: { color: fgColor, opacity: 0.3 },
        show: true,
      },
    },
    dataZoom: [
      {
        type: "inside",
        zoomOnMouseWheel: "ctrl",
      },
    ],
    textStyle: {
      color: fgColor,
    },
    legend: {
      type: 'scroll',
      show: settingsChartSize !== SettingsSizeOptions.small,
      selectedMode: columns.length === 1 ? false : "multiply",
      itemWidth: 20,
      itemHeight: 14,
      icon: "circle",
      textStyle: {
        fontSize: "14",
        fontWeight: "bold",
        color: fgColor,
      },
      pageIconColor: fgColor,
      pageIconInactiveColor: '#cccccc',
      pageIconSize: [14, 14],
      pageTextStyle: {
        color: fgColor,
        fontSize: 12
      },
      orient: "horizontal",
      itemGap: 20,
      left: 0,
    },
    series: data.map((item) => ({
      data: item.data.map((point) => {
          if (point.x || point.y) {
            return {
              id: `${point.x}_${point.y}`,
              value: [point.x, point.y],
            };
          } else {
            return {};
          }
        }),
      sampling: "lttb",
      showSymbol: item.data.filter((item) => item.y).length <= 10,
      type: chartChartType,
      lineStyle: {
        width: Number(chartLineWidth),
        color: item.color,
      },
      smooth,
      step,
      name: item.label || item.name,
      areaStyle: {
        opacity: 0,
        color: item.color,
      },
      emphasis: {
        lineStyle: {
          color: item.color,
        },
        areaStyle: {
          color: item.color,
          opacity: 0,
        },
      },
      itemStyle: { color: item.color },
    })),
    tooltip: {
      confine: true,
      trigger: "axis",
      textStyle: {
        align: "left",
      },
    },
  };

  useEffect(() => {
    // chartInstance?.clear();
    setData(transformForChart(series))
  }, [series]);

  return (
    <>
      {series.length > 0 && (
        <ReactEChartsCore
          echarts={echarts}
          option={chartOptions}
          onChartReady={setChartInstance}
          lazyUpdate={true}
          style={{ width: "100%", height: getChartHeight(), flexShrink: 0, padding: '0 12px' }}
        />
      )}
      {series.length === 0 && (
        <div style={{
          borderBottom: settingsFormat !== 'chart' ? `1px solid ${tinycolor(fgColor).setAlpha("0.3")}` : '',
          width: "100%",
          height: getChartHeight(),
          flexShrink: 0,
        }}>
          <EmptyChart fgColor={fgColor} />
        </div>
      )}
    </>
  );
};

export default ChartStaticTable;
