import { gql, useLazyQuery, useMutation } from "@apollo/client";
import { DragIndicator } from "@mui/icons-material";
import AddIcon from "@mui/icons-material/Add";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import { useTheme } from "@mui/material/styles";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import makeStyles from "@mui/styles/makeStyles";
import { isPast } from "date-fns";
import { loader } from "graphql.macro";
import { isEqual } from "lodash";
import React, { useEffect, useState } from "react";
import { Responsive, WidthProvider } from "react-grid-layout";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import useBus from "use-bus";
import Spinner from "../components/Spinner";
import { DASHBOARD_STREAM_DATA_TIME, mediaServer } from "../constants";
import { msg } from "../messages";
import { setContext } from "../store/contextSlice";
import { setSettings } from "../store/settingsSlice";
import useMedia from "../utils/useMedia";
import DashboardControls from "./common/DashboardControls";
import SelectWidget from "./create-widget/SelectWidget";
import GroupControls from "./dashboard/GroupControls";
import useCustomNavigate from "./hooks/useCustomNavigate";
import W_ColoredChart from "./widgets/chart/W_ColoredChart";
import W_DataChart from "./widgets/chart/W_DataChart";
import W_Title from "./widgets/title/W_Title";
import W_TrackingTable from "./widgets/tracking-table/W_TrackingTable";
import W_AdvancedButton from "./widgets/W_AdvancedButton";
import W_CommandButton from "./widgets/W_CommandButton";
import W_DataBox from "./widgets/W_DataBox";
import W_DateTime from "./widgets/W_DateTime";
import W_DiagramChart from "./widgets/W_DiagramChart";
import W_HistoryTable from "./widgets/W_HistoryTable";
import W_MonitorStatusTable from "./widgets/W_MonitorStatusTable";
import W_MonitorTable from "./widgets/W_MonitorTable";
import W_StaticTable from "./widgets/static-table/index";
import W_Timer from "./widgets/W_Timer";

const UPDATE_PROPERTY = loader("../graphql/UpdatePropertyMutation.graphql");
const DASHBOARD_QUERY = loader("../graphql/DashboardQuery.graphql");
const UPDATE_PROPERTIES_BY_IDS = loader(
  "../graphql/UpdateObjectPropertiesByIdMutation.graphql"
);
const CHECK_PERMISSIONS = gql`
  mutation resolveObjectPermissions($input: ResolveObjectPermissionsInput!) {
    resolveObjectPermissions(input: $input) {
      accessRight {
        edit
      }
    }
  }
`;

const CREATE_EXECUTION = gql`
  mutation createControlsExecution($input: CreateControlExecutionInput!) {
    createControlExecution(input: $input) {
      controlExecution {
        params
        name
        nodeId
        linkedControlId
        objectId
        id
      }
      clientMutationId
    }
  }
`;

const ResponsiveGridLayout = WidthProvider(Responsive);

const useStyles = makeStyles((theme) => ({
  wrapper: {
    position: "relative",
    paddingRight: "3px",
    paddingLeft: "3px",
  },
  addContainerButton: {
    marginTop: "5px",
    marginLeft: "5px",
    marginBottom: "5px",
    padding: 0,
    borderRadius: "20%",
    boxShadow: "none",
    height: "40px",
    width: "40px",
    color: theme.palette.white,
    "&:hover": {
      backgroundColor: theme.palette.gray1,
    },
  },
  groupContent: {
    // margin: "100px"
  },
  groupWrapper: {
    position: "relative",
  },
  containerWrapper: {
    borderRadius: "2px",
    // backgroundColor: 'red',
  },
}));

const Dashboard = React.memo((props) => {
  const {
    setAddGroupModalState,
    setEditWidgetModalState,
    setDeleteWidgetModalState,
    isReport,
  } = props;
  const classes = useStyles();
  const theme = useTheme();
  const { dashboardId } = useParams();

  const dispatch = useDispatch();

  const history = useCustomNavigate();

  const isEditMode = useSelector((state) => state.settings.isEditMode);
  const isViewMode = useSelector((state) => state.settings.isViewMode);
  const isFullScreen = useSelector((state) => state.settings.isFullScreen);
  const isShowGrid = useSelector((state) => state.settings.isShowGrid);
  const prevBoard = useSelector((state) => state.settings.prevBoard);
  const objectHoveredId = useSelector((state) => state.context.objectHoveredId);

  const makeDashboardAlive = (dashboardData) => {
    if (isReport) return;
    const dataLiveDataExpiresAt =
      dashboardData?.dashboard?.objectProperties.find(
        (prop) => prop.key === "dataLiveDataExpiresAt"
      )?.value;

    if (!dataLiveDataExpiresAt || isPast(dataLiveDataExpiresAt * 1000)) {
      createExecution({
        variables: {
          input: {
            controlExecution: {
              name: "StreamDataToDashboard",
              objectId: dashboardId,
              params: {
                initialize_data: true,
                stream_data_for: DASHBOARD_STREAM_DATA_TIME,
              },
            },
          },
        },
      }).then((res) => {});
    } else {
      createExecution({
        variables: {
          input: {
            controlExecution: {
              name: "StreamDataToDashboard",
              objectId: dashboardId,
              params: {
                initialize_data: false,
                stream_data_for: DASHBOARD_STREAM_DATA_TIME,
              },
            },
          },
        },
      }).then((res) => {});
    }
  };

  const [loadDashboard, { data: dashboardData }] = useLazyQuery(
    DASHBOARD_QUERY,
    {
      variables: {
        dashboardId,
      },
      fetchPolicy: "network-only",
    }
  );

  const [createExecution, { loading }] = useMutation(CREATE_EXECUTION);

  const [widgetsPositionsWithinGroups, setWidgetsPositionsWithinGroups] =
    useState({});
  const [
    containerPositionWithinDashboard,
    setContainerPositionWithinDashboard,
  ] = useState([]);

  const [updateProperties] = useMutation(UPDATE_PROPERTIES_BY_IDS);

  const dashboardTitle = dashboardData?.dashboard?.objectProperties.find(
    (prop) => prop.key === "generalTitle"
  ).value;

  const streamingDashboard = dashboardData?.dashboard?.objectProperties.find(
    (prop) => prop.key === "dataLiveDataExpiresAt"
  )?.value;

  const dashboardTitleStyle = dashboardData?.dashboard?.objectProperties.find(
    (prop) => prop.key === "generalTitleStyle"
  ).value;

  const dashboardBackgroundImageUID =
    dashboardData?.dashboard?.objectProperties.find(
      (prop) => prop.key === "generalBackgroundImageUid"
    ).value;

  const dashboardBackgroundImageName =
    dashboardData?.dashboard?.objectProperties.find(
      (prop) => prop.key === "generalBackgroundImageName"
    ).value;

  const dashboardBackgroundColor =
    dashboardData?.dashboard?.objectProperties?.find(
      (prop) => prop.key === "generalBgColor"
    )?.value;

  const [checkPermissions, { loading: isCheckingPermission }] =
    useMutation(CHECK_PERMISSIONS);

  const [addWidgetModalState, setAddWidgetModalState] = useState({
    open: false,
    groupId: null,
    widgetIdClicked: null,
    dashboardId: dashboardId,
  });

  useEffect(() => {
    if (
      !isEqual(prevBoard, {
        widgetsPositionsWithinGroups,
        containerPositionWithinDashboard,
      })
    ) {
      dispatch(setSettings({ isBoardSaved: false }));
    }
  }, [containerPositionWithinDashboard, widgetsPositionsWithinGroups]);

  useEffect(() => {
    checkPermissions({
      variables: {
        input: {
          objectId: dashboardId,
        },
      },
    })
      .then(({ data }) => {
        dispatch(
          setSettings({
            isMayEdit: data.resolveObjectPermissions.accessRight.edit,
          })
        );
        return loadDashboard();
      })
      .then(({ data }) => {
        makeDashboardAlive(data);
      });
  }, [dashboardId]);

  const handleUpdateWidget = async (props) => {}; //handleUpdateWidget

  const getTypeOfWidget = (schemaTags) => {
    return schemaTags[3];
  };

  const visibleGroups = (dashboardData) => {
    const groups = dashboardData.dashboard.groups;
    const layouts = dashboardData.dashboard.layouts[0].value.lg;

    return groups.filter(({ group }) =>
      Boolean(layouts.find((layoutCell) => layoutCell.i === group.id))
    );
  };

  const getGroupLayouts = (group) => {
    const localPositionWithinGroup =
      widgetsPositionsWithinGroups[group.layouts[0].id];
    const fromServerPositionWithinGroup = group.layouts[0].value;

    if (localPositionWithinGroup) {
      return {
        lg: localPositionWithinGroup,
        md: localPositionWithinGroup,
        sm: localPositionWithinGroup,
        xs: localPositionWithinGroup,
        xxs: localPositionWithinGroup,
      };
    } else {
      return {
        lg: fromServerPositionWithinGroup,
        md: fromServerPositionWithinGroup,
        sm: fromServerPositionWithinGroup,
        xs: fromServerPositionWithinGroup,
        xxs: fromServerPositionWithinGroup,
      };
    }
  };

  const isMinimap = (group) => group.type[0]?.value === "minimap";

  const getGroupCols = (group) => {
    const isMinimap = group.type[0]?.value === "minimap";

    const localCols = isMinimap
      ? containerPositionWithinDashboard.find((t) => t.i === group.id)?.w * 4
      : containerPositionWithinDashboard.find((t) => t.i === group.id)?.w * 2;
    const fromServerCols = isMinimap
      ? group.columnsCount[0].value * 4
      : group.columnsCount[0].value * 2;

    if (localCols) {
      return {
        lg: localCols,
        md: localCols,
        sm: localCols,
        xs: localCols,
        xxs: localCols,
      };
    } else {
      return {
        lg: fromServerCols,
        md: fromServerCols,
        sm: fromServerCols,
        xs: fromServerCols,
        xxs: fromServerCols,
      };
    }
  };

  const getGroupRowHeight = (group) => {
    const type = group.type[0].value;

    if (type === "group" || !type) {
      return 50;
    } else {
      return 25;
    }
  };

  const visibleWidgets = (group) => {
    const widgets = group?.widgets;
    const layouts = group?.layouts?.[0]?.value;

    if (!group) return [];
    if (!layouts) return [];

    return widgets
      .filter(item => item)
      .filter(({ widget }) => Boolean(layouts.find((layoutCell) => layoutCell.i === widget?.id))
    );
  };

  const saveBoard = (e, isSilent) => {
    if (!dashboardData?.dashboard.groups.length) {
      return;
    }

    const propertiesArray = [];

    for (let prop in widgetsPositionsWithinGroups) {
      propertiesArray.push({
        propertyId: prop,
        value: widgetsPositionsWithinGroups[prop],
      });
    }

    containerPositionWithinDashboard.forEach((layout) => {
      const assosiatedGroup = dashboardData.dashboard.groups.find((item) => {
        return item.group.id === layout.i;
      })?.group.objectProperties;

      const generalColumnsCount = assosiatedGroup.find(
        (property) => property.key === "generalColumnsCount"
      );
      const generalRowsCount = assosiatedGroup.find(
        (property) => property.key === "generalRowsCount"
      );

      propertiesArray.push({
        propertyId: generalColumnsCount.id,
        value: layout.w,
      });
      propertiesArray.push({
        propertyId: generalRowsCount.id,
        value: layout.h,
      });
    });

    if (containerPositionWithinDashboard.length) {
      propertiesArray.push({
        propertyId: dashboardData.dashboard.layouts[0].id,
        value: {
          lg: containerPositionWithinDashboard,
          md: containerPositionWithinDashboard,
          sm: containerPositionWithinDashboard,
          xs: containerPositionWithinDashboard,
          xxs: containerPositionWithinDashboard,
        },
      });
    }

    if (!propertiesArray.length) return;

    if (!isSilent) {
      toast
        .promise(
          updateProperties({ variables: { input: { propertiesArray } } }),
          {
            loading: "Autosaving positions...",
            success: () => `All positions successfully saved`,
            error: (err) => `${err.toString()}`,
          }
        )
        .then(() => {
          dispatch(setSettings({ isBoardSaved: true }));
          dispatch(
            setSettings({
              prevBoard: {
                widgetsPositionsWithinGroups,
                containerPositionWithinDashboard,
              },
            })
          );
          window.dispatchEvent(new Event("resize"));
        });
    } else {
      updateProperties({ variables: { input: { propertiesArray } } }).then(
        () => {
          dispatch(setSettings({ isBoardSaved: true }));
          window.dispatchEvent(new Event("resize"));
        }
      );
    }
  };

  const getGridGroups = (group) => {
    return dashboardData.dashboard.layouts[0].value.lg.find(
      (item) => item.i === group.id
    );
  };

  const getGridWidgets = (group, widgetId) => {
    return group.layouts[0].value.find((item) => item.i === widgetId);
  };

  const getBackgroundColorOfContainer = (group) => {
    if (isEditMode && group.bg[0].value === "transparent") {
      return "rgba(39, 128, 227, 0.5)";
    } else {
      return group.bg[0].value;
    }
  };
  const { getImageById } = useMedia();

  const getBackgroundImageOfContainer = (group) => {
    if (group.bgImage[0].value) {
      return `url(${getImageById(group.bgImage[0].value)})`;
    } else {
      return "";
    }
  };

  useBus(
    "@@board/SAVE_BOARD",
    (e) => {
      saveBoard("", true);
    },
    [widgetsPositionsWithinGroups]
  );

  useBus(
    "@@board/RESET_DASHBOARD_LOCAL_STATE",
    (e) => {
      setWidgetsPositionsWithinGroups({});
    },
    []
  );

  if (!dashboardData?.dashboard) return <Spinner />;

  return (
    <>
      {!isViewMode && (
        <DashboardControls
          saveBoardCb={saveBoard}
          setAddGroupModalState={setAddGroupModalState}
          dashboardId={dashboardId}
        />
      )}
      <div
        className={classes.wrapper}
        data-test-board-bg={dashboardBackgroundColor}
        style={{
          backgroundImage: dashboardBackgroundImageUID
            ? `url(${getImageById(dashboardBackgroundImageUID)})`
            : "none",
          backgroundColor: dashboardBackgroundColor,
          backgroundSize: "cover",
          backgroundRepeat: "repeat-y",
          backgroundClip: "border-box",
          backgroundAttachment: "scroll",
          overflow: "auto",
          minHeight: "100%",
        }}
      >
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          style={{ height: "56px" }}
        >
          <Typography
            data-board-title={dashboardTitle}
            variant="h5"
            style={{
              color:
                dashboardTitleStyle === "light"
                  ? theme.palette.white
                  : theme.palette.blue,
            }}
          >
            {dashboardTitle}
          </Typography>
        </Grid>

        {!isEditMode &&
          dashboardData.dashboard.layouts[0].value["xs"].length === 0 && (
            <Typography
              variant="h6"
              style={{
                position: "absolute",
                left: 0,
                right: 0,
                margin: "0 auto",
                top: "50%",
                transform: "translateY(-50%)",
                textAlign: "center",
                color: theme.palette.blue,
              }}
            >
              {msg.dashboard.noContainers}
            </Typography>
          )}

        <div className="dashboard-container" style={{ width: "2500px", paddingBottom: '200px' }}>
          <ResponsiveGridLayout
            className="layout"
            layouts={dashboardData.dashboard.layouts[0].value}
            cols={{ lg: 25, md: 25, sm: 25, xs: 25, xxs: 25 }}
            rowHeight={50}
            margin={[0, 0]}
            containerPadding={[0, 0]}
            compactType={null}
            isDraggable={isEditMode}
            isResizable={isEditMode}
            isBounded={false}
            allowOverlap={false}
            preventCollision={true}
            resizeHandle={
              <div
                data-group-resize-handle
                className="react-resizable-handle react-resizable-handle-se"
              ></div>
            }
            draggableHandle={isEditMode ? ".handleGroup" : null}
            measureBeforeMount={true}
            onDragStop={(layout) => {
              setContainerPositionWithinDashboard(layout);
              window.dispatchEvent(new Event("resize"));
            }}
            onResizeStop={(layout) => {
              setContainerPositionWithinDashboard(layout);
              window.dispatchEvent(new Event("resize"));
            }}
            onLayoutChange={(layout) => {
              setContainerPositionWithinDashboard(layout);
              window.dispatchEvent(new Event("resize"));
            }}
          >
            {visibleGroups(dashboardData).map(({ group }) => {
              return (
                <div
                  style={{ height: "100%" }}
                  data-grid={{ ...getGridGroups(group) }}
                  key={group.id}
                  data-id={`t-${group.id}`}
                >
                  <div
                    onClick={(e) => {
                      dispatch(
                        setSettings({
                          isShowHistory: false,
                          isShowControlsHistory: false,
                          isShowHistoryProperty: false,
                        })
                      )
                      if (
                        !isFullScreen &&
                        (e.target.dataset.targetEmptyGroup ||
                          e.target.dataset.targetGroup)
                      ) {
                        history(`/boards/${dashboardId}/${group.id}`);
                      }
                    }}
                    data-target-group={true}
                    data-id={`pdf-${group.id}`}
                    className={
                      group.bgImage[0].value ? classes.groupWrapper : null
                    }
                    style={{
                      position: "relative",
                      width: "100%",
                      height: "100%",
                      overflow: "hidden",
                      cursor: !isFullScreen ? "pointer" : "default",
                    }}
                  >
                    {isEditMode && (
                      <>
                        <div
                          onClick={(e) => {
                            dispatch(
                              setSettings({
                                isShowHistory: false,
                                isShowControlsHistory: false,
                                isShowHistoryProperty: false,
                              })
                            )
                            history(`/boards/${dashboardId}/${group.id}`);
                          }}
                          className={"handleGroup"}
                          style={{
                            cursor: "grab",
                            width: "24px",
                            height: "24px",
                            backgroundColor: "rgba(255, 255, 255, 0.44)",
                            position: "absolute",
                            right: "0",
                            zIndex: "999",
                          }}
                        >
                          <DragIndicator
                            data-test={1}
                            data-drag-handle={"group"}
                            style={{ color: "#686868" }}
                          />
                        </div>

                        {isMinimap(group) && isShowGrid && isEditMode && (
                          <div
                            data-target-group="1"
                            style={{
                              position: "absolute",
                              width: "100%",
                              height: "100%",
                              backgroundImage: "url('/gridPattern.svg')",
                              backgroundRepeat: "repeat",
                              opacity: 0.5,
                              backgroundSize: "25px",
                            }}
                          ></div>
                        )}
                        {!isMinimap(group) && isShowGrid && isEditMode && (
                          <div
                            data-target-group="1"
                            style={{
                              position: "absolute",
                              width: "100%",
                              height: "100%",
                              backgroundImage: "url('/gridPatternGroup.svg')",
                              backgroundRepeat: "repeat",
                              opacity: 0.5,
                              backgroundSize: "50px",
                            }}
                          ></div>
                        )}
                        {Boolean(group.widgets.length) && (
                          <div
                            style={{
                              cursor: "pointer",
                              width: "24px",
                              height: "24px",
                              backgroundColor: "rgba(255, 255, 255, 0.44)",
                              position: "absolute",
                              left: "0",
                              zIndex: "999",
                            }}
                          >
                            <Tooltip title={msg.dashboard.addWidget}>
                              <IconButton
                                size={"small"}
                                style={{ padding: 0 }}
                                onClick={(e) => {
                                  SelectWidget({
                                    group: group,
                                    groupId: group.id,
                                  })
                                    .then()
                                    .catch(() => {});
                                }}
                              >
                                <AddIcon style={{ color: "#686868" }} />
                              </IconButton>
                            </Tooltip>
                          </div>
                        )}
                      </>
                    )}
                    {group.widgets.length === 0 && (
                      <GroupControls
                        group={group}
                        backgroundImage={getBackgroundImageOfContainer(group)}
                        backgroundColor={getBackgroundColorOfContainer(group)}
                        isHasWidgets={group.widgets.length}
                      />
                    )}

                    {visibleWidgets(group).length !== 0 && (
                      <div
                        data-target-group="1"
                        onClick={(e) => {
                          dispatch(
                            setSettings({
                              isShowHistory: false,
                              isShowControlsHistory: false,
                              isShowHistoryProperty: false,
                            })
                          );

                          if (
                            !isFullScreen &&
                            e.target.classList.value ===
                              "react-grid-layout layout"
                          ) {
                            history(`/boards/${dashboardId}/${group.id}`);
                          }
                        }}
                        style={{
                          backgroundSize: "cover",
                          backgroundPosition: "center",
                          backgroundRepeat: "no-repeat",
                          backgroundImage: getBackgroundImageOfContainer(group),
                          backgroundColor: getBackgroundColorOfContainer(group),
                          height: "100%",
                          width: "100%",
                          overflow: "hidden",
                          borderRadius: "2px",
                        }}
                      >
                        <ResponsiveGridLayout
                          className="layout"
                          layouts={getGroupLayouts(group)}
                          cols={getGroupCols(group)}
                          margin={[0, 0]}
                          containerPadding={[0, 0]}
                          isBounded={false}
                          isResizable={isEditMode}
                          resizeHandle={
                            <div
                              data-widget-resize-handle
                              className="react-resizable-handle react-resizable-handle-se"
                            ></div>
                          }
                          onDragStop={(layout) => {
                            setWidgetsPositionsWithinGroups({
                              ...widgetsPositionsWithinGroups,
                              [group.layouts[0].id]: layout,
                            });

                            window.dispatchEvent(new Event("resize"));
                          }}
                          onResizeStop={(layout) => {
                            setWidgetsPositionsWithinGroups({
                              ...widgetsPositionsWithinGroups,
                              [group.layouts[0].id]: layout,
                            });

                            window.dispatchEvent(new Event("resize"));
                          }}
                          onLayoutChange={(layout) => {
                            // addedWidget = group.layouts[0].value.filter((item) => !widgetsPositionsWithinGroups[group.layouts[0].id].find(itemInner => itemInner.i === item.i))
                            setWidgetsPositionsWithinGroups({
                              ...widgetsPositionsWithinGroups,
                              [group.layouts[0].id]: layout,
                            });

                            window.dispatchEvent(new Event("resize"));
                          }}
                          preventCollision={false}
                          allowOverlap={true}
                          measureBeforeMount={true}
                          isDraggable={isEditMode}
                          compactType={null}
                          rowHeight={getGroupRowHeight(group)}
                        >
                          {visibleWidgets(group).map(({ widget }) => {
                            let w = null;
                            const handleClickWidget = function () {
                              dispatch(setSettings({
                                isShowHistory: false,
                                isShowControlsHistory: false,
                                isShowHistoryProperty: false
                              }))
                              if (!isFullScreen) {
                                history(
                                  `/boards/${dashboardId}/${group.id}/${widget.id}`
                                );
                              }
                            };
                            switch (getTypeOfWidget(widget.schemaTags)) {
                              case "databox":
                                w = (
                                  <W_DataBox
                                    object={widget}
                                    widgetType="databox"
                                    {...widget}
                                    groupNames={[
                                      { groupName: { equalTo: "Settings" } },
                                    ]}
                                    group={group}
                                    groupId={group.id}
                                    order={group.order}
                                    isResizable={false}
                                    dashboardId={dashboardId}
                                    setDeleteWidgetModalState={
                                      setDeleteWidgetModalState
                                    }
                                    setAddWidgetModalState={
                                      setAddWidgetModalState
                                    }
                                    saveBoard={saveBoard}
                                    setEditWidgetModalState={
                                      setEditWidgetModalState
                                    }
                                    selected={false}
                                    handleUpdateWidget={handleUpdateWidget}
                                    handleClickWidget={handleClickWidget}
                                  />
                                );
                                break;
                              case "timer":
                                w = (
                                  <W_Timer
                                    object={widget}
                                    widgetType="timer"
                                    {...widget}
                                    groupNames={[
                                      { groupName: { equalTo: "Settings" } },
                                    ]}
                                    group={group}
                                    groupId={group.id}
                                    order={group.order}
                                    isResizable={false}
                                    dashboardId={dashboardId}
                                    setDeleteWidgetModalState={
                                      setDeleteWidgetModalState
                                    }
                                    setAddWidgetModalState={
                                      setAddWidgetModalState
                                    }
                                    saveBoard={saveBoard}
                                    setEditWidgetModalState={
                                      setEditWidgetModalState
                                    }
                                    selected={false}
                                    handleUpdateWidget={handleUpdateWidget}
                                    handleClickWidget={handleClickWidget}
                                  />
                                );
                                break;
                              case "datachart":
                                w = (
                                  <W_DataChart
                                    object={widget}
                                    key={widget.id}
                                    widgetType="datachart"
                                    {...widget}
                                    dashboardId={dashboardId}
                                    groupNames={[
                                      { groupName: { equalTo: "Settings" } },
                                    ]}
                                    group={group}
                                    groupId={group.id}
                                    order={group.order}
                                    setDeleteWidgetModalState={
                                      setDeleteWidgetModalState
                                    }
                                    setAddWidgetModalState={
                                      setAddWidgetModalState
                                    }
                                    setEditWidgetModalState={
                                      setEditWidgetModalState
                                    }
                                    selected={false}
                                    saveBoard={saveBoard}
                                    handleUpdateWidget={handleUpdateWidget}
                                    handleClickWidget={handleClickWidget}
                                  />
                                );
                                break;
                              case "color datachart":
                                w = (
                                  <W_ColoredChart
                                    object={widget}
                                    key={widget.id}
                                    widgetType="color datachart"
                                    {...widget}
                                    dashboardId={dashboardId}
                                    groupNames={[
                                      { groupName: { equalTo: "Settings" } },
                                    ]}
                                    groupId={group.id}
                                    order={group.order}
                                    setDeleteWidgetModalState={
                                      setDeleteWidgetModalState
                                    }
                                    setAddWidgetModalState={
                                      setAddWidgetModalState
                                    }
                                    setEditWidgetModalState={
                                      setEditWidgetModalState
                                    }
                                    group={group}
                                    selected={false}
                                    saveBoard={saveBoard}
                                    handleUpdateWidget={handleUpdateWidget}
                                    handleClickWidget={handleClickWidget}
                                  />
                                );
                                break;
                              case "command button":
                                w = (
                                  <W_CommandButton
                                    key={widget.id}
                                    object={widget}
                                    {...widget}
                                    dashboardId={dashboardId}
                                    groupNames={[
                                      { groupName: { equalTo: "Settings" } },
                                    ]}
                                    group={group}
                                    groupId={group.id}
                                    order={group.order}
                                    setDeleteWidgetModalState={
                                      setDeleteWidgetModalState
                                    }
                                    setAddWidgetModalState={
                                      setAddWidgetModalState
                                    }
                                    setEditWidgetModalState={
                                      setEditWidgetModalState
                                    }
                                    selected={false}
                                    saveBoard={saveBoard}
                                    handleUpdateWidget={handleUpdateWidget}
                                    handleClickWidget={handleClickWidget}
                                  />
                                );
                                break;
                              case "advanced button":
                                w = (
                                  <W_AdvancedButton
                                    key={widget.id}
                                    object={widget}
                                    {...widget}
                                    dashboardId={dashboardId}
                                    groupNames={[
                                      { groupName: { equalTo: "Settings" } },
                                    ]}
                                    group={group}
                                    groupId={group.id}
                                    order={group.order}
                                    setDeleteWidgetModalState={
                                      setDeleteWidgetModalState
                                    }
                                    setAddWidgetModalState={
                                      setAddWidgetModalState
                                    }
                                    setEditWidgetModalState={
                                      setEditWidgetModalState
                                    }
                                    selected={false}
                                    saveBoard={saveBoard}
                                    handleUpdateWidget={handleUpdateWidget}
                                    handleClickWidget={handleClickWidget}
                                  />
                                );
                                break;
                              case "title":
                                w = (
                                  <W_Title
                                    object={widget}
                                    key={widget.id}
                                    widgetType="title"
                                    group={group}
                                    {...widget}
                                    dashboardId={dashboardId}
                                    groupNames={[
                                      { groupName: { equalTo: "Settings" } },
                                      { groupName: { equalTo: "Value" } },
                                    ]}
                                    groupId={group.id}
                                    order={group.order}
                                    setDeleteWidgetModalState={
                                      setDeleteWidgetModalState
                                    }
                                    setAddWidgetModalState={
                                      setAddWidgetModalState
                                    }
                                    setEditWidgetModalState={
                                      setEditWidgetModalState
                                    }
                                    selected={false}
                                    saveBoard={saveBoard}
                                    handleUpdateWidget={handleUpdateWidget}
                                    handleClickWidget={handleClickWidget}
                                  />
                                );
                                break;

                              case "monitor table":
                                w = (
                                  <W_MonitorTable
                                    object={widget}
                                    group={group}
                                    key={widget.id}
                                    widgetType="monitor table"
                                    {...widget}
                                    dashboardId={dashboardId}
                                    groupNames={[
                                      { groupName: { equalTo: "Settings" } },
                                    ]}
                                    groupId={group.id}
                                    order={group.order}
                                    setDeleteWidgetModalState={
                                      setDeleteWidgetModalState
                                    }
                                    setAddWidgetModalState={
                                      setAddWidgetModalState
                                    }
                                    setEditWidgetModalState={
                                      setEditWidgetModalState
                                    }
                                    selected={false}
                                    saveBoard={saveBoard}
                                    handleUpdateWidget={handleUpdateWidget}
                                    handleClickWidget={handleClickWidget}
                                  />
                                );
                                break;
                              case "monitor status":
                                w = (
                                  <W_MonitorStatusTable
                                    object={widget}
                                    group={group}
                                    key={widget.id}
                                    widgetType="history table"
                                    {...widget}
                                    dashboardId={dashboardId}
                                    groupNames={[
                                      { groupName: { equalTo: "Settings" } },
                                    ]}
                                    groupId={group.id}
                                    order={group.order}
                                    setDeleteWidgetModalState={
                                      setDeleteWidgetModalState
                                    }
                                    setAddWidgetModalState={
                                      setAddWidgetModalState
                                    }
                                    setEditWidgetModalState={
                                      setEditWidgetModalState
                                    }
                                    selected={false}
                                    saveBoard={saveBoard}
                                    handleUpdateWidget={handleUpdateWidget}
                                    handleClickWidget={handleClickWidget}
                                  />
                                );
                                break;
                              case "history table":
                                w = (
                                  <W_HistoryTable
                                    object={widget}
                                    key={widget.id}
                                    widgetType="history table"
                                    {...widget}
                                    dashboardId={dashboardId}
                                    groupNames={[
                                      { groupName: { equalTo: "Settings" } },
                                    ]}
                                    groupId={group.id}
                                    order={group.order}
                                    setDeleteWidgetModalState={
                                      setDeleteWidgetModalState
                                    }
                                    setAddWidgetModalState={
                                      setAddWidgetModalState
                                    }
                                    setEditWidgetModalState={
                                      setEditWidgetModalState
                                    }
                                    selected={false}
                                    saveBoard={saveBoard}
                                    handleUpdateWidget={handleUpdateWidget}
                                    handleClickWidget={handleClickWidget}
                                    group={group}
                                  />
                                );
                                break;
                              case "static table":
                                w = (
                                  <W_StaticTable
                                    object={widget}
                                    key={widget.id}
                                    widgetType="static table"
                                    {...widget}
                                    dashboardId={dashboardId}
                                    groupNames={[
                                      { groupName: { equalTo: "Settings" } },
                                    ]}
                                    group={group}
                                    groupId={group.id}
                                    order={group.order}
                                    setDeleteWidgetModalState={
                                      setDeleteWidgetModalState
                                    }
                                    setAddWidgetModalState={
                                      setAddWidgetModalState
                                    }
                                    setEditWidgetModalState={
                                      setEditWidgetModalState
                                    }
                                    selected={false}
                                    saveBoard={saveBoard}
                                    handleUpdateWidget={handleUpdateWidget}
                                    handleClickWidget={handleClickWidget}
                                  />
                                );
                                break;

                              case "tracking table":
                                w = (
                                  <W_TrackingTable
                                    group={group}
                                    object={widget}
                                    key={widget.id}
                                    widgetType="tracking table"
                                    {...widget}
                                    dashboardId={dashboardId}
                                    groupNames={[
                                      { groupName: { equalTo: "Settings" } },
                                    ]}
                                    groupId={group.id}
                                    order={group.order}
                                    setDeleteWidgetModalState={
                                      setDeleteWidgetModalState
                                    }
                                    setAddWidgetModalState={
                                      setAddWidgetModalState
                                    }
                                    setEditWidgetModalState={
                                      setEditWidgetModalState
                                    }
                                    selected={false}
                                    saveBoard={saveBoard}
                                    handleUpdateWidget={handleUpdateWidget}
                                    handleClickWidget={handleClickWidget}
                                  />
                                );
                                break;

                              case "diagram":
                                w = (
                                  <W_DiagramChart
                                    group={group}
                                    widgetType="diagram"
                                    object={widget}
                                    key={widget.id}
                                    {...widget}
                                    dashboardId={dashboardId}
                                    groupNames={[
                                      { groupName: { equalTo: "Settings" } },
                                    ]}
                                    groupId={group.id}
                                    order={group.order}
                                    setDeleteWidgetModalState={
                                      setDeleteWidgetModalState
                                    }
                                    setAddWidgetModalState={
                                      setAddWidgetModalState
                                    }
                                    setEditWidgetModalState={
                                      setEditWidgetModalState
                                    }
                                    selected={false}
                                    saveBoard={saveBoard}
                                    handleUpdateWidget={handleUpdateWidget}
                                    handleClickWidget={handleClickWidget}
                                  />
                                );
                                break;
                              case "datetime":
                                w = (
                                  <W_DateTime
                                    group={group}
                                    widgetType="datetime"
                                    object={widget}
                                    key={widget.id}
                                    {...widget}
                                    dashboardId={dashboardId}
                                    groupNames={[
                                      { groupName: { equalTo: "Settings" } },
                                    ]}
                                    groupId={group.id}
                                    order={group.order}
                                    setDeleteWidgetModalState={
                                      setDeleteWidgetModalState
                                    }
                                    setAddWidgetModalState={
                                      setAddWidgetModalState
                                    }
                                    setEditWidgetModalState={
                                      setEditWidgetModalState
                                    }
                                    selected={false}
                                    saveBoard={saveBoard}
                                    handleUpdateWidget={handleUpdateWidget}
                                    handleClickWidget={handleClickWidget}
                                  />
                                );
                                break;
                            }
                            const hoverWidget = (id) => {
                              if (objectHoveredId === id) {
                                dispatch(setContext({ objectHoveredId: null }));
                              }
                              return objectHoveredId === id;
                            };
                            return (
                              <div
                                onClick={(e) => {
                                  if (!isFullScreen) {
                                    history(
                                      `/boards/${dashboardId}/${group.id}/${widget.id}`
                                    );
                                  }
                                }}
                                style={{
                                  padding: "2px",
                                  filter: hoverWidget(widget.id)
                                    ? "brightness(130%)"
                                    : "",
                                }}
                                data-grid={{
                                  ...getGridWidgets(group, widget.id),
                                }}
                                key={widget.id}
                              >
                                {w}
                              </div>
                            );
                          })}
                        </ResponsiveGridLayout>
                      </div>
                    )}
                  </div>
                </div>
              );
            })}
          </ResponsiveGridLayout>
        </div>
      </div>
    </>
  );
});
export default Dashboard;
