import List from "@mui/material/List";
import ListSubheader from "@mui/material/ListSubheader";
import Typography from "@mui/material/Typography";
import makeStyles from "@mui/styles/makeStyles";
import React from "react";
import useHandleCopy from "./menu/handlers/useHandleCopy";
import PropListItem from "./PropListItem";
import AccessSection from "./basic/AccessSection";
import ServiceSection from "./basic/ServiceSection";
import DescriptionSection from "./basic/DescriptionSection";

const useStyles = makeStyles((theme) => ({
  listSubheader: {
    marginTop: "15px",
    marginBottom: "10px",
  },
}));

const TabContentGeneral = (props) => {
  const { item, type, dashboardId, groupId, widgetId } = props;
  const classes = useStyles({
    listItem: {
      minHeight: "56px",
    },
  });

  // group properties by groupName (array of properties -> array of groupNames)
  const grouping = (arr, key) =>
    (arr || []).reduce(
      (acc, x = {}) => ({ ...acc, [x[key]]: [...(acc[x[key]] || []), x] }),
      {}
    );

  const groups = grouping(item.objectProperties, "groupName");
  const groupNames = Object.keys(groups);

  const handleCopy = useHandleCopy();

  const linkedObject = (key) =>
    key === "valueValue" ? item.objectsToObjectsByObject1Id : "";

  return (
    <>
      <List>
        {groupNames.map((groupName) => {
          if (groupName !== "Value") return false;

          const headline = (
            <ListSubheader
              color="primary"
              key={groupName}
              className={classes.listSubheader}
            >
              <Typography variant="subtitle2">{groupName}</Typography>
            </ListSubheader>
          );

          return [
            headline,
            groups[groupName].map((item, index) => (
              <PropListItem
                linkedObjects={linkedObject(item.key)}
                data-test-value={groupName}
                tab="general"
                key={index}
                index={index}
                obj={props.item}
                item={item}
                dashboardId={dashboardId}
                widgetId={widgetId}
                groupId={groupId}
                type={type}
              />
            )),
          ];
        })}

        <ServiceSection classes={classes} item={item} />

        <AccessSection classes={classes} item={item} />

        {item.description && <DescriptionSection classes={classes} item={item} />}
      </List>
    </>
  );
};

export default TabContentGeneral;
