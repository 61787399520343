import TodayIcon from "@mui/icons-material/Today";
import { Button, CircularProgress } from "@mui/material";
import Grid from "@mui/material/Grid";
import { useTheme } from "@mui/material/styles";
import TextField from "@mui/material/TextField";
import { MobileDatePicker } from "@mui/x-date-pickers";
import { sub } from "date-fns";
import { useState } from "react";
import { create } from "react-modal-promise";
import { msg } from "../../messages";
import CommonModal from "../CommonModal";
import Switch from "@mui/material/Switch";
import InputLabel from "@mui/material/InputLabel";

const DateModal = (props) => {
  const theme = useTheme();
  const [startDate, setStartDate] = useState(sub(Date.now(), { weeks: 1 }));
  const [endDate, setEndDate] = useState(Date.now());
  const [loading, setLoading] = useState(false);
  const [shape, setShape] = useState('wide');

  const submit = () => props.onResolve();

  const handleClose = () => submit();

  return (
    <>
      <CommonModal
        key="DatePicker"
        modalOpen={props.isOpen}
        title="Download"
        handleClose={handleClose}
        buttons={
          <>
            <Button color="inherit" onClick={handleClose}>{msg.default.cancel}</Button>
            <Button
              color="primary"
              onClick={async () => {
                setLoading(true);
                try {
                  await props.downloadHistory(
                    [startDate, endDate],
                    props.downloadIds,
                    props.type !== 'controls' ? shape : undefined
                  );
                  handleClose();
                } finally {
                  setLoading(false);
                }
              }}
            >
              {loading ? <CircularProgress size={23} /> : msg.default.download}
            </Button>
          </>
        }
      >
        <Grid container direction="column" spacing={1}>
          <Grid item>
            <div style={{ display: "flex" }}>
              <div style={{ padding: "16px 16px 16px 0px" }}>
                <TodayIcon style={{ color: theme.palette.gray1 }} />
              </div>
              <MobileDatePicker
                renderInput={(props) => (
                  <TextField
                    {...props}
                    style={{ width: "100%" }}
                    variant="standard"
                    label="From"
                    helperText={null}
                  />
                )}
                inputFormat="dd-MM-yyyy"
                variant="inline"
                value={startDate}
                maxDate={endDate}
                onChange={(date) => {
                  setStartDate(date);
                }}
              />
            </div>
          </Grid>
          <Grid item>
            <div style={{ display: "flex" }}>
              <div style={{ padding: "16px 16px 16px 0px" }}>
                <TodayIcon style={{ color: theme.palette.gray1 }} />
              </div>
              <MobileDatePicker
                disableFuture
                minDate={startDate}
                renderInput={(props) => (
                  <TextField
                    style={{ width: "100%" }}
                    {...props}
                    variant="standard"
                    label="To"
                    helperText={null}
                  />
                )}
                inputFormat="dd-MM-yyyy"
                variant="inline"
                value={endDate}
                onChange={(date) => {
                  setEndDate(date);
                }}
              />
            </div>
          </Grid>
          { props.type !== 'controls' && (
            <Grid
              item
              container
              xs={12}
              justifyContent="space-between"
              alignItems="center"
            >

              <InputLabel
                htmlFor="shape-switch"
              >
                Download wide data
              </InputLabel>
              <Switch
                id="shape-switch"
                checked={shape === 'wide'}
                onChange={(e) => {
                  setShape(e.target.checked ? 'wide' : 'long');
                }}
              />
            </Grid>
          ) }

        </Grid>
      </CommonModal>
    </>
  );
};

export default create(DateModal);
