import { useLazyQuery, useMutation } from "@apollo/client";
import { Button, Checkbox, FormControlLabel, Typography } from "@mui/material";
import Grid from "@mui/material/Grid";
import { useFormik } from "formik";
import { useEffect } from "react";
import { create, InstanceProps } from "react-modal-promise";
import * as yup from "yup";
import CommonModal from "../../../../components/CommonModal";
import { msg } from "../../../../messages";
import { cloneObject } from "../../../../utils/cloneObject";
import { defineInitialValue } from "../../../../utils/defineInitialValue";
import { isEmpty } from "../../../../utils/isEmpty";
import { GET_REPORT } from "../../api/GetReport";
import { UPDATE_PROPERTIES } from "../../api/UpdateProperties";
import { HHmm, YYYYMMdd } from "../isValidDate";
import DateIntervalItem from "./DateInteralItem";
import TimeIntervalItem from "./TimeInteralItem";

type AddReportModalProps = {
  reportId?: unknown;
};

const ReportIntervalSettings = (props: AddReportModalProps & InstanceProps<{ reportId: any }>) => {
  const reject = () => props.onReject();

  const handleClose = () => reject();

  const [reportQuery, { loading }] = useLazyQuery(GET_REPORT);
  const [updateProperties, { loading: saveLoading }] = useMutation(UPDATE_PROPERTIES);

  const initialValues = {
    intervalSettingsCustomDateRange: true,
    intervalSettingsCustomSingleDate: true,
    intervalSettingsCustomTimeRange: true,
    intervalSettingsDateIntervals: [],
    intervalSettingsTimeIntervals: [],
  };

  // TODO: issueId: #553
  const validationSchema = yup.object({
    intervalSettingsCustomDateRange: yup.boolean().required("Custom Date Range is required"),
    intervalSettingsCustomSingleDate: yup.boolean().required("Custom Single Date is required"),
    intervalSettingsCustomTimeRange: yup.boolean().required("Custom Time Range is required"),
    intervalSettingsDateIntervals: yup.array().of(
      yup.object().shape({
        value: yup.array().of(yup.string().required("Date is required")),
      })
    ),
    intervalSettingsTimeIntervals: yup.array().of(
      yup.object().shape({
        value: yup.array().of(yup.string()),
      })
    ),
  });

  type FormikType = {
    intervalSettingsCustomDateRange: boolean;
    intervalSettingsCustomSingleDate: boolean;
    intervalSettingsCustomTimeRange: boolean;
    intervalSettingsDateIntervals: {
      title: string;
      value: [string, string] | [];
    }[];
    intervalSettingsTimeIntervals: {
      title: string;
      value: [string, string] | [];
    }[];
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (formikValues: FormikType) => {
      if (!isEmpty(formik.errors)) {
        return;
      }
      if (formik.isValid) {
        const payload = [
          {
            propertyKey: "intervalSettingsCustomDateRange",
            value: formikValues.intervalSettingsCustomDateRange,
          },
          {
            propertyKey: "intervalSettingsCustomSingleDate",
            value: formikValues.intervalSettingsCustomSingleDate,
          },
          {
            propertyKey: "intervalSettingsCustomTimeRange",
            value: formikValues.intervalSettingsCustomTimeRange,
          },
          {
            propertyKey: "intervalSettingsDateIntervals",
            value: formikValues.intervalSettingsDateIntervals.filter((item) => item.title),
          },
          {
            propertyKey: "intervalSettingsTimeIntervals",
            value: formikValues.intervalSettingsTimeIntervals.filter((item) => item.title),
          },
        ];

        updateProperties({
          variables: {
            input: {
              detailedObject: [{ objectId: props.reportId, keyedProperties: payload }],
            },
          },
        }).then(handleClose);
      }
    },
  });
  useEffect(() => {
    reportQuery({
      variables: {
        objId: props.reportId,
      },
      fetchPolicy: "network-only",
    })
      .then(({ data }) => {
        if (data) {
          const values = { ...formik.initialValues };
          data.object.objectProperties.forEach((prop) => {
            const type = prop.spec.type.name;
            if (prop.value === null) {
              values[prop.key] = defineInitialValue(type);
            } else {
              values[prop.key] = prop.value;
            }
          });
          formik.setValues(values);
        }
      })
      .catch(() => {});
  }, [props.reportId]);

  const DateIntervalHelperText = (index: number) =>
    isEmpty(formik.errors) || !formik.errors.intervalSettingsDateIntervals
      ? ""
      : formik.errors?.intervalSettingsDateIntervals[index];
  const TimeIntervalHelperText = (index: number) =>
    isEmpty(formik.errors) || !formik.errors.intervalSettingsTimeIntervals
      ? ""
      : formik.errors?.intervalSettingsTimeIntervals[index];

  return (
    <>
      <CommonModal
        loading={loading || saveLoading}
        modalOpen={props.isOpen}
        title="Report interval settings"
        forceTitle={true}
        contentStyles={{
          padding: "14px 16px 16px 14px",
        }}
        handleClose={() => {
          handleClose();
        }}
        buttons={
          <>
            <Button color="inherit" onClick={handleClose}>
              {msg.addDashboardModal.buttonCancel}
            </Button>
            <Button
              color="primary"
              data-test="createReport"
              onClick={() => formik.handleSubmit()}
              disabled={!isEmpty(formik.errors)}
            >
              Save
            </Button>
          </>
        }
      >
        <Grid container direction="column" spacing={2}>
          <Grid item>
            <Typography variant="subtitle2" color="primary">
              Date intervals
            </Typography>
          </Grid>

          <Grid item>
            <FormControlLabel
              name="intervalSettingsCustomDateRange"
              control={
                <Checkbox
                  sx={{ marginRight: "12px" }}
                  onChange={formik.handleChange}
                  checked={formik.values.intervalSettingsCustomDateRange}
                />
              }
              label="Custom date range"
            />
            <FormControlLabel
              name="intervalSettingsCustomSingleDate"
              control={
                <Checkbox
                  sx={{ marginRight: "12px" }}
                  checked={formik.values.intervalSettingsCustomSingleDate}
                  onChange={formik.handleChange}
                />
              }
              label="Сustom single date"
            />
          </Grid>

          {formik.values.intervalSettingsDateIntervals.map((item, index) => (
            <DateIntervalItem
              key={index}
              item={item}
              helperText={DateIntervalHelperText(index)}
              onChangeName={(e) => {
                const array = cloneObject(formik.values.intervalSettingsDateIntervals);

                array[index] = {
                  ...array[index],
                  title: e.target.value,
                };

                formik.setFieldValue("intervalSettingsDateIntervals", array);
              }}
              onChangeRange={(date) => {
                const array = cloneObject(formik.values.intervalSettingsDateIntervals);
                array[index] = { ...array[index], value: date };
                if (date && date[0] === YYYYMMdd) {
                  formik.setFieldError(`intervalSettingsDateIntervals[${index}].value[0]`, "Start Date is required");
                } else if (date && date[1] === YYYYMMdd) {
                  formik.setFieldError(`intervalSettingsDateIntervals[${index}].value[1]`, "End Date is required");
                } else {
                  formik.setFieldValue("intervalSettingsDateIntervals", array);
                }
              }}
            />
          ))}

          <Grid item>
            <Button
              data-test="addDateInterval"
              variant="outlined"
              color="primary"
              fullWidth
              disableElevation
              onClick={() => {
                formik.setFieldValue("intervalSettingsDateIntervals", [
                  ...formik.values.intervalSettingsDateIntervals,
                  {
                    title: "",
                    value: [],
                  },
                ]);
              }}
            >
              Add date interval
            </Button>
          </Grid>
          <Grid item>
            <Typography variant="subtitle2" color="primary">
              Time ranges
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="body2" color="gray1">
              System timezone: UTC
            </Typography>
          </Grid>

          <Grid item>
            <FormControlLabel
              name="intervalSettingsCustomTimeRange"
              control={
                <Checkbox
                  sx={{ marginRight: "12px" }}
                  onChange={formik.handleChange}
                  checked={formik.values?.intervalSettingsCustomTimeRange}
                />
              }
              label="Custom time range"
            />
          </Grid>
          {formik.values?.intervalSettingsTimeIntervals?.map((item, index) => (
            <TimeIntervalItem
              key={index}
              item={item}
              helperText={TimeIntervalHelperText(index)}
              onChangeName={(e) => {
                const array = cloneObject(formik.values.intervalSettingsTimeIntervals);
                array[index] = {
                  ...array[index],
                  title: e.target.value,
                };

                formik.setFieldValue("intervalSettingsTimeIntervals", array);
              }}
              onChangeRange={(date) => {
                const array = cloneObject(formik.values.intervalSettingsTimeIntervals);
                array[index] = {
                  ...array[index],
                  value: date,
                };

                if (date && date[0] === HHmm) {
                  formik.setFieldError(`intervalSettingsTimeIntervals[${index}].value[0]`, "Start Time is required");
                } else if (date && date[1] === HHmm) {
                  formik.setFieldError(`intervalSettingsTimeIntervals[${index}].value[1]`, "End Time is required");
                } else {
                  formik.setFieldValue("intervalSettingsTimeIntervals", array);
                }
              }}
            />
          ))}
          <Grid item>
            <Button
              data-test="addTimeInterval"
              variant="outlined"
              color="primary"
              fullWidth
              disableElevation
              onClick={() => {
                formik.setFieldValue("intervalSettingsTimeIntervals", [
                  ...formik.values.intervalSettingsTimeIntervals,
                  {
                    title: "",
                    value: [],
                  },
                ]);
              }}
            >
              Add time interval
            </Button>
          </Grid>
        </Grid>
      </CommonModal>
    </>
  );
};

export default create(ReportIntervalSettings);
