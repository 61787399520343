import { Button, CircularProgress } from "@mui/material";
import Grid from "@mui/material/Grid";
import React, {  useState } from "react";
import { msg } from "../../../../messages";
import CommonModal from "../../../CommonModal";

import { create } from "react-modal-promise";
import CustomInput from "../../../CustomInput";

const MinimumDuration = (props) => {
  const [loading, setLoading] = useState(false);

  const [value, setValue] = useState(props.value || 1);

  const handleInputChange = (e) => {
    let { value } = e.target;

    setValue(Math.round(Math.abs(value)));
  };

  const submit = () => props.onResolve();

  const reject = () => props.onReject();

  const handleSave = async () => {
    setLoading(true);
    props.handleSave(value).finally(() => {
      setLoading(false);
      submit();
    });
  };

  return (
    <CommonModal
      key="minimumDuration"
      modalOpen={props.isOpen}
      title="Set minimum duration (minutes)"
      handleClose={reject}
      buttons={
        <>
          <Button color="inherit" onClick={reject}>{msg.default.cancel}</Button>
          <Button color="primary" disabled={loading} onClick={handleSave}>
            {loading ? <CircularProgress size={23} /> : msg.default.save}
          </Button>
        </>
      }
    >
      <Grid container direction="column" spacing={2}>
        <Grid item>
        <CustomInput
          clearFieldIcon={true}
          min={0}
          placeholder="Minutes"
          value={value}
          type="number"
          onChange={handleInputChange}
        />
      </Grid>
      </Grid>
    </CommonModal>
  );
};

export default create(MinimumDuration);
