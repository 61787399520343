import { Button } from "@mui/material";
import Grid from "@mui/material/Grid";
import { useEffect, useState } from "react";
import { create } from "react-modal-promise";
import CommonModal from "../../../components/CommonModal";
import CustomSelect from "../../../components/CustomSelect";
import { msg } from "../../../messages";

const ParameterPeriod = (props) => {
  const [type, setType] = useState("device");

  const periods = props.selected.spec.valueSet.list.map((item) => ({
    title: item.title,
    value: item.key,
  }));

  const [value, setValue] = useState(null);

  useEffect(() => {
    if (props.selected) {
      setValue(props.selected.value.value);
    }
  }, [type]);

  const submit = () => props.onResolve();
  const reject = () => props.onReject();

  const handleClose = () => reject();

  const handleInputChange = (e) => {
    let { name, value, checked } = e.target;

    if (checked) value = checked;

    setValue(e.target.value);
  };

  const handleSave = () => {
    props.save(periods.find((item) => item.value === value));
    submit();
  };

  return (
    <>
      <CommonModal
        modalOpen={props.isOpen}
        title={"Select period"}
        handleClose={handleClose}
        buttons={
          <>
            <Button color="inherit" onClick={handleClose}>
              {msg.linkWidgetModal.buttonCancel}
            </Button>
            <Button color="primary" onClick={handleSave}>
              {msg.linkWidgetModal.buttonSave}
            </Button>
          </>
        }
      >
        <Grid container direction="column" spacing={2}>
          <Grid item>
            <CustomSelect
              name="period"
              label="Period"
              value={value}
              list={periods}
              onChange={handleInputChange}
            />
          </Grid>
        </Grid>
      </CommonModal>
    </>
  );
};

export default create(ParameterPeriod);
