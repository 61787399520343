import { DataboxIcon } from "./DataboxIcon";
import { styled } from "@mui/system";
import { SettingsSizeOptions } from "../../../utils/constants/selectOptions";
import { Typography } from "@mui/material";
import { FormatEnum } from "../../create-widget/databox/DataboxTypes";

const Wrap = styled("div")(({ theme }) => ({
  "&": {
    display: "flex",
    height: "100%",
    alignItems: "center"
  },
}));

const ContentWrap = styled("div")(({ theme, mode }) => ({
  "&": {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-evenly",
    width: "100%",
    height: "100%",
    padding: mode === FormatEnum.value ? "6px 12px" : "6px 18px 6px 0",
    overflow: "hidden",
  },
}));

const Title = styled(Typography)(({ size, mode }) => ({
  "&": {
    fontSize: size === SettingsSizeOptions.medium ? "45px" : "20px",
    lineHeight: size === SettingsSizeOptions.medium ? "45px" : "24px",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    fontWeight: "500",
    textAlign: mode !== FormatEnum.value ? "left" : "center",
  },
}));

const getValueFontSize = (size, mode) => {
  if (size === SettingsSizeOptions.medium && mode === FormatEnum.value) {
    return '56px'
  }
  if (size === SettingsSizeOptions.medium) {
    return '36px'
  }
  return '32px'
}

const Value = styled(Typography)(({ size, mode }) => ({
  "&": {
    fontSize: getValueFontSize(size, mode),
    lineHeight: size === SettingsSizeOptions.medium ? "48px" : "32px",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    fontWeight: "500",
    textAlign: mode !== FormatEnum.value ? "left" : "center",
  },
}));

const Label = styled(Typography)(({ size, mode }) => ({
  "&": {
    fontSize: size === SettingsSizeOptions.medium ? "32px" : "24px",
    lineHeight: "24px",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    fontWeight: "500",
    textAlign: mode !== FormatEnum.value ? "left" : "center",
  },
}));

const getFixedSize = (size) => {
  switch (size) {
    case SettingsSizeOptions.medium:
      return {
        iconW: '192px',
        iconH: '192px',
      };
    default:
      return {
        iconW: '96px',
        iconH: '96px',
      };
  }
};

export const HorizontalView = (
  {
    colors,
    iconId,
    mode,
    size,
    isShowTitle,
    label,
    title,
    value,
  }) => {

  const sizes = getFixedSize(size)

  return (
    <Wrap>
      {mode !== FormatEnum.value &&
        <>
          <DataboxIcon padding="18px" id={iconId} colors={colors} width={sizes.iconW} height={sizes.iconH} />
        </>}
      {mode !== FormatEnum.icon &&
        <>
          <ContentWrap
            mode={mode}
            style={{ heigth: sizes.iconW, color: colors?.fg || "transparent" }}>
            {isShowTitle &&
              <>
                <Title mode={mode} size={size}>
                  {title}
                </Title>
              </>
            }

            <Value mode={mode} size={size}>{ value }</Value>

            {label &&
              <>
                <Label mode={mode} size={size}>
                  {label}
                </Label>
              </>}
          </ContentWrap>
        </>
      }
    </Wrap>
  );
};
