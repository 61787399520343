import ChartIcon from "../../icons/chartIcon";

const EmptyChart = ({ fgColor }) => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        height: "100%",
        textAlign: "center",
      }}
    >
      <div
        style={{
          color: fgColor,
          flexDirection: "column",
          alignItems: "center",
          display: "flex",
        }}
      >
        <ChartIcon fontSize={"large"} />
        <span>No data</span>
      </div>
    </div>
  );
};

export default EmptyChart;
