import { useApolloClient } from "@apollo/client";
import { Typography } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import makeStyles from "@mui/styles/makeStyles";
import { format, parseISO } from "date-fns";
import { orderBy, sortBy } from "lodash";
import { useEffect, useState } from "react";
import { ReactComponent as MenuDown } from "../../assets/menu-down.svg";
import { COLOR_SCHEMAS, highlightSelectedStyle } from "../../constants";
import { GET_DATA_SUBSCRIPTION } from "../../queries";
import useColors from "../../utils/useColors";
import { BASE_HEIGHT } from "../../utils/widgetSizes";
import WidgetEditControls from "../WidgetEditControls";

const useStyles = makeStyles((theme) => ({
  table: {
    backgroundColor: "#2780E3",
    height: "100%",
  },

  tableCellHead: {
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    borderBottom: "none",
    fontSize: "16px",
    fontFamily: "Roboto-Medium",
    userSelect: "none",
  },

  tableCell: {
    whiteSpace: "nowrap",
    overflow: "hidden",
    position: "relative",
    textOverflow: "ellipsis",
    borderBottom: "none",
    fontSize: "16px",
    fontFamily: "Roboto-Regular",
  },
}));

const W_MonitorTable = (props) => {
  const { objectProperties, selected } = props;
  const client = useApolloClient();

  const getPropValue = (prop) => objectProperties.find((obj) => obj.key === prop)?.value;
  const [valueInitial, setValue] = useState(objectProperties.find((obj) => obj.key === "valueValue")?.value);
  const columns = objectProperties.find((item) => item.key === "sourceColumns").value?.columns || [];
  const colors = [getPropValue("settingsStyle"), null];
  const showTitle = getPropValue("settingsShowTitle");
  const { getColorBasedOnStyle } = useColors();
  const { fg: fgColor, bg: bgColor } = getColorBasedOnStyle(getPropValue("settingsStyle"));

  const [sort, setSort] = useState({
    type: "asc",
    name: "object",
  });

  const headers = () => {
    if (valueInitial.length) {
      let sortedCollection = sortBy(valueInitial[0].properties, function (item) {
        return columns.findIndex((o) => o.property.id === item.schemaId);
      });

      return sortedCollection.map((item) => {
        const type = columns.find((i) => i.property.id === item.schemaId)?.property?.type;

        if (type && type !== "value") {
          return `${item.name} (Last update)`;
        }

        return item.name || "n/a";
      });
    }
    return [];
  };

  const classes = useStyles();

  const getColorOfRow = (index) => {
    const isOdd = () => index % 2;

    if (isOdd()) {
      return "";
    }

    const theme = colors[0];

    switch (theme) {
      case COLOR_SCHEMAS.DARK_ON_LIGHT:
        return "#F1F1F1";
      default:
        return "rgba(255, 255, 255, 0.1)";
    }
  };

  const sortColumns = (value) => {
    const flat = [];

    value.forEach((item) => {
      const itemLocal = {
        ...item,
        properties: {},
        propertiesInternal: {},
      };

      let sortedCollection = sortBy(item.properties, function (item) {
        return columns.findIndex((o) => o.property.id === item.schemaId);
      });

      sortedCollection.forEach((property) => {
        const lastUpdate = format(parseISO(property.lastUpdate), "dd-MM-yyyy HH:mm:ss")
        const type = columns.find((item) => item.property.id === property.schemaId)?.property?.type;
        itemLocal.properties[type === "value" ? property.name : `${property.name} (Last update)`] =
          type === "value" ? property.value : lastUpdate;
        itemLocal.propertiesInternal[`${property.name}_isAlert`] = property.isAlert;


        if (type !== 'value') {
          itemLocal.propertiesInternal[`${property.name} (Last update)`] = property.lastUpdate;
        }
      });

      flat.push(itemLocal);
    });

    if (sort.name && sort.type) {
      if (sort.name === "object") {
        return orderBy(flat, ["objectName"], [sort.type]);
      }

      if (sort.name.includes('Last update')) {
        return orderBy(flat, [(o) => new Date(o.propertiesInternal[`${sort.name}`])], [sort.type]);
      }

      return orderBy(flat, [(o) => o.properties[`${sort.name}`]], [sort.type]);
    }

    return flat;
  };

  useEffect(() => {
    const observer = client.subscribe({
      query: GET_DATA_SUBSCRIPTION,
      variables: { objId: props.id },
    });

    const subscription = observer.subscribe(({ data }) => {
      if (data.Objects.relatedNode?.key === "valueValue") {
        setValue(data.Objects.relatedNode?.value);
      }
    });

    return () => subscription.unsubscribe();
  }, [props.id]);

  return (
    <div
      className={"force-scroll"}
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        alignItems: "flex-start",
        height: "100%",
        position: "relative",
        overflow: "auto",
        backgroundColor: bgColor,
        filter: selected ? highlightSelectedStyle : "",
        borderRadius: "2px",
      }}
    >
      <div style={{ width: "100%" }}>
        <Table
          size="small"
          className={classes.table}
          aria-label="simple table"
          style={{ backgroundColor: "transparent" }}
        >
          <TableHead
            style={{
              position: "sticky",
              top: 0,
              zIndex: 1,
              backgroundColor: bgColor,
              height: `${BASE_HEIGHT}px`,
            }}
          >
            <TableRow>
              <TableCell
                className={classes.tableCellHead}
                align="center"
                variant="head"
                colSpan={headers()?.length + sortColumns(valueInitial)?.length}
              >
                {showTitle && (
                  <Typography style={{ color: fgColor }} variant="h5">
                    {props.name}
                  </Typography>
                )}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell
                className={classes.tableCellHead}
                onClick={() => {
                  setSort({
                    name: "object",
                    type: "object" === sort.name && sort.type === "asc" ? "desc" : "asc",
                  });
                }}
                style={{ textAlign: "left", color: fgColor }}
              >
                Object
                {sort.type === "desc" && sort.name === "object" && (
                  <MenuDown
                    style={{
                      height: "20px",
                      position: "absolute",
                    }}
                  />
                )}
                {sort.type === "asc" && sort.name === "object" && (
                  <MenuDown
                    style={{
                      transform: "rotate(180deg)",
                      height: "20px",
                      position: "absolute",
                    }}
                  />
                )}
              </TableCell>
              {headers().map((header) => (
                <TableCell
                  onClick={() => {
                    setSort({
                      name: header,
                      type: header === sort.name && sort.type === "asc" ? "desc" : "asc",
                    });
                  }}
                  align="right"
                  className={classes.tableCellHead}
                  style={{ color: fgColor, paddingRight: "25px" }}
                >
                  {header}

                  {sort.type === "desc" && sort.name === header && (
                    <MenuDown
                      style={{
                        height: "20px",
                        position: "absolute",
                      }}
                    />
                  )}
                  {sort.type === "asc" && sort.name === header && (
                    <MenuDown
                      style={{
                        transform: "rotate(180deg)",
                        height: "20px",
                        position: "absolute",
                      }}
                    />
                  )}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {sortColumns(valueInitial).map((item, index) => (
              <TableRow
                key={index}
                style={{
                  height: `${BASE_HEIGHT}px`,
                  backgroundColor: getColorOfRow(index),
                }}
              >
                <TableCell component="th" scope="row" className={classes.tableCell} style={{ color: fgColor }}>
                  {item.objectName}
                </TableCell>
                {Object.entries(item.properties).map(([key, value]) => (
                  <TableCell
                    align="right"
                    className={classes.tableCell}
                    style={{
                      color: fgColor,
                      backgroundColor: item.propertiesInternal[`${key}_isAlert`] ? "#D50000" : "",
                    }}
                  >
                    {`${value}`}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </div>
      <WidgetEditControls {...props} />
    </div>
  );
};

export default W_MonitorTable;
