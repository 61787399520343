import { gql, useLazyQuery, useMutation, useQuery } from "@apollo/client";
import { Button } from "@mui/material";
import Grid from "@mui/material/Grid";
import { useEffect, useState } from "react";
import { create } from "react-modal-promise";
import { msg } from "../../messages";
import CommonModal from "../CommonModal";
import CustomAutocomplete from "../CustomAutocomplete";
import { loader } from "graphql.macro";
import * as yup from "yup";
import { useFormik } from "formik";
import CustomInput from "../CustomInput";
import LoadingButton from "@mui/lab/LoadingButton";
import RpcSubscribeWrapper from "../side-card/static-table/RpcSubscribeWrapper";
import { useParams } from "react-router-dom";
const DASHBOARD_QUERY = loader("../../graphql/DashboardQuery.graphql");
const UPDATE_PROPERTY = loader("../../graphql/UpdatePropertyMutation.graphql");

const CREATE_EXECUTION = gql`
    mutation createControlsExecution($input: CreateControlExecutionInput!) {
        createControlExecution(input: $input) {
            controlExecution {
                params
                name
                nodeId
                linkedControlId
                objectId
                id
            }
            clientMutationId
        }
    }
`;

const DASHBOARDS_QUERY = gql`
    query getDashboards {
        dashboards: objects(
            filter: { schemaTags: { contains: ["application", "board", "dashboard"] } }
            orderBy: NAME_ASC
        ) {
            id
            name,
            objectProperties(
                first: 1, 
                filter: { 
                key: {
                    equalTo: "settingsLayouts"
                }
            }) {
                id
                value
            }
        }
        reports: objects(
            filter: { schemaTags: { contains: ["application", "board", "report"] } }
            orderBy: NAME_ASC
        ) {
            id
            name,
            objectProperties(
                first: 1, 
                filter: { 
                key: {
                    equalTo: "settingsLayouts"
                }
            }) {
                id
                value
            }
        }
    }
`;

const CreateGroupCopy = (props) => {
  const { dashboardId, reportId } = useParams();
  const [updateDashboardLayout] = useMutation(UPDATE_PROPERTY);
  const [createExecution] = useMutation(CREATE_EXECUTION);
  const [dashboardLazyQuery] = useLazyQuery(DASHBOARD_QUERY, {
    variables: {
      dashboardId: props.dashboardId,
    },
    fetchPolicy: "network-only",
  });
  const [load, { data }] = useLazyQuery(DASHBOARDS_QUERY, {
    fetchPolicy: "cache-and-network",
    variables: {},
  });
  const validationSchema = yup.object({
    name: yup.string().trim().required("Name is required"),
    parentId: yup.string().trim().required("Dashboard is required"),
  });

  const [layoutParent, setLayoutParent] = useState(null);
  const formik = useFormik({
    initialValues: {
      name: `${props.group.name} copy`,
    },
    validationSchema,
    onSubmit: () => {},
  });

  const rpcHandler = () => {
    return createExecution({
      variables: {
        input: {
          controlExecution: {
            name: "CopyGroup",
            objectId: props.group.id,
            params: {
              UUID: props.group.id,
              NAME: formik.values.name,
              PARENT: formik.values.parentId,
            },
          },
        },
      },
    });
  };

  const submit = () => props.onResolve();

  const reject = () => props.onReject();

  const list = () => {
    if (data) {
     const dashboards = data.dashboards.map((item) => {
        return {
          sort: 'Dashboards',
          ...item,
          value: item.id,
          title: item.name,
        };
      });

     const reports = data.reports.map((item) => {
        return {
          sort: 'Reports',
          ...item,
          value: item.id,
          title: item.name,
        };
      });

     if (reportId) {
       return reports;
     }

     if (dashboardId) {
       return dashboards;
     }
    }

    return [];
  };

  useEffect(() => {
    load();

  }, []);

  return (
    <CommonModal
      key="CreateGroupCopy"
      modalOpen={props.isOpen}
      title="Copy group"
      handleClose={reject}
      buttons={
        <>
          <Button color="inherit" onClick={reject}>{msg.default.cancel}</Button>

          <RpcSubscribeWrapper
            rpcName={"CopyGroup"}
            objectId={props.group.id}
            object={null}
            disabled={!formik.values.parentId || !formik.values.name}
            handler={rpcHandler}
            title={"Copy"}
            successCb={(groupId) => {
              const generalColumnsCount = props.group.objectProperties.find(
                (property) => property.key === "generalColumnsCount"
              );
              const generalRowsCount = props.group.objectProperties.find(
                (property) => property.key === "generalRowsCount"
              );
              const yCoords = layoutParent.value.lg.map(
                (item) => item.y
              );

              const newGroup = {
                x: 0,
                y: yCoords.length ? Math.max(...yCoords) + 1 : 0,
                w: generalColumnsCount.value,
                h: generalRowsCount.value,
                i: groupId,
                resizeHandles: ["se"],
              };
              const newLayout = {
                lg: [...layoutParent.value.lg, newGroup],
                md: [...layoutParent.value.md, newGroup],
                sm: [...layoutParent.value.sm, newGroup],
                xs: [...layoutParent.value.xs, newGroup],
                xxs: [...layoutParent.value.xxs, newGroup],
              };

              updateDashboardLayout({
                variables: {
                  input: {
                    id: layoutParent.id,
                    patch: {
                      value: newLayout,
                    },
                  },
                },
              }).then(res => {
                return dashboardLazyQuery({
                  variables: {
                    dashboardId: formik.values.parentId
                  }
                })
              }).then(() => {
                submit();
              });
            }}
          >
            <LoadingButton
              data-test-copy-group-rpc
              color="primary"
              disableElevation
            ></LoadingButton>
          </RpcSubscribeWrapper>
        </>
      }
    >
      <Grid container direction="column" spacing={2}>
        <Grid item>
          <CustomInput
            name="name"
            label="Name"
            value={formik.values.name}
            onChange={formik.handleChange}
            error={formik.touched.name && Boolean(formik.errors.name)}
            helperText={formik.touched.name && formik.errors.name}
          />
        </Grid>
        <Grid item>
          <CustomAutocomplete
            value={formik.values.parentId}
            onChange={(e) => {
              setLayoutParent(e.target.rawValue?.objectProperties?.[0])
              formik.handleChange(e);
            }}
            error={formik.touched.parentId && Boolean(formik.errors.parentId)}
            helperText={formik.touched.parentId && formik.errors.parentId}
            name="parentId"
            label="Target"
            list={list()}
          />
        </Grid>
      </Grid>
    </CommonModal>
  );
};

export default create(CreateGroupCopy);
