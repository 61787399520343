import { gql, useMutation } from "@apollo/client";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import DeleteIcon from "@mui/icons-material/Delete";
import FilterNoneIcon from "@mui/icons-material/FilterNone";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import Tooltip from "@mui/material/Tooltip";
import { styled } from "@mui/system";
import { Image } from "mui-image";
import { ChangeEvent, useRef, useState } from "react";
import toast from "react-hot-toast";
import { useCopyToClipboard } from "react-use";
import imgUpload from "../../../assets/upload.svg";
import useMedia from "../../../utils/useMedia";
import CustomInput from "../../CustomInput";
import IdentifierIcon from "../../icons/IdentifierIcon";
import PicturePlusIcon from "../../icons/picturePlusIcon";

const Wrapper = styled("form")(() => ({
  [`&`]: {
    position: "relative",
  },
  [`& .iconButton`]: {
    backgroundColor: "#000000",
    opacity: "0.44",
    height: "40px",
    width: "40px",
    color: "#ffffff",
    marginLeft: "16px",
    "&:hover": {
      backgroundColor: "#000000",
      opacity: "0.6",
    },
  },
}));

const REMOVE = gql`
  mutation remove($input: mnDeleteObjectInput!) {
    mnDeleteObject(input: $input) {
      clientMutationId
    }
  }
`;

const VIEW_TYPES = {
  IMG: 0,
  UUID: 1,
};

type TTypeView = 0 | 1;

interface HandleCopyParams {
  object: { id: string; [key: string]: any };
  text?: string;
  message?: string;
}

const ImageItem = ({
  id,
  onChange,
  onDelete,
  onSelectFile,
}: {
  id: string;
  onSelectFile: (file: unknown) => void;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  onDelete: () => void;
}) => {
  const accept = ["image/png", "image/jpeg"];
  const uploadInputRef = useRef<HTMLInputElement>(null);
  const formRef = useRef<HTMLFormElement>(null);
  const { getImageById } = useMedia();
  const [imagePreview, setImagePreview] = useState(null);
  const [imagePreviewBroken, setImagePreviewBroken] = useState(false);
  const [rawUUID, setRawUUID] = useState(null);
  const [currentView, setCurrentView] = useState(VIEW_TYPES.IMG);
  const [deleteObjects] = useMutation(REMOVE);
  const [state, copyToClipboard] = useCopyToClipboard();

  const handleCopy = () => {
    copyToClipboard(id);

    if (state?.error?.message) {
      toast.error(state.error.message);
    } else {
      toast.success(`UUID copied successfully`);
    }
  };
  const handleDelete = () => {
    if (id) {
      deleteObjects({
        variables: {
          input: {
            mnPatch: [{ id }],
          },
        },
      }).then(() => {
        onDelete();
      });
    } else {
      if (formRef.current) {
        formRef.current.reset();
        setImagePreview(null);
        onSelectFile(null);
      }
    }
  };

  const pickFile = (e) => {
    const file: Blob | MediaSource = e.target.files[0];

    // TODO: validation
    // if (file.size > props.maxSize) {
    //   // handleError(`File too large (max ${maxSize} bytes)`);
    //
    //   return false;
    // }
    // e.currentTarget.

    setImagePreview(URL.createObjectURL(file));

    onSelectFile(file);
  };

  const changeView = (viewType: TTypeView) => {
    onSelectFile(null);
    setCurrentView(viewType);
  };

  const handleImageError = () => {
    setImagePreviewBroken(true);
  };

  return (
    <>
      {currentView === VIEW_TYPES.UUID && (
        <div>
          <CustomInput
            data-test="media-uuid"
            name="uuid"
            label="UUID"
            clearFieldIcon={true}
            value={rawUUID}
            multiline={true}
            inputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <Tooltip title={"Add image by selecting file"} disableTouchListener>
                    <IconButton
                      data-test="turn-on-img-uploader"
                      onClick={() => {
                        setCurrentView(VIEW_TYPES.IMG);
                      }}
                    >
                      <PicturePlusIcon />
                    </IconButton>
                  </Tooltip>
                </InputAdornment>
              ),
            }}
            onChange={onChange}
          />
        </div>
      )}
      {currentView === VIEW_TYPES.IMG && (
        <Wrapper className="wrapper" ref={formRef}>
          <div style={{ position: "relative", width: "100%" }}>
            <Image
              sx={{ minHeight: "120px" }}
              duration={300}
              bgColor="#f3f3f3"
              showLoading
              errorIcon={true}
              src={`${id ? getImageById(id) : imagePreview || imgUpload}`}
            />
          </div>

          <div style={{ position: "absolute", right: "16px", top: "8px" }}>
            {id && (
              <IconButton className={"iconButton"} onClick={() => handleCopy()} size="large">
                <FilterNoneIcon />
              </IconButton>
            )}

            <Tooltip title={"Add image by manually typing UUID of existed media"} disableTouchListener>
              <IconButton
                className={"iconButton"}
                onClick={() => {
                  setCurrentView(VIEW_TYPES.UUID);
                }}
                size="large"
              >
                <IdentifierIcon />
              </IconButton>
            </Tooltip>
            {!imagePreview && !id && (
              <IconButton
                className={"iconButton"}
                onClick={() => {
                  if (uploadInputRef.current) {
                    uploadInputRef.current.click();
                  }
                }}
                size="large"
              >
                <CloudUploadIcon />
              </IconButton>
            )}

            {(id || imagePreview) && (
              <IconButton className={"iconButton"} onClick={handleDelete} size="large">
                <DeleteIcon />
              </IconButton>
            )}
          </div>

          {/*<div style={{ position: "absolute", top: "0", width: "100%" }}>*/}
          {/*  {fileIsUploading && <LinearProgress variant="determinate" value={progress} style={{ width: "100%" }} />}*/}
          {/*</div>*/}

          {!id && (
            <input
              ref={uploadInputRef}
              type="file"
              accept={accept.join(",")}
              hidden
              multiple={false}
              onChange={(e) => pickFile(e)}
            />
          )}
        </Wrapper>
      )}
    </>
  );
};

export default ImageItem;
