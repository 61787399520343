import { gql, useLazyQuery } from "@apollo/client";
import { Button } from "@mui/material";
import Grid from "@mui/material/Grid";
import { useEffect, useReducer, useState } from "react";
import { create } from "react-modal-promise";
import CommonModal from "../../../../components/CommonModal";
import { msg } from "../../../../messages";
import CustomSelect from "../../../CustomSelect";
import ObjectsAutocomplete from "../../../forms/ObjectsAutocomplete";
import SchemasAutocomplete from "../../../forms/SchemasAutocomplete";
import ObjectsAutocompletePure from "../../../forms/ObjectsAutocompletePure";

const GET_OBJECT = gql`
  query getObject($objectId: UUID!) {
    object(id: $objectId) {
      id
      schemaTags
      schemaType
      schemaId
    }
  }
`;

const ParameterObjectModal = (props) => {
  const filterSchemaId = {};
  const filterExtraObject = {};
  const filter = {
    schemaTags: {
      contains: ["application", "monitor", "object"],
    },
    or: []
  };

  const defaultValues = {};

  const [values, setValues] = useReducer(
    (prev, updated) => ({ ...prev, ...updated }),
    defaultValues
  );

  const submit = () => props.onResolve();
  const reject = () => props.onReject();

  const handleClose = () => reject();

  const handleInputChange = (e) => {
    let { name, value, checked } = e.target;

    if (checked) value = checked;

    if (name === "object") {
      setValues({ [name]: e.target.rawValue });
    } else {
      setValues({ [name]: value });
    }
  };

  const handleLinkWidget = () => {
    props.save(values["object"]);
    submit();
  };

  if (props.type) {
    filterSchemaId.schemaId = {
      equalTo: props.type
    }
  }

  if (props.value) {
    filterExtraObject.id = {
      equalTo: props.value
    }
  }

  filter.or = [filterSchemaId, filterExtraObject]

  useEffect(() => {
    if (props.type) {
      filterSchemaId.schemaId = {
        equalTo: props.type
      }
    } else {
      filterSchemaId.schemaId = {}
    }

    filter.or = [filterSchemaId, filterExtraObject]

  }, [props.type]);

  useEffect(() => {
    if (props.value) {
      filterExtraObject.id = {
        equalTo: props.value
      }
    } else {
      filterExtraObject.id = {}
    }
    filter.or = [filterSchemaId, filterExtraObject]

  }, [props.value]);

  return (
    <>
      <CommonModal
        modalOpen={props.isOpen}
        title={"Select object"}
        handleClose={handleClose}
        buttons={
          <>
            <Button color="inherit" onClick={handleClose}>
              {msg.linkWidgetModal.buttonCancel}
            </Button>
            <Button color="primary" onClick={handleLinkWidget}>
              {msg.linkWidgetModal.buttonSave}
            </Button>
          </>
        }
      >
        <Grid container direction="column" spacing={2}>
          <Grid item>
            <ObjectsAutocompletePure
              name="object"
              filter={filter}
              objectId={props.value}
              handleChange={handleInputChange}
            />
          </Grid>
        </Grid>
      </CommonModal>
    </>
  );
};

export default create(ParameterObjectModal);
