import { gql, useLazyQuery } from "@apollo/client";
import { Button } from "@mui/material";
import Grid from "@mui/material/Grid";
import React, { useEffect, useReducer, useState } from "react";
import { create } from "react-modal-promise";
import CommonModal from "../../../../components/CommonModal";
import { msg } from "../../../../messages";
import ObjectsAutocomplete from "../../../forms/ObjectsAutocomplete";
import CustomAutocomplete from "../../../CustomAutocomplete";
import uniqBy from "lodash.uniqby";
import { orderBy } from "lodash";

export const LINKED_OBJECTS = gql`
    query linkedObjects($id: UUID!, $schemaId: UUID!) {
        object(id: $id) {
            objectsToObjectsByObject1Id(filter: {
                object2: {
                    schemaId: {
                        equalTo: $schemaId
                    }
                    enabled: { equalTo: true }
                }
            }) {
                id
                object2 {
                    id
                    name
                }
            }
        }
    }
`;


const ParameterObjectModal = (props) => {
  let defaultValues = {};
  const [linkedObjects, setLinkedObjects] = useState([]);

  const [values, setValues] = useReducer(
    (prev, updated) => ({ ...prev, ...updated }),
    defaultValues,
  );

  const [loadLinkedObjects] = useLazyQuery(LINKED_OBJECTS, {
    onCompleted: (e) => {
      const linkedObjects = e.object.objectsToObjectsByObject1Id.map((item) => ({
        value: item.object2.id,
        title: item.object2.name,
      }));
      const objects2 = [...linkedObjects];

      if (props.value) {
        objects2.push(props.value);
      }
      setLinkedObjects(orderBy(uniqBy(objects2, "value"), ['title'], ['asc']));
    },
  });

  const submit = () => props.onResolve();
  const reject = () => props.onReject();

  const handleClose = () => reject();

  const handleInputChange = (e) => {
    let { name, value, checked } = e.target;

    if (checked) value = checked;

    if (name === "object") {
      setValues({ [name]: e.target.rawValue });
    } else {
      setValues({ [name]: value });
    }
  };

  const handleLinkWidget = () => {
    props.save(values["object"]);
    submit();
  };

  useEffect(() => {
    if (props.objectId) {
      setValues({ object: props.objectId });
    }
  }, [props.objectId]);

  useEffect(() => {
    if (props.settingsLinkedOnly.value && props.schemaId) {
      loadLinkedObjects({
        variables: {
          id: props.id,
          schemaId: props.schemaId,
        }
      });
    }
  }, [props.settingsLinkedOnly]);

  return (
    <>
      <CommonModal
        modalOpen={props.isOpen}
        title={"Select object"}
        handleClose={handleClose}
        buttons={
          <>
            <Button color="inherit" onClick={handleClose}>
              {msg.linkWidgetModal.buttonCancel}
            </Button>
            <Button color="primary" onClick={handleLinkWidget}>
              {msg.linkWidgetModal.buttonSave}
            </Button>
          </>
        }
      >
        <Grid container direction="column" spacing={2}>
          <Grid item>
            {!props.settingsLinkedOnly.value && (
              <ObjectsAutocomplete
                schemaId={props.schemaId}
                name="object"
                objectId={props.objectId}
                handleChange={handleInputChange}
              />
            )}

            {props.settingsLinkedOnly.value && (
              <CustomAutocomplete
                disabled={!Boolean(props.schemaId)}
                value={values.object?.value || values.object}
                name="object"
                list={linkedObjects}
                fullWidth={true}
                label={"Object name"}
                clearFieldIcon={true}
                onChange={(e) => {
                  setValues({ object: e.target.rawValue });
                }}
              >
              </CustomAutocomplete>
            )}
          </Grid>
        </Grid>
      </CommonModal>
    </>
  );
};

export default create(ParameterObjectModal);
