import EditIcon from "@mui/icons-material/Edit";
import HistoryIcon from "@mui/icons-material/History";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { ListItemSecondaryAction } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import ListSubheader from "@mui/material/ListSubheader";
import Typography from "@mui/material/Typography";
import makeStyles from "@mui/styles/makeStyles";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setSettings } from "../../../store/settingsSlice";
import EditWidgetPropertyModal from "../../EditWidgetPropertyModal";
import useMoreMenu from "../../useMoreMenu";
import HistoryProperty from "../history/HistoryProperty";
import useHandleCopy from "../menu/handlers/useHandleCopy";
import EditActionAdvancedModal from "./EditActionAdvancedButton";
import AccessSection from "../basic/AccessSection";
import ServiceSection from "../basic/ServiceSection";
import DescriptionSection from "../basic/DescriptionSection";

const useStyles = makeStyles((theme) => ({
  listSubheader: {
    marginTop: "15px",
    marginBottom: "10px",
  },
  itemToHideOrShow: {
    visibility: "hidden",
    opacity: 1,
    transition: "opacity 0.2s linear",
    [theme.breakpoints.down("sm")]: {
      visibility: "visible",
      opacity: 1,
    },
  },
  itemToHover: {
    "&:hover $itemToHideOrShow": {
      visibility: "visible",
      opacity: 1,
      transition: "opacity 0.2s linear",
    },
  },
}));

const AdvancedButtonGeneralTab = (props) => {
  const { item } = props;
  const { MoreMenu: ColumnMenu, openMoreMenu: openColumnMenu } = useMoreMenu();
  const { MoreMenu: TextMenu, openMoreMenu: openTextMenu } = useMoreMenu();
  const dispatch = useDispatch();

  const [idProperty, setIdProperty] = useState(null);
  const isShowHistoryProperty = useSelector(
    (state) => state.settings.isShowHistoryProperty
  );

  const classes = useStyles({
    listItem: {
      minHeight: "56px",
    },
  });

  const getPropertyByKey = (key) => {
    return item.objectProperties.find((item) => item.key === key);
  };

  const valueProperty = () => getPropertyByKey("valueValue");
  const buttonText = () => getPropertyByKey("valueText");

  const handleCopy = useHandleCopy();

  return (
    <>
      {isShowHistoryProperty && (
        <HistoryProperty id={idProperty} object={props.item} />
      )}
      <List>
        <ColumnMenu
          items={[
            {
              icon: <EditIcon />,
              title: "Edit action",
              id: "edit_prop",
              handleAction: (obj) => {
                EditActionAdvancedModal({
                  widgetId: obj.id,
                })
                  .then()
                  .catch(() => {});
              },
              disabled: false,
            },
            {
              icon: <HistoryIcon />,
              title: "Show history",
              id: "show_history",
              disabled: true,
              handleAction: (obj) => {
                setIdProperty(obj.propId);
                dispatch(setSettings({ isShowHistoryProperty: true }));
              },
            },
          ]}
        />
        <TextMenu
          items={[
            {
              icon: <EditIcon />,
              title: "Edit text",
              id: "edit_prop",
              handleAction: (obj) => {
                EditWidgetPropertyModal({
                  widgetId: obj.id,
                  propId: buttonText().id,
                  propKey: buttonText().key,
                })
                  .then()
                  .catch(() => {});
              },
              disabled: false,
            },
            {
              icon: <HistoryIcon />,
              title: "Show history",
              id: "show_history",
              disabled: true,
              handleAction: (obj) => {
                setIdProperty(obj.propId);
                dispatch(setSettings({ isShowHistoryProperty: true }));
              },
            },
          ]}
        />
        <ListSubheader color="primary" className={classes.listSubheader}>
          <Typography variant="subtitle2">Value</Typography>
        </ListSubheader>
        <ListItem
          classes={{
            container: classes.itemToHover,
          }}
          style={{ height: "48px", paddingRight: "48px" }}
          button
          onClick={() => {}}
        >
          <ListItemIcon></ListItemIcon>
          <ListItemText
            primary={
              <Typography variant="body1">
                Target: {valueProperty().value.title}
              </Typography>
            }
            onClick={(e) => {}}
          />
          <ListItemSecondaryAction className={classes.itemToHideOrShow}>
            <IconButton
              edge="end"
              aria-label="more"
              onClick={(e) => {
                openColumnMenu(e, {
                  ...props.item,
                  item: item,
                });
              }}
              size="large"
            >
              <MoreVertIcon />
            </IconButton>
          </ListItemSecondaryAction>
        </ListItem>
        <ListItem
          classes={{
            container: classes.itemToHover,
          }}
          style={{ height: "48px", paddingRight: "48px" }}
          button
          onClick={() => {}}
        >
          <ListItemIcon></ListItemIcon>
          <ListItemText
            title={buttonText()?.value || "n/a"}
            primary={
              <Typography variant="body1" noWrap={true}>
                Text: {buttonText()?.value || "n/a"}
              </Typography>
            }
          />
          <ListItemSecondaryAction className={classes.itemToHideOrShow}>
            <IconButton
              edge="end"
              aria-label="more"
              onClick={(e) => {
                openTextMenu(e, {
                  ...props.item,
                  item: buttonText(),
                });
              }}
              size="large"
            >
              <MoreVertIcon />
            </IconButton>
          </ListItemSecondaryAction>
        </ListItem>

        <ServiceSection classes={classes} item={item} />

        <AccessSection classes={classes} item={item} />

        {item.description && <DescriptionSection classes={classes} item={item} />}
      </List>
    </>
  );
};

export default AdvancedButtonGeneralTab;
