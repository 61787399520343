import LocalizedStrings from "react-localization";

export let msg = new LocalizedStrings({
  en: {
    default: {
      cancel: "Cancel",
      ok: "Ok",
      save: "Save",
      add: "Add",
      download: "Download",
      execute: "Execute",
      object: "Object",
      copy: "Copy",
    },
    appBar: {
      search: "Search...",
    },
    home: {
      addNew: "ADD NEW DASHBOARD",
      boardName: "Some content",
      info: "(like widgets)",
    },
    mainSideMenu: {
      home: "Home",
      account: "Account",
      widgets: "Widgets",
      boards: "Boards",
      boardName: "Board name",
      addBoard: "Add board",
      users: "Users",
      userGroups: "User groups",
      userGroup: "User group",
      settings: "Settings",
      about: "About",
      help: "Help",
    },
    mainToolbar: {
      goBack: "go back",
    },
    settingsModal: {
      settings: "Settings",
      general: "General",
      defaultTheme: "Default theme",
      themeLight: "Light",
      themeDark: "Dark",
      defaultLang: "Default language",
      langEnglish: "English",
      langRussian: "Русский",
      appTitle: "App title",
      companyLogo: "Company logo",
      appLogo: "App logo",
      simulation: "Simulation mode",
      buttonCancel: "Cancel",
      buttonSave: "Save",
      copied: "UUID copied to clipboard",
    },
    notificationsModal: {
      notifications: "Notifications",
      all: "All",
      buttonShowAll: "Show all",
      buttonClose: "Close",
      onObject: "On object",
    },
    profileModal: {
      profile: "Profile",
      editProfile: "Edit account",
      programSettings: "Program settings",
      notificationsSettings: "Notifications settings",
      logOut: "Logout",
      buttonClose: "Close",
    },
    addDashboardModal: {
      addBoard: "Add new board",
      name: "Name",
      description: "Description",
      backgroundImage: "Background image",
      added: "Dashboard added",
      buttonCancel: "Cancel",
      buttonAdd: "Add",
    },
    addGroupModal: {
      addGroup: "Add container",
      name: "Name",
      boardColumn: "Board column",
      boardRow: "Board row",
      columnCount: "Column count",
      rowCount: "Row count",
      useHeight: "Use height",
      description: "Description",
      enabled: "Enabled",
      added: "Container added",
      buttonCancel: "Cancel",
      buttonAdd: "Add",
    },
    deleteGroupModal: {
      deleteGroup: "Delete container",
      text: 'Delete "{containerName}" container?',
      deleted: "Container deleted",
      buttonCancel: "Cancel",
      buttonDelete: "Delete",
    },
    deleteGroupsModal: {
      deleteGroups: "Delete containers",
      text: "Delete {containersCount} container(s)?",
      deleted: "Container(s) deleted",
      buttonCancel: "Cancel",
      buttonDelete: "Delete",
    },
    deleteWidgetModal: {
      deleteWidget: "Delete widget",
      text: 'Delete "{widgetName}" widget?',
      deleted: "Widget deleted",
      buttonCancel: "Cancel",
      buttonDelete: "Delete",
    },
    deleteDashboardModal: {
      deleteDashboard: "Delete dashboard",
      text: 'Delete "{dashboardName}" dashboard?',
      deleted: "Dashboard deleted",
      buttonCancel: "Cancel",
      buttonDelete: "Delete",
    },
    addWidgetModal: {
      addWidget: "Add widget",
      name: "Name",
      widgetType: "Widget type",
      enabled: "Enabled",
      description: "Description",
      added: "Widget added",
      buttonCancel: "Cancel",
      buttonAdd: "Add",
    },
    editWidgetModal: {
      editWidget: "Edit widget",
      name: "Name",
      widgetType: "Widget type",
      enabled: "Enabled",
      description: "Description",
      updated: "Widget saved",
      buttonCancel: "Cancel",
      buttonUpdate: "Save",
    },
    editWidgetColorsModal: {
      colorsSettings: "Colors settings",
      defaultColor: "Default color",
      color: "Color",
      state: "State",
      objectColor: "Object color",
      option: "Option",
      value: "Value",
      contains: "contains",
      buttonCancel: "Cancel",
      buttonUpdate: "Save",
    },
    editWidgetImagesModal: {
      imagesSettings: "Optional images",
      defaultImage: "Default media",
      image: "Media",
      state: "State",
      objectImage: "Media",
      option: "Option",
      value: "Value",
      contains: "contains",
      buttonCancel: "Cancel",
      buttonUpdate: "Save",
    },
    addNewMediaModal: {
      addNewMedia: "Add new media",
      mediaName: "Media name",
      schemaName: "Schema name",
      enabled: "enabled",
      description: "Description",
      buttonCancel: "Cancel",
      buttonUpdate: "Add",
    },
    editWidgetChartModal: {
      editCharts: "Edit charts",
      color: "Color",
      object: "Object",
      property: "Property",
      label: "Label",
      chart: "Chart",
      buttonCancel: "Cancel",
      buttonUpdate: "Save",
    },
    EditWidgetColorChartModal: {
      editCharts: "Edit charts",
      color: "Color",
      object: "Object",
      property: "Property",
      label: "Label",
      chart: "Chart",
      buttonCancel: "Cancel",
      buttonUpdate: "Save",
    },
    editWidgetSourceModal: {
      editSource: "Edit source",
      source: "Source",
      property: "Property",
      buttonCancel: "Cancel",
      buttonSave: "Save",
      clear: "Clear",
    },
    linkWidgetModal: {
      linkObject: "Add existing object",
      object: "Object to link",
      buttonCancel: "Cancel",
      buttonSave: "Save",
    },
    addNewObjectModal: {
      addNewObject: "Add new object",
      name: "Name",
      schemaType: "Schema type",
      schemaName: "Schema name",
      linkedOnly: "Linked only",
      enabled: "Enabled",
      description: "Description",
      buttonCancel: "Cancel",
      buttonAdd: "Add",
    },
    changeAccessModal: {
      buttonCancel: "Cancel",
      buttonSave: "Save",
      title: "Access Rights"
    },
    copyWidgetModal: {
      createCopy: "Create copy",
      name: "Name",
      targetDashboard: "Target dashboard",
      buttonCancel: "Cancel",
      buttonCreate: "Create",
    },
    editWidgetPropertyModal: {
      editProperty: "Edit property",
      buttonCancel: "Cancel",
      buttonSave: "Save",
    },
    editWidgetTextModal: {
      edit: "Edit text",
      text: "Text",
      buttonCancel: "Cancel",
      buttonSave: "Save",
    },
    editWidgetActionModal: {
      editAction: "Edit Action",
      object: "Object",
      control: "Control",
      paramName: "Parameter name",
      buttonCancel: "Cancel",
      buttonSave: "Save",
    },
    editWidgetAlarmsModal: {
      alarms: "Alarms",
      alert: "Alert",
      alarmCondition: "Alarm condition",
      allowedTime: "Allowed time of stay in the state",
      alarmTimeInterval: "Alarm time interval",
      from: "From",
      to: "To",
      minutes: "minutes",
      seconds: "seconds",
      minute: "minute",
      second: "second",
      contains: "contains",
      buttonCancel: "Cancel",
      buttonUpdate: "Save",
      options: "Options",
    },
    editGroupModal: {
      editGroup: "Edit container",
      name: "Name",
      enabled: "Enabled",
      description: "Description",
      updated: "Container saved",
      buttonCancel: "Cancel",
      buttonUpdate: "Save",
    },
    editDashboardModal: {
      editDashboard: "Edit dashboard",
      name: "Name",
      titleStyle: "Title style",
      compactMode: "Compact mode",
      description: "Description",
      backgroundImage: "Background image",
      updated: "Dashboard saved",
      buttonCancel: "Cancel",
      buttonUpdate: "Save",
      buttonCopy: "Copy UUID",
      buttonDelete: "Delete board",
      copied: "UUID copied to clipboard",
    },
    editAccessModal: {
      accessRights: "Access rights",
      editorsGroup: "Editors group",
      usersGroup: "Users group",
      readersGroup: "Readers group",

      updated: "Data updated",
      buttonCancel: "Cancel",
      buttonUpdate: "Save",
    },
    editNotificationsSettingsModal: {
      notificationsSettings: "Notifications settings",

      general: "General",
      options: "Options",

      allNotifications: "All notifications",
      favoritesOnly: "Favorites only",
      muteAll: "Mute all",

      showNotifications: "Show notifications from other apps",
      sms: "SMS notifications",
      email: "E-Mail notifications",

      updated: "Data updated",
      buttonCancel: "Cancel",
      buttonUpdate: "Save",
    },
    aboutModal: {
      aboutProgram: "About program",
      application: "Application",
      pixelAdminVer: "PixelAdmin App version",
      pixelCoreVer: "PixelCore version",
      pixelFrontVer: "PixelBoard version",
      pixelCoreTimeZone: "Pixel Core timezone",
      aboutUs: "About us",
      buttonOK: "OK",
    },
    editDeviceModal: {
      title: "Edit object",
    },
    dashboard: {
      deleteGroup: "Delete container",
      editGroup: "Edit container",
      addGroup: "Add container",
      addWidget: "Add widget to container",
      noContainers: "No containers added yet. Switch to edit mode to add one",
    },
    noDashboards: {
      noDashboards: "No dashboards yet",
      addOne: "Add one",
    },
    widgetEdit: {
      add: "Add widget into container",
      delete: "Delete this widget",
      moveRight: "Swap widget with one on the right",
      moveLeft: "Swap widget with one on the left",
      edit: "Edit widget",
      back: "Move widget backward",
      forward: "Move widget forward",
    },
    sideCard: {
      general: "General",
      properties: "Properties",
      source: "Source",
      controls: "Controls",
      widgets: "Widgets",
      notifications: "Notifications",
      objects: "Objects",
      enabled: "Enabled",
      disabled: "Disabled",
      serviceInfo: "Service info",
      settings: "Settings",
      columnCount: "Column count:",
      access: "Access",
      description: "Description",
      value: "Value",
      moreMenu: {
        edit: "Edit",
        changeAccess: "Change access",
        disable: "Disable",
        enable: "Enable",
        mute: "Mute",
        createCopy: "Create copy",
        sendNotification: "Send notification",
        showHistory: "Show history",
        downloadNotifications: "Download notifications",
        copyUUID: "Copy UUID",
        delete: "Delete",
        optionalColors: "Optional states",
        optionalImages: "Optional images",
        unlink: "Unlink",
        editChart: "Edit chart",
      },
      addExistingObject: "Add existing object",
      addNewObject: "Add new object",
    },
    error404: {
      pageNotFound: "Page not found, sorry",
    },
    misc: {
      na: "n/a",
    },
    color: {
      red: "Red",
      blue: "Blue",
      green: "Green",
      yellow: "Yellow",
      orange: "Orange",
    },
  },
  ru: {
    default: {
      cancel: "Отмена",
      ok: "Ok",
      save: "Сохранить",
      add: "Добавить",
      download: "Скачать",
      execute: "Выполнить",
      object: "Объект",
      copy: "Копировать",
    },
    appBar: {
      search: "Поиск...",
    },
    home: {
      addNew: "ДОБАВИТЬ ПАНЕЛЬ",
      boardName: "Название панели",
      info: "Информация",
    },
    mainSideMenu: {
      home: "Главная",
      account: "Профиль",
      widgets: "Виджеты",
      boards: "Панели",
      boardName: "Название панели",
      addBoard: "Добавить панель",
      users: "Пользователи",
      userGroups: "Группы пользователей",
      userGroup: "Группа пользователей",
      settings: "Настройки",
      about: "О проекте",
      help: "Помощь",
    },
    mainToolbar: {
      goBack: "Назад",
    },
    settingsModal: {
      settings: "Настройки",
      general: "Общие",
      defaultTheme: "Тема по умолчанию",
      themeLight: "Светлая",
      themeDark: "Тёмная",
      defaultLang: "Язык по умолчанию",
      langEnglish: "English",
      langRussian: "Русский",
      appTitle: "Название приложения",
      companyLogo: "Логотип компании",
      appLogo: "Логотип приложения",
      simulation: "Режим имитации данных",
      buttonCancel: "Отмена",
      buttonSave: "Сохранить",
      copied: "UUID скопирован",
    },
    editDeviceModal: {
      title: "Редактировать объект",
    },
    notificationsModal: {
      notifications: "Уведомления",
      all: "Все",
      buttonShowAll: "Показать все",
      buttonClose: "Закрыть",
      onObject: "По объекту",
    },
    profileModal: {
      profile: "Профиль",
      editProfile: "Редактировать профиль",
      programSettings: "Настройки программы",
      notificationsSettings: "Настройки уведомлений",
      logOut: "Выйти",
      buttonClose: "Закрыть",
    },
    addDashboardModal: {
      addBoard: "Добавить новый дашборд",
      name: "Имя",
      description: "Описание",
      backgroundImage: "Фоновое изображение",
      added: "Дашборд добавлен",
      buttonCancel: "Отмена",
      buttonAdd: "Добавить",
    },
    addGroupModal: {
      addGroup: "Добавить контейнер",
      name: "Имя",
      boardColumn: "Board column",
      boardRow: "Board row",
      columnCount: "Column count",
      rowCount: "Row count",
      useHeight: "Use height",
      description: "Описание",
      enabled: "Включён",
      added: "Контейнер добавлен",
      buttonCancel: "Отмена",
      buttonAdd: "Добавить",
    },
    deleteGroupModal: {
      deleteGroup: "Удалить контейнер",
      text: 'Удалить этот контейнер "{containerName}"?',
      deleted: "Контейнер удалён",
      buttonCancel: "Отмена",
      buttonDelete: "Удалить",
    },
    deleteGroupsModal: {
      deleteGroups: "Удалить контейнеры",
      text: 'Удалить "{containersCount}" контейнеров?',
      deleted: "Контейнеры удалены",
      buttonCancel: "Отмена",
      buttonDelete: "Удалить",
    },
    deleteWidgetModal: {
      deleteWidget: "Удалить виджет?",
      text: 'Удалить виджет "{widgetName}"?',
      deleted: "Виджет удалён",
      buttonCancel: "Отмена",
      buttonDelete: "Удалить",
    },
    deleteDashboardModal: {
      deleteDashboard: "Удалить дашборд?",
      text: 'Удалить  дашборд "{dashboardName}"?',
      deleted: "Дашборд удалён",
      buttonCancel: "Отмена",
      buttonDelete: "Удалить",
    },
    addWidgetModal: {
      addWidget: "Добавить виджет",
      name: "Имя",
      widgetType: "Тип виджета",
      enabled: "Включён",
      description: "Описание",
      added: "Виджет добавлен",
      buttonCancel: "Отмена",
      buttonAdd: "Добавить",
    },
    editWidgetModal: {
      editWidget: "Редактирование виджета",
      name: "Имя",
      widgetType: "Тип виджета",
      enabled: "Включён",
      description: "Описание",
      updated: "Виджет сохранён",
      buttonCancel: "Отмена",
      buttonUpdate: "Сохранить",
    },
    editWidgetColorsModal: {
      colorsSettings: "Настройки цветов",
      defaultColor: "Цвет по умолчанию",
      color: "Цвет",
      state: "Состояние",
      objectColor: "Цвет объекта",
      option: "Настройка",
      value: "Значение",
      contains: "содержит",
      buttonCancel: "Отмена",
      buttonUpdate: "Сохранить",
    },
    editWidgetImagesModal: {
      imagesSettings: "Настройки изображений",
      defaultImage: "Изображение по умолчанию",
      image: "Изображение",
      state: "Состояние",
      objectImage: "Изображение",
      option: "Настройка",
      value: "Значение",
      contains: "содержит",
      buttonCancel: "Отмена",
      buttonUpdate: "Сохранить",
    },
    editWidgetSourceModal: {
      editSource: "Редактирование источника",
      source: "Источник",
      property: "Свойство",
      buttonCancel: "Отмена",
      buttonSave: "Сохранить",
      clear: "Удалить",
    },
    linkWidgetModal: {
      linkObject: "Привязать объект",
      object: "Объект для привязки",
      buttonCancel: "Отмена",
      buttonSave: "Сохранить",
    },
    addNewObjectModal: {
      addNewObject: "Добавить новый объект",
      name: "Имя",
      schemaType: "Тип схемы",
      schemaName: "Название схемы",
      linkedOnly: "Только связанные",
      enabled: "Включено",
      description: "Описание",
      buttonCancel: "Отмена",
      buttonAdd: "Добавить",
    },
    copyWidgetModal: {
      createCopy: "Создать копию",
      name: "Имя",
      targetDashboard: "Целевой дашборд",
      buttonCancel: "Отмена",
      buttonCreate: "Создать",
    },
    editWidgetPropertyModal: {
      editProperty: "Редактирование свойства",
      buttonCancel: "Отмена",
      buttonSave: "Сохранить",
    },
    editWidgetTextModal: {
      edit: "Редактирование текста",
      text: "Текст",
      buttonCancel: "Отмена",
      buttonSave: "Сохранить",
    },
    editWidgetActionModal: {
      editAction: "Редактирование действия",
      object: "Объект",
      control: "Вызов",
      paramName: "Параметр",
      buttonCancel: "Отмена",
      buttonSave: "Сохранить",
    },
    editWidgetAlarmsModal: {
      alarms: "Сигнализация",
      alert: "Сигнал тревоги",
      alarmCondition: "Условие тревоги",
      allowedTime: "Сколько времени оставаться в состоянии тревоги",
      alarmTimeInterval: "Интервал между сигналами",
      from: "с",
      to: "по",
      minutes: "минут",
      seconds: "секунд",
      minute: "минута",
      second: "секунда",
      contains: "содержит",
      buttonCancel: "Отмена",
      buttonUpdate: "Сохранить",
      options: "Опции",
    },
    editGroupModal: {
      editGroup: "Редактирование контейнера",
      name: "Имя",
      enabled: "Включён",
      description: "Описание",
      updated: "Контейнер сохранён",
      buttonCancel: "Отмена",
      buttonUpdate: "Сохранить",
    },
    editDashboardModal: {
      editDashboard: "Редактирование дашборда",
      name: "Заголовок",
      titleStyle: "Стиль заголовка",
      compactMode: "Компактный режим",
      description: "Описание",
      backgroundImage: "Фоновое изображение",
      updated: "Дашборд сохранён",
      buttonCancel: "Отмена",
      buttonUpdate: "Сохранить",
      buttonCopy: "Скопировать UUID",
      buttonDelete: "Удалить дашборд",
      copied: "UUID скопирован",
    },
    editAccessModal: {
      accessRights: "Права доступа",
      editorsGroup: "Группа редакторов",
      usersGroup: "Группа пользователей",
      readersGroup: "Группа читателей",

      updated: "Данные сохранены",
      buttonCancel: "Отмена",
      buttonUpdate: "Сохранить",
    },
    editNotificationsSettingsModal: {
      notificationsSettings: "Настройки уведомлений",

      general: "Общие",
      options: "Опции",

      allNotifications: "Все уведомления",
      favoritesOnly: "Только избранные",
      muteAll: "Выключить все",

      showNotifications: "Показывать уведомления из других приложений",
      sms: "SMS уведомления",
      email: "E-Mail уведомления",

      updated: "Данные сохранены",
      buttonCancel: "Отмена",
      buttonUpdate: "Сохранить",
    },

    aboutModal: {
      aboutProgram: "О программе",
      application: "Application",
      pixelAdminVer: "PixelAdmin App version",
      pixelCoreVer: "PixelCore version",
      pixelFrontVer: "PixelBoard version",
      aboutUs: "О нас",
      buttonOK: "Закрыть",
    },
    dashboard: {
      deleteGroup: "Удалить контейнер",
      editGroup: "Редактировать контейнер",
      addGroup: "Добавить контейнер",
      addWidget: "Добавить виджет в контейнер",
      noContainers: "Пока не добавлено ни одного контейнера. Перейдите в режим редактирования, чтобы добавить первый",
    },
    noDashboards: {
      noDashboards: "Пока здесь нет ни одного дашборда",
      addOne: "Добавить первый",
    },
    widgetEdit: {
      add: "Добавить ещё виджет в эту контейнер",
      delete: "Удалить этот виджет",
      moveRight: "Переместить виджет вправо",
      moveLeft: "Переместить виджет влево",
      edit: "Редактировать виджет",
      back: "Переместить виджет назад",
      forward: "Переместить виджет вперёд",
    },
    sideCard: {
      general: "Общее",
      properties: "Свойства",
      controls: "Контроль",
      widgets: "Виджеты",
      source: "Источник",
      notifications: "Уведомления",
      objects: "Объекты",
      enabled: "включён",
      disabled: "выключен",
      serviceInfo: "Служебная информация",
      settings: "Настройки",
      columnCount: "Количество столбцов:",
      access: "Права доступа",
      description: "Описание",
      value: "Значение",
      moreMenu: {
        edit: "Редактировать",
        changeAccess: "Изменить права доступа",
        disable: "Выключить",
        enable: "Включить",
        mute: "Заткнуть",
        createCopy: "Создать копию",
        sendNotification: "Отправить уведомление",
        showHistory: "Показать историю",
        downloadNotifications: "Скачать уведомления",
        copyUUID: "Скопировать UUID",
        delete: "Удалить",
        optionalColors: "Настройки цветов",
        unlink: "Отвязать",
        editChart: "Редактировать диаграмму",
      },
      addExistingObject: "Добавить существующий объект",
      addNewObject: "Добавить новый объект",
    },
    error404: {
      pageNotFound: "Извините, запрошенная страница не найдена",
    },
    misc: {
      na: "н/д",
    },
    color: {
      red: "Красный",
      blue: "Голубой",
      green: "Зелёный",
      yellow: "Жёлтый",
      orange: "Оранжевый",
    },
  },
});
