import useMedia from "../../../utils/useMedia";

export const DataboxIcon = ({ id, colors, width, height, padding }) => {
  const { getImageById } = useMedia();

  return (
    <div style={{
      padding: padding,
      height: height,
      width: width,
      flexShrink: 0,
    }}>
      <div
        data-test-icon="icon_value-icon"
        style={{
          backgroundRepeat: "no-repeat",
          backgroundSize: "contain",
          WebkitMaskRepeat: `no-repeat`,
          WebkitMaskSize: `contain`,
          WebkitMaskImage: `url(${getImageById(id)})`,
          maskImage: `url(${getImageById(id)})`,
          maskRepeat: `no-repeat`,
          maskSize: `contain`,
          backgroundColor: colors?.fg || "transparent",
          height: '100%',
          width: '100%',
        }}
      />
    </div>

  );
};
