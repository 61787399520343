import { useApolloClient } from "@apollo/client";
import { Email, Key, WhatsApp } from "@mui/icons-material";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import AnnouncementIcon from "@mui/icons-material/Announcement";
import EditIcon from "@mui/icons-material/Edit";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import SettingsIcon from "@mui/icons-material/Settings";
import {
  Box,
  Button,
  CircularProgress,
  ClickAwayListener,
  Grow,
  Paper,
  Popper,
} from "@mui/material";
import Avatar from "@mui/material/Avatar";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import Divider from "@mui/material/Divider";
import Fab from "@mui/material/Fab";
import Grid from "@mui/material/Grid";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Typography from "@mui/material/Typography";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/styles";
import makeStyles from "@mui/styles/makeStyles";
import { Fragment, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { msg } from "../../messages";
import { useAuth } from "../../utils/useAuth";
import EditNotificationsSettingsModal from "../EditNotificationsSettingsModal";
import EditProgramSettingsModal from "../EditProgramSettingsModal";
import useCustomNavigate from "../hooks/useCustomNavigate";
import ChangePassword from "./ChangePassword";
import { CHANNELS } from "../../utils/notificationProfiles";
import BellPlus from "../icons/bellPlus";
import LoadingButton from "@mui/lab/LoadingButton";
import { setSettings } from "../../store/settingsSlice";

const ProfileModalNew = (props) => {
  const [open, setOpen] = useState(false);
  const anchorRef = useRef(null);
  const client = useApolloClient();
  const { loadUser, isLoadingUser } = useAuth();
  const dispatch = useDispatch();
  const theme = useTheme();
  const isSm = useMediaQuery(theme.breakpoints.down("sm"));

  const handleClick = (event) => {
    setOpen(true);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };


  const useStyles = makeStyles((theme) => ({
    modalDialog: {
      paddingTop: 0,
    },

    info: {
      padding: "12px",
    },
    modalContent: {
      paddingLeft: "0",
      paddingRight: "0",
      paddingBottom: "0",
      paddingTop: "0",
    },
    listItemIcon: {
      minWidth: "40px",
    },
    menu: {
      width: "412px",
    },
    name: {
      color: theme.palette.black,
    },
    email: {
      color: theme.palette.gray1,
    },
  }));

  const classes = useStyles();

  const user = useSelector((state) => state.user);

  const history = useCustomNavigate();

  const handleLogOutClick = () => {
    client.clearStore().then(() => {
      localStorage.removeItem("authToken");
      localStorage.removeItem("refreshToken");
      localStorage.removeItem("tokenId");
      dispatch(setSettings({ isDrawerOpen: false }));
      history("/login");
    });
    // logout();
  };

  const getProfileByChannel = (channel) => {
    if (!user) {
      return null;
    }

    const profiles = user?.userProfiles?.[0]?.profile?.objectsToObjectsByObject1Id.map(
      item => item.object2,
    ) || [];

    return profiles.find(
      item => item.deliveryChannel === channel,
    );
  };

  if (!user)
    return (
      <Fab
        size="small"
        color="primary"
        style={{ marginLeft: "16px" }}
        aria-label="profile"
      >
        UN
      </Fab>
    );
  return (
    <Fragment>
      {!isSm && (
        <>
          <Fab
            data-test="userMenu"
            ref={anchorRef}
            color="primary"
            size="small"
            style={{ marginLeft: "16px" }}
            aria-label="profile"
            onClick={() => {
              if (!open) {
                loadUser().then(() => {
                  handleClick(true);
                });
              } else {
                handleClick(false);
              }
            }}
          >
            {isLoadingUser && <Box sx={{ color: "#fff", marginTop: "6px", position: "absolute" }}>
              <CircularProgress
                thickness={2}
                color="inherit"
                size={40}
                value={user?.mName?.[0] || user.login[0]}>
              </CircularProgress>
            </Box>}
            {user?.mName?.[0] || user.login[0]}
          </Fab>

          <Popper
            open={open}
            anchorEl={anchorRef.current}
            modifiers={[
              {
                name: "offset",
                options: {
                  offset: [0, 8],
                },
              },
            ]}
            disablePortal={true}
            placement="bottom-end"
            transition
          >
            {({ TransitionProps }) => (
              <Grow {...TransitionProps}>
                <Paper elevation={8}>
                  <ClickAwayListener onClickAway={handleClose}>
                    <div>
                      <Grid
                        container
                        className={classes.info}
                        style={{ width: "412px", padding: "8px 16px" }}
                      >
                        <Grid xs={2} alignItems="center" container item>
                          <Avatar
                            size="small"
                            style={{
                              fontSize: "18px",
                              backgroundColor: "black",
                              color: "white",
                              boxShadow: "none",
                            }}
                            aria-label="profile"
                          >
                            {user?.mName?.[0] || user.login[0]}
                          </Avatar>
                        </Grid>
                        <Grid xs={10} item>
                          <Grid
                            xs={12}
                            item
                            container
                            justifyContent="flex-start"
                          >
                            <Typography variant="h6" className={classes.name}>
                              {user.login || user?.mName}
                            </Typography>
                          </Grid>
                          <Grid xs={12} item>
                            <Typography
                              variant="body1"
                              className={classes.email}
                            >
                              {user.mEmail}
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Divider />
                      <Grid container direction="column">
                        <Grid item>
                          <List>
                            <ListItem button disabled={true}>
                              <ListItemIcon className={classes.listItemIcon}>
                                <SettingsIcon />
                              </ListItemIcon>
                              <ListItemText
                                primary={
                                  <Typography variant="subtitle2">
                                    {msg.profileModal.editProfile}
                                  </Typography>
                                }
                              />
                            </ListItem>

                            <ListItem
                              button
                              data-test-user-menu="changePassword"
                              onClick={() => {
                                setOpen(false);
                                ChangePassword()
                                  .then()
                                  .catch(() => {
                                  });
                              }}
                            >
                              <ListItemIcon className={classes.listItemIcon}>
                                <Key />
                              </ListItemIcon>
                              <ListItemText
                                primary={
                                  <Typography variant="subtitle2">
                                    Change password
                                  </Typography>
                                }
                              />
                            </ListItem>

                            <ListItem
                              button
                              data-test-user-menu="programSettings"
                              onClick={() => {
                                setOpen(false);
                                EditProgramSettingsModal()
                                  .then()
                                  .catch(() => {
                                  });
                              }}
                            >
                              <ListItemIcon className={classes.listItemIcon}>
                                <EditIcon />
                              </ListItemIcon>
                              <ListItemText
                                primary={
                                  <Typography variant="subtitle2">
                                    {msg.profileModal.programSettings}
                                  </Typography>
                                }
                              />
                            </ListItem>
                            {getProfileByChannel(CHANNELS.IN_APP) &&
                              <ListItem
                                button
                                data-test-user-menu="notificationsSettingsInApp"
                                onClick={() => {
                                  setOpen(false);
                                  EditNotificationsSettingsModal({ profile: getProfileByChannel(CHANNELS.IN_APP) })
                                    .then()
                                    .catch(() => {
                                    });
                                }}
                              >
                                <ListItemIcon className={classes.listItemIcon}>
                                  <BellPlus />
                                </ListItemIcon>
                                <ListItemText
                                  primary={
                                    <Typography variant="subtitle2">
                                      Notification settings
                                    </Typography>
                                  }
                                />
                              </ListItem>
                            }

                            {getProfileByChannel(CHANNELS.SMS) && <ListItem
                              data-test-user-menu="notificationsSettingsSms"
                              button
                              onClick={() => {
                                setOpen(false);
                                EditNotificationsSettingsModal({ profile: getProfileByChannel(CHANNELS.SMS) })
                                  .then()
                                  .catch(() => {
                                  });
                              }}
                            >
                              <ListItemIcon className={classes.listItemIcon}>
                                <AnnouncementIcon />
                              </ListItemIcon>
                              <ListItemText
                                primary={
                                  <Typography variant="subtitle2">
                                    SMS (Twilio)
                                  </Typography>
                                }
                              />
                            </ListItem>}
                            {
                              getProfileByChannel(CHANNELS.WHATSAPP) && <ListItem
                                button
                                data-test-user-menu="notificationsSettingsSmsWhatsApp"
                                onClick={() => {
                                  setOpen(false);
                                  EditNotificationsSettingsModal({ profile: getProfileByChannel(CHANNELS.WHATSAPP) })
                                    .then()
                                    .catch(() => {
                                    });
                                }}
                              >
                                <ListItemIcon className={classes.listItemIcon}>
                                  <WhatsApp />
                                </ListItemIcon>
                                <ListItemText
                                  primary={
                                    <Typography variant="subtitle2">
                                      WhatsApp (Twilio)
                                    </Typography>
                                  }
                                />
                              </ListItem>

                            }


                            {getProfileByChannel(CHANNELS.EMAIL) && <ListItem
                              button
                              data-test-user-menu="notificationsSettingsEmail"
                              onClick={() => {
                                setOpen(false);
                                EditNotificationsSettingsModal({ profile: getProfileByChannel(CHANNELS.EMAIL) })
                                  .then()
                                  .catch(() => {
                                  });
                              }}
                            >
                              <ListItemIcon className={classes.listItemIcon}>
                                <Email />
                              </ListItemIcon>
                              <ListItemText
                                primary={
                                  <Typography variant="subtitle2">
                                    E-mail notifications
                                  </Typography>
                                }
                              />
                            </ListItem>}

                            <ListItem
                              data-test-user-menu="logout"
                              button
                              onClick={handleLogOutClick}
                            >
                              <ListItemIcon className={classes.listItemIcon}>
                                <ExitToAppIcon />
                              </ListItemIcon>
                              <ListItemText
                                primary={
                                  <Typography variant="subtitle2">
                                    {msg.profileModal.logOut}
                                  </Typography>
                                }
                              />
                            </ListItem>
                          </List>
                        </Grid>
                      </Grid>
                    </div>
                  </ClickAwayListener>
                </Paper>
              </Grow>
            )}
          </Popper>
        </>
      )}
      {isSm && (
        <>
          <ListItem onClick={() => setOpen(true)}>
            <ListItemIcon className={classes.listItemIcon}>
              <AccountCircleIcon />
            </ListItemIcon>
            <ListItemText
              classes={{ primary: classes.listItemText }}
              primary={
                <Typography variant="subtitle2">
                  {msg.mainSideMenu.account}
                </Typography>
              }
            />
          </ListItem>
          <Dialog
            fullScreen={true}
            open={open}
            className={classes.modalDialog}
            BackdropProps={{
              classes: {
                root: classes.modalAppBackDrop,
              },
            }}
            PaperProps={{
              classes: { root: classes.modalPaper },
              style: { pointerEvents: "auto" },
            }}
            hideBackdrop={false}
            disableEnforceFocus={true}
            style={{ pointerEvents: "none" }}
          >
            <DialogContent className={classes.modalContent}>
              <Grid
                container
                direction="column"
                justifyContent="space-between"
                style={{ height: "100%" }}
              >
                <div>
                  <Grid container className={classes.info}>
                    <Grid xs={2} alignItems="center" container item>
                      <Avatar
                        size="small"
                        style={{
                          fontSize: "18px",
                          backgroundColor: "black",
                          color: "white",
                          boxShadow: "none",
                        }}
                        aria-label="profile"
                      >
                        {user?.mName?.[0] || user.login[0]}
                      </Avatar>
                    </Grid>
                    <Grid xs={10} item>
                      <Grid xs={12} item container justifyContent="flex-start">
                        <Typography variant="h6" className={classes.name}>
                          {user?.mName || user.login}
                        </Typography>
                      </Grid>
                      <Grid xs={12} item>
                        <Typography variant="body1" className={classes.email}>
                          {user.mEmail}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Divider />
                  <Grid container direction="column">
                    <Grid item>
                      <List>
                        <ListItem button disabled={true}>
                          <ListItemIcon className={classes.listItemIcon}>
                            <SettingsIcon />
                          </ListItemIcon>
                          <ListItemText
                            primary={
                              <Typography variant="subtitle2">
                                {msg.profileModal.editProfile}
                              </Typography>
                            }
                          />
                        </ListItem>
                        <ListItem
                          button
                          onClick={() => {
                            setOpen(false);
                            ChangePassword()
                              .then()
                              .catch(() => {
                              });
                          }}
                        >
                          <ListItemIcon className={classes.listItemIcon}>
                            <Key />
                          </ListItemIcon>
                          <ListItemText
                            primary={
                              <Typography variant="subtitle2">
                                Change password
                              </Typography>
                            }
                          />
                        </ListItem>
                        <ListItem button onClick={() => {
                          setOpen(false);
                          EditProgramSettingsModal()
                            .then()
                            .catch(() => {
                            });
                        }}>
                          <ListItemIcon className={classes.listItemIcon}>
                            <EditIcon />
                          </ListItemIcon>
                          <ListItemText
                            primary={
                              <Typography variant="subtitle2">
                                {msg.profileModal.programSettings}
                              </Typography>
                            }
                          />
                        </ListItem>

                        {getProfileByChannel(CHANNELS.IN_APP) &&
                          <ListItem
                            button
                            data-test-user-menu="notificationsSettingsInApp"
                            onClick={() => {
                              setOpen(false);
                              EditNotificationsSettingsModal({ profile: getProfileByChannel(CHANNELS.IN_APP) })
                                .then()
                                .catch(() => {
                                });
                            }}
                          >
                            <ListItemIcon className={classes.listItemIcon}>
                              <BellPlus />
                            </ListItemIcon>
                            <ListItemText
                              primary={
                                <Typography variant="subtitle2">
                                  Notification settings
                                </Typography>
                              }
                            />
                          </ListItem>
                        }

                        {getProfileByChannel(CHANNELS.SMS) && <ListItem
                          data-test-user-menu="notificationsSettingsSms"
                          button
                          onClick={() => {
                            setOpen(false);
                            EditNotificationsSettingsModal({ profile: getProfileByChannel(CHANNELS.SMS) })
                              .then()
                              .catch(() => {
                              });
                          }}
                        >
                          <ListItemIcon className={classes.listItemIcon}>
                            <AnnouncementIcon />
                          </ListItemIcon>
                          <ListItemText
                            primary={
                              <Typography variant="subtitle2">
                                SMS (Twilio)
                              </Typography>
                            }
                          />
                        </ListItem>}
                        {
                          getProfileByChannel(CHANNELS.WHATSAPP) && <ListItem
                            button
                            data-test-user-menu="notificationsSettingsSmsWhatsApp"
                            onClick={() => {
                              setOpen(false);
                              EditNotificationsSettingsModal({ profile: getProfileByChannel(CHANNELS.WHATSAPP) })
                                .then()
                                .catch(() => {
                                });
                            }}
                          >
                            <ListItemIcon className={classes.listItemIcon}>
                              <WhatsApp />
                            </ListItemIcon>
                            <ListItemText
                              primary={
                                <Typography variant="subtitle2">
                                  WhatsApp (Twilio)
                                </Typography>
                              }
                            />
                          </ListItem>

                        }


                        {getProfileByChannel(CHANNELS.EMAIL) && <ListItem
                          button
                          data-test-user-menu="notificationsSettingsEmail"
                          onClick={() => {
                            setOpen(false);
                            EditNotificationsSettingsModal({ profile: getProfileByChannel(CHANNELS.EMAIL) })
                              .then()
                              .catch(() => {
                              });
                          }}
                        >
                          <ListItemIcon className={classes.listItemIcon}>
                            <Email />
                          </ListItemIcon>
                          <ListItemText
                            primary={
                              <Typography variant="subtitle2">
                                E-mail notifications
                              </Typography>
                            }
                          />
                        </ListItem>}

                        <ListItem button onClick={handleLogOutClick}>
                          <ListItemIcon className={classes.listItemIcon}>
                            <ExitToAppIcon />
                          </ListItemIcon>
                          <ListItemText
                            primary={
                              <Typography variant="subtitle2">
                                {msg.profileModal.logOut}
                              </Typography>
                            }
                          />
                        </ListItem>
                      </List>
                    </Grid>
                  </Grid>
                </div>
              </Grid>
            </DialogContent>
            <Divider />
            <DialogActions>
              <Button onClick={() => setOpen(false)}>Close</Button>
            </DialogActions>
          </Dialog>
        </>
      )}
    </Fragment>
  );
};

export default ProfileModalNew;
