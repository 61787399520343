import { useMutation, useQuery } from "@apollo/client";
import { Button, Typography } from "@mui/material";
import Grid from "@mui/material/Grid";
import { loader } from "graphql.macro";
import { cloneDeep } from "lodash";
import { useReducer, useState } from "react";
import toast from "react-hot-toast";
import { create } from "react-modal-promise";
import CommonModal from "../../../components/CommonModal";
import CustomInput from "../../../components/CustomInput";
import CustomSelect from "../../../components/CustomSelect";
import CustomSelectColor from "../../../components/CustomSelectColor";
import { OPERATORS } from "../../../constants";
import { msg } from "../../../messages";
import { WIDGET_QUERY } from "../../../queries";

const UPDATE_OBJECT_PROPERTY = loader(
  "../../../graphql/UpdateObjectWithProperties.graphql"
);

const COLORS_LIST = [
  "default",
  "red",
  "blue",
  "green",
  "yellow",
  "orange",
  "gray4",
];
const EditColorsTrackingBox = (props) => {
  const [updateProperty] = useMutation(UPDATE_OBJECT_PROPERTY);

  const submit = () => props.onResolve();
  const reject = () => props.onReject();

  const { loading } = useQuery(WIDGET_QUERY, {
    variables: { objId: props.widgetId },
    onCompleted({ object }) {
      const getPropValue = (prop) =>
        object.objectProperties.find((obj) => obj.key === prop)?.value;

      setColorsConditions(getPropValue("colorsConditions"));
      defaultValues["colorsDefaultColor"] = getPropValue("colorsDefaultColor");
      setValues(defaultValues);
    },
    fetchPolicy: "network-only",
  });

  let defaultValues = {
    colorsDefaultColor: "default",
  };

  const [values, setValues] = useReducer(
    (prev, updated) => ({ ...prev, ...updated }),
    defaultValues
  );

  const [colorsConditions, setColorsConditions] = useState({
    0: {
      color: "",
      value: "",
      operator: "",
    },
    1: {
      color: "",
      value: "",
      operator: "",
    },
    2: {
      color: "",
      value: "",
      operator: "",
    },
    3: {
      color: "",
      value: "",
      operator: "",
    },
    4: {
      color: "",
      value: "",
      operator: "",
    },
  });

  const handleClose = () => reject();

  const handleInputChange = (e, index) => {
    let { name, value, checked } = e.target;

    if (index !== undefined) {
      const conditions = cloneDeep(colorsConditions);

      conditions[index] = {
        ...conditions[index],
        [name]: value,
      };

      setColorsConditions(conditions);
    } else {
      if (checked) value = checked;

      setValues({ [name]: value });
    }
  };

  return (
    <>
      <CommonModal
        loading={loading}
        modalOpen={props.isOpen}
        title={msg.editWidgetColorsModal.colorsSettings}
        handleClose={reject}
        buttons={
          <>
            <Button color="inherit" onClick={handleClose}>
              {msg.editWidgetModal.buttonCancel}
            </Button>
            <Button
              color="primary"
              onClick={() => {
                const valuesReady = [
                  {
                    propertyKey: "colorsDefaultColor",
                    value: values.colorsDefaultColor,
                  },
                  {
                    propertyKey: "colorsConditions",
                    value: colorsConditions,
                  },
                ];

                toast
                  .promise(
                    updateProperty({
                      variables: {
                        input: {
                          detailedObject: [
                            {
                              objectId: props.widgetId,
                              keyedProperties: valuesReady,
                            },
                          ],
                        },
                      },
                    }),
                    {
                      loading: "Updating colors ...",
                      success: () => `Colors updated`,
                      error: (err) => `${err.toString()}`,
                    }
                  )
                  .then(() => submit());
              }}
            >
              {msg.editWidgetModal.buttonUpdate}
            </Button>
          </>
        }
      >
        <Grid container direction="column" spacing={2}>
          <Grid item>
            <Typography variant="subtitle2" color="primary">
              {msg.editWidgetColorsModal.defaultColor}
            </Typography>
          </Grid>

          <Grid item>
            <CustomSelectColor
              name="colorsDefaultColor"
              colors={COLORS_LIST}
              label={msg.editWidgetColorsModal.color}
              value={values["colorsDefaultColor"] ?? ""}
              onChange={handleInputChange}
            />
          </Grid>

          {Object.values(colorsConditions).map((value, index) => {
            return (
              <>
                <Grid item>
                  <Typography variant="subtitle2" color="primary">
                    {`${msg.editWidgetColorsModal.state} ${index + 1}`}
                  </Typography>
                </Grid>

                <Grid item>
                  <CustomSelectColor
                    clearFieldIcon={true}
                    name="color"
                    colors={COLORS_LIST}
                    label={msg.editWidgetColorsModal.objectColor}
                    value={colorsConditions[index].color}
                    onChange={(e) => handleInputChange(e, index)}
                  />
                </Grid>

                <Grid item container>
                  <Grid item xs={4} style={{ paddingRight: "10px" }}>
                    <CustomSelect
                      clearFieldIcon={true}
                      name="operator"
                      label={msg.editWidgetColorsModal.option}
                      list={OPERATORS}
                      value={colorsConditions[index].operator}
                      onChange={(e) => handleInputChange(e, index)}
                    />
                  </Grid>
                  <Grid item xs={8} alignContent="flex-end">
                    <CustomInput
                      name="value"
                      label=" "
                      clearFieldIcon={true}
                      value={colorsConditions[index].value}
                      onChange={(e) => handleInputChange(e, index)}
                      type="string"
                    />
                  </Grid>
                </Grid>
              </>
            );
          })}
        </Grid>
      </CommonModal>
    </>
  );
};

export default create(EditColorsTrackingBox);
