import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface ISettingsState {
  theme: string;
  lang: string;
  isFullScreen: boolean;
  isShowGrid: boolean;
  isMayEdit: boolean;
  isSideBar: boolean;
  isBoardSaved: boolean;
  prevBoard: {
    widgetsPositionsWithinGroups: {};
    containerPositionWithinDashboard: [];
  };
  isViewMode: boolean;
  isEditMode: boolean;
  simulation: boolean;
  appTitle: string;
  layout: [];
  simData: 0;
  datetime: string;
  isShowCoreTime: boolean;
  icons: [];
  isShowHistory: false;
  isShowControlsHistory: false;
  isShowHistoryProperty: false;
  groupsLayout: null;
  isDrawerOpen: false;
}

const initialState: ISettingsState = {
  theme: "light",
  lang: "en",
  isFullScreen: true,
  isShowGrid: false,
  isMayEdit: false,
  isSideBar: false,
  isBoardSaved: true,
  prevBoard: {
    widgetsPositionsWithinGroups: {},
    containerPositionWithinDashboard: [],
  },
  isViewMode: false,
  isEditMode: false,
  simulation: false,
  appTitle: "Optional title text",
  layout: [],
  simData: 0,
  datetime: JSON.stringify(new Date()),
  icons: [],
  isShowHistory: false,
  isShowControlsHistory: false,
  isShowHistoryProperty: false,
  isShowCoreTime: false,
  groupsLayout: null,
  isDrawerOpen: false,
};

export const settingsSlice = createSlice({
  name: "settings",
  initialState,
  reducers: {
    setSettings: (state, action: PayloadAction<{ [key: string]: unknown }>) => {
      return { ...state, ...action.payload };
    },
  },
});

export const { setSettings } = settingsSlice.actions;

export const settingsReducer = settingsSlice.reducer;
