import { Button, CircularProgress } from "@mui/material";
import Grid from "@mui/material/Grid";
import { useEffect, useState } from "react";
import { msg } from "../../../../messages";
import CommonModal from "../../../CommonModal";

import { gql, useLazyQuery } from "@apollo/client";
import { create } from "react-modal-promise";
import SchemasAutocomplete from "../../../forms/SchemasAutocomplete";

export const SELECTED_SCHEMA = gql`
  query selectedSchema($id: UUID!) {
    schema(id: $id) {
      type
      mTags
    }
  }
`;
const SelectType = (props) => {
  const [loading, setLoading] = useState(false);
  const [schemaId, setSchemaId] = useState(props.value || null);

  const [type, setType] = useState("monitor");

  const handleInputChange = (e) => {
    let { name, value, checked } = e.target;

    if (checked) value = checked;

    setSchemaId(value);
  };
  const handleChangeType = (e) => {
    setType(e.target.value);
  };

  const submit = () => props.onResolve();

  const reject = () => props.onReject();

  const handleSave = async () => {
    setLoading(true);
    props.handleSave(props.id, schemaId).finally(() => {
      setLoading(false);
      submit();
    });
  };

  useEffect(() => {
    if (props.value) {
      // loadSchema();
    }
  }, [props.value]);

  return (
    <CommonModal
      key="selectType"
      modalOpen={props.isOpen}
      title="Select type"
      handleClose={reject}
      buttons={
        <>
          <Button color="inherit" onClick={reject}>{msg.default.cancel}</Button>
          <Button color="primary" disabled={loading} onClick={handleSave}>
            {loading ? <CircularProgress size={23} /> : msg.default.save}
          </Button>
        </>
      }
    >
      <Grid container direction="column" spacing={2}>
        <Grid item>
          <SchemasAutocomplete
            type={type}
            name="schemaId"
            label="Type name"
            schemaId={schemaId}
            handleChange={handleInputChange}
          />
        </Grid>
      </Grid>
    </CommonModal>
  );
};

export default create(SelectType);
