import { gql, useMutation } from "@apollo/client";
import List from "@mui/material/List";
import makeStyles from "@mui/styles/makeStyles";
import React, { useEffect, useState } from "react";
import { CREATE_EXECUTION } from "../../../modules/reports/api/CreateExecution";
import GeotagsSection from "./geotags/GeotagsSection";
import { getPropertyByKey } from "../../../utils/getPropertyByKey";
import PeriodsSection from "./periods/PeriodsSection";
import ServiceSection from "../basic/ServiceSection";
import AccessSection from "../basic/AccessSection";
import ParametersSection from "./ParametersSection";
import RpcSubscribeWrapper from "../static-table/RpcSubscribeWrapper";
import LoadingButton from "@mui/lab/LoadingButton";
import { Box } from "@mui/material";
import DescriptionSection from "../basic/DescriptionSection";

const useStyles = makeStyles((theme) => ({
  listSubheader: {
    marginTop: "15px",
    marginBottom: "10px",
  },
  itemToHideOrShow: {
    visibility: "hidden",
    opacity: 1,
    transition: "opacity 0.2s linear",
    [theme.breakpoints.down("sm")]: {
      visibility: "visible",
      opacity: 1,
    },
  },
  itemToHover: {
    "&:hover $itemToHideOrShow": {
      visibility: "visible",
      opacity: 1,
      transition: "opacity 0.2s linear",
    },
  },
}));

const TabGeneralTrackingBox = (props) => {
  const { item } = props;
  const classes = useStyles();
  const [createExecution, { loading }] = useMutation(CREATE_EXECUTION);

  const getProperty = (key) => {
    return item.objectProperties.find((item) => item.key === key);
  };

  const settingsPeriodsProperty = () => {
    return getPropertyByKey(item.objectProperties, "settingsPeriods");
  };

  const settingsGeotagsProperty = () => {
    return getPropertyByKey(item.objectProperties, "settingsGeotags");
  };


  const [isGetDataDisabled, setIsGetDataDisabled] = useState(true);


  useEffect(() => {
    if (item) {
      const conditions = [
        getProperty("settingsGeotags")?.value?.length,
        getProperty("settingsPeriods")?.value?.length,
        getProperty("parametersDateRange")?.value?.length,
      ];

      setIsGetDataDisabled(!conditions.every((item) => item));
    }
  }, [item]);

  const rpcHandler = () => {
    return createExecution({
      variables: {
        input: {
          controlExecution: {
            name: "UpdateReport",
            objectId: item.id,
            params: {},
          },
        },
      },
    });
  };

  return (
    <>
      <List>
        <ParametersSection classes={classes} item={item} />
        <GeotagsSection geotagsProperty={settingsGeotagsProperty()} classes={classes} item={item} />
        <PeriodsSection periodsProperty={settingsPeriodsProperty()} classes={classes} />
        <Box m={1} mb={4}>
          <RpcSubscribeWrapper
            rpcName={"UpdateReport"}
            objectId={item.id}
            disabled={isGetDataDisabled}
            object={item}
            handler={rpcHandler}
            title={"Get data"}
          >
            <LoadingButton
              fullWidth={true}
              variant={"outlined"}
            ></LoadingButton>
          </RpcSubscribeWrapper>
        </Box>
        <ServiceSection classes={classes} item={item} />
        <AccessSection classes={classes} item={item} />
        {item.description && <DescriptionSection classes={classes} item={item} />}
      </List>
    </>
  );
};

export default TabGeneralTrackingBox;
