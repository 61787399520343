import { ExportToCsv } from "export-to-csv";
import { useDispatch } from "react-redux";
import useRoute from "../../../hooks/useRoute";
import { setSettings } from "../../../store/settingsSlice";
import downloadNotifications from "../../../utils/downloadNotifications";
import {
  getPropertyByKey,
  getPropertyValueByKey,
} from "../../../utils/getPropertyByKey";
import hideIfEmpty from "../../../utils/hideIfEmpty";
import {
  isCalculator,
  isCounter,
  isTimeseries,
} from "../../../utils/objectType";
import { getWidgetType } from "../../../utils/widgetType";
import { WIDGETS_ENUM } from "../../../utils/widgetTypes";
import { useSelectWidget } from "../../create-widget/useSelectWidget";
import DeleteWidgetModal from "../../DeleteWidgetModal";
import EditColorWidgetChartModal from "../../EditColorWidgetChartModal";
import EditWidgetChartModal from "../../EditWidgetChartModal";
import EditWidgetColorsModal from "../../EditWidgetColorsModal";
import EditWidgetTableChartModal from "../../EditWidgetTableChartModal";
import useCustomNavigate from "../../hooks/useCustomNavigate";
import ChangeAccessModal from "../../modals/ChangeAccessModal/ChangeAccessModal";
import DateModal from "../../modals/DateModal";
import EditCalculator from "../../modals/EditCalculator";
import EditCounter from "../../modals/EditCounter";
import EditTimerColorsModal from "../../modals/EditTimerColorsModal";
import EditTimeseries from "../../modals/EditTimeseries";
import LinkTitleModal from "../../widgets/title/LinkTitleModal";
import EditColorsTrackingBox from "../geo-timer/EditColorsTrackingBox";
import useHandleCopy from "./handlers/useHandleCopy";
import useMenu from "./useMenu";
import EditWidgetModal from "../../EditWidgetModal";
import { format, parseISO } from "date-fns";
import toast from "react-hot-toast";
import { downloadCSV } from "../../widgets/static-table/utils/downloadCsv";

export default function useWidgetMenu(object) {
  const dispatch = useDispatch();
  const items = useMenu();
  const handleCopy = useHandleCopy();
  const navigate = useCustomNavigate();
  const { getParamsByRoute } = useRoute();

  const widgetType = object.schemaTags[3];
  const group =
    object?.objectsToObjectsByObject2Id?.find((item) =>
      item.object1.schemaTags?.includes("group")
    )?.object1 || null;

  const groupPrepared = group ? {
    ...group,
    type: [
      {
        id: getPropertyByKey(group.objectProperties, "generalContainerType")?.id,
        value: getPropertyValueByKey(
          group.objectProperties,
          "generalContainerType"
        ),
      },
    ],
    layouts: [
      {
        id: getPropertyByKey(group.objectProperties, "generalLayouts")?.id,
        value: getPropertyValueByKey(group.objectProperties, "generalLayouts"),
      },
    ],
  } : null;

  const { selectByType } = useSelectWidget({ group: groupPrepared });

  const isShowOptionalColors = () => {
    if ([WIDGETS_ENUM.DATABOX].includes(getWidgetType(object.schemaTags))) {
      return items.getMenuOptionalColors({
        handleAction: () => {
          EditWidgetColorsModal({
            widgetId: object.id,
          })
            .then()
            .catch(() => {});
        },
      });
    }
  };

  const isShowTimerColorsColors = () => {
    if ([WIDGETS_ENUM.TIMER].includes(getWidgetType(object.schemaTags))) {
      return items.getMenuOptionalColors({
        handleAction: () => {
          EditTimerColorsModal({
            widgetId: object.id,
          })
            .then()
            .catch(() => {});
        },
      });
    }
  };

  const isShowTrackingBoxColors = () => {
    if ([WIDGETS_ENUM.GEO_TIMER].includes(getWidgetType(object.schemaTags))) {
      return items.getMenuOptionalColors({
        handleAction: () => {
          EditColorsTrackingBox({
            widgetId: object.id,
          })
            .then()
            .catch(() => {});
        },
      });
    }
  };

  const isShowLinkMenu = () => {
    if ([WIDGETS_ENUM.TITLE].includes(getWidgetType(object.schemaTags))) {
      return items.getLink({
        handleAction: () => {
          LinkTitleModal({
            widgetId: object.id,
          })
            .then()
            .catch(() => {});
        },
      });
    }
  };

  const isDownloadCsv = () => {
    if (
      [WIDGETS_ENUM.STATIC_TABLE].includes(getWidgetType(object.schemaTags))
    ) {
      return items.getGenCsv({
        handleAction: () => {
          const device = object.objectProperties.find((item) => item.key === "settingsObject")?.value;
          downloadCSV(object, device)
        },
      });
    }
  };


  const isDownloadCsvTrackingTable = () => {
    if (
      [WIDGETS_ENUM.TRACKING_TABLE].includes(getWidgetType(object.schemaTags))
    ) {
      return items.getGenCsv({
        handleAction: () => {
          const value =
            object.objectProperties.find((item) => item.key === "valueValue")
              ?.value || [];

          if (value.length > 0) {
            const headers =
              object.objectProperties.find(
                (item) => item.key === "settingsPeriods"
              )?.value || [];

            let prepareForExport = [];

            value.forEach((item) => {
              const localItem = {
                "Date": format(parseISO(item["date"]), "dd-MM-yyyy"),
                "Geotag": item["geotag"],
              };

              item.periods.forEach((itemValueInRow) => {
                const name = headers.find(
                  (item) => item.id === itemValueInRow.id
                )?.period?.join('—');

                if (!name) {
                  localItem[itemValueInRow.id] = itemValueInRow.value;
                } else {
                  localItem[name] = itemValueInRow.value;
                }

              });

              prepareForExport.push(localItem);
            });

            const csvExporter = new ExportToCsv({
              title: object.name,
              fieldSeparator: ",",
              quoteStrings: '"',
              decimalSeparator: ".",
              showLabels: true,
              showTitle: true,
              useTextFile: false,
              filename: object.name,
              useBom: true,
              useKeysAsHeaders: true,
            });

            csvExporter.generateCsv(prepareForExport.reverse());
          } else {
            toast.error("No data available for export");
          }
        },
      });
    }
  };

  const isShowEditChart = () => {
    if ([WIDGETS_ENUM.DATACHART].includes(getWidgetType(object.schemaTags))) {
      return items.getMenuEditChart({
        handleAction: () => {
          EditWidgetChartModal({
            widgetId: object.id,
          })
            .then()
            .catch(() => {});
        },
      });
    }
  };

  const isShowEditColorChart = () => {
    if (
      [WIDGETS_ENUM.DATACHART_COLOR].includes(getWidgetType(object.schemaTags))
    ) {
      return items.getMenuEditChart({
        handleAction: () => {
          EditColorWidgetChartModal({
            widgetId: object.id,
          })
            .then()
            .catch(() => {});
        },
      });
    }
  };

  const isShowEditTableChart = () => {
    if (
      [WIDGETS_ENUM.DATACHART, WIDGETS_ENUM.DATACHART_COLOR].includes(
        getWidgetType(object.schemaTags)
      )
    ) {
      return items.getMenuEditTableChart({
        handleAction: () => {
          EditWidgetTableChartModal({
            widgetId: object.id,
          })
            .then()
            .catch(() => {});
        },
      });
    }
  };

  const isShowHistoryControls = () => {
    if (
      [
        WIDGETS_ENUM.ADVANCED_BUTTON,
        WIDGETS_ENUM.COMMAND_BUTTON,
        WIDGETS_ENUM.STATIC_TABLE,
      ].includes(getWidgetType(object.schemaTags))
    ) {
      return items.genMenuShowControlsHistory({
        handleAction: () => {
          const { dashboardId, reportId, groupId } = getParamsByRoute();

          dispatch(setSettings({ isShowControlsHistory: true }));

          if (dashboardId) {
            navigate(`/boards/${dashboardId}/${groupId}/${object.id}`);
          }

          if (reportId) {
            navigate(`/reports/${reportId}/${groupId}/${object.id}`);
          }
        },
      });
    }
  };

  return [
    items.genMenuEditItem({
      handleAction: () => {
        if (isCounter(object.schemaTags)) {
          EditCounter({ object })
            .then()
            .catch(() => {});
        } else if (isTimeseries(object.schemaTags)) {
          EditTimeseries({ object })
            .then()
            .catch(() => {});
        } else if (isCalculator(object.schemaTags)) {
          EditCalculator({ object })
            .then()
            .catch(() => {});
        } else {
          if (groupPrepared) {
            selectByType({
              widgetType,
              name: object.name,
              widget: object,
              group: groupPrepared,
            });
          } else {
            EditWidgetModal({ object })
              .then()
              .catch(() => {});
          }
        }
      },
    }),
    items.genMenuAccess({
      handleAction: () => {
        ChangeAccessModal({ object, isWidget: true }).then();
      },
    }),
    items.getMenuDisableItem({
      disabled: true,
    }),
    items.genMenuMuteItem({
      disabled: true,
    }),
    ...hideIfEmpty(isShowLinkMenu(), [isShowLinkMenu()]),
    ...hideIfEmpty(isShowOptionalColors(), [isShowOptionalColors()]),
    ...hideIfEmpty(isShowTimerColorsColors(), [isShowTimerColorsColors()]),
    ...hideIfEmpty(isShowTrackingBoxColors(), [isShowTrackingBoxColors()]),
    ...hideIfEmpty(isShowEditChart(), [isShowEditChart()]),
    ...hideIfEmpty(isShowEditColorChart(), [isShowEditColorChart()]),
    ...hideIfEmpty(isShowEditTableChart(), [isShowEditTableChart()]),
    items.genMenuCreateCopy({
      disabled: true,
    }),
    items.genMenuSendItem({
      disabled: true,
    }),
    items.genMenuShowHistory({
      handleAction: () => {
        const { dashboardId, reportId, lang, groupId } = getParamsByRoute();

        dispatch(setSettings({ isShowHistory: true }));

        if (dashboardId) {
          navigate(`/boards/${dashboardId}/${groupId}/${object.id}`);
        }

        if (reportId) {
          navigate(`/reports/${reportId}/${groupId}/${object.id}`);
        }
      },
    }),
    ...hideIfEmpty(isShowHistoryControls(), [isShowHistoryControls()]),
    items.genMenuDownloadNotifications({
      handleAction: () => {
        DateModal({
          downloadHistory: downloadNotifications,
          downloadIds: [object.id],
        })
          .then()
          .catch(() => {});
      },
    }),
    ...hideIfEmpty(isDownloadCsv(), [isDownloadCsv()]),
    ...hideIfEmpty(isDownloadCsvTrackingTable(), [isDownloadCsvTrackingTable()]),
    items.genCopyUUID({
      handleAction: () => handleCopy({ object }),
    }),
    items.genMenuDelete({
      disabled: false,
      handleAction: () => {
        const { groupId } = getParamsByRoute();

        DeleteWidgetModal({
          widgetId: object.id,
          groupId: groupId,
          name: object.name,
        })
          .then()
          .catch(() => {});
      },
    }),
  ];
}
