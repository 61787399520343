import AddIcon from "@mui/icons-material/Add";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import LoadingButton from "@mui/lab/LoadingButton";
import { Box, ListItemSecondaryAction } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import ListSubheader from "@mui/material/ListSubheader";
import Typography from "@mui/material/Typography";
import makeStyles from "@mui/styles/makeStyles";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { dispatch as dispatchBus } from "use-bus";
import useHandleCopy from "../menu/handlers/useHandleCopy";
import PropListItem from "../PropListItem";
import AccessSection from "../basic/AccessSection";
import ServiceSection from "../basic/ServiceSection";
import DescriptionSection from "../basic/DescriptionSection";

const useStyles = makeStyles((theme) => ({
  listSubheader: {
    marginTop: "15px",
    marginBottom: "10px",
  },
  itemToHideOrShow: {
    visibility: "hidden",
    opacity: 1,
    transition: "opacity 0.2s linear",
    [theme.breakpoints.down("sm")]: {
      visibility: "visible",
      opacity: 1,
    },
  },
  itemToHover: {
    "&:hover $itemToHideOrShow": {
      visibility: "visible",
      opacity: 1,
      transition: "opacity 0.2s linear",
    },
  },
}));

const HistoryTableGeneral = (props) => {
  const { item, type, dashboardId, groupId, widgetId } = props;
  const dispatch = useDispatch();

  const classes = useStyles({
    listItem: {
      minHeight: "56px",
    },
  });

  // group properties by groupName (array of properties -> array of groupNames)
  const grouping = (arr, key) =>
    (arr || []).reduce(
      (acc, x = {}) => ({ ...acc, [x[key]]: [...(acc[x[key]] || []), x] }),
      {}
    );

  const groups = grouping(item.objectProperties, "groupName");
  const groupNames = Object.keys(groups);

  const [fakeLoader, setFakeLoader] = useState(false);
  const fakeTrigger = () => {
    setFakeLoader(true);
    setTimeout(() => {
      setFakeLoader(false);
      dispatchBus({ type: "@@iwow/FAKE_UPDATE_HISTORY", id: item.id });
    }, 1000);
  };

  const handleCopy = useHandleCopy();

  return (
    <>
      <List>
        <ListSubheader color="primary" className={classes.listSubheader}>
          <Typography variant="subtitle2">Columns</Typography>
        </ListSubheader>
        {[
          "Property 1",
          "Property 2",
          "Property 3",
          "Property 4",
          "Property 5",
        ].map((item, index) => {
          return (
            <ListItem
              key={index}
              classes={{
                container: classes.itemToHover,
              }}
              style={{ height: "48px", paddingRight: "96px" }}
              button
              onClick={() => {}}
            >
              <ListItemIcon />
              <ListItemText
                primary={<Typography variant="body1">{item}</Typography>}
                onClick={(e) => {}}
              />
              <ListItemSecondaryAction className={classes.itemToHideOrShow}>
                <IconButton size="small" onClick={() => {}}>
                  <MoreVertIcon />
                </IconButton>
              </ListItemSecondaryAction>
            </ListItem>
          );
        })}

        <ListItem style={{ height: "48px" }} button onClick={() => {}}>
          <ListItemIcon>
            <AddIcon />
          </ListItemIcon>
          <ListItemText
            primary={<Typography variant="body1">Add column</Typography>}
          />
        </ListItem>

        <Box m={1} mb={4}>
          <LoadingButton
            loading={fakeLoader}
            onClick={fakeTrigger}
            fullWidth={true}
            variant={"outlined"}
          >
            Get data
          </LoadingButton>
        </Box>

        <ServiceSection classes={classes} item={item} />

        {groupNames.map((groupName) => {
          if (groupName !== "Value") return false;

          const headline = (
            <ListSubheader
              color="primary"
              key={groupName}
              className={classes.listSubheader}
            >
              <Typography variant="subtitle2">{groupName}</Typography>
            </ListSubheader>
          );

          return [
            headline,
            groups[groupName].map((item, index) => (
              <PropListItem
                tab="general"
                key={index}
                index={index}
                obj={props.item}
                item={item}
                dashboardId={dashboardId}
                widgetId={widgetId}
                groupId={groupId}
                type={type}
              />
            )),
          ];
        })}

        <AccessSection classes={classes} item={item} />

        {item.description && <DescriptionSection classes={classes} item={item} />}
      </List>
    </>
  );
};

export default HistoryTableGeneral;
