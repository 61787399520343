import NotificationsIcon from "@mui/icons-material/Notifications";
import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";
import StarBorderIcon from "@mui/icons-material/StarBorder";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import { useTheme } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import makeStyles from "@mui/styles/makeStyles";
import { msg } from "../../../messages";

const useStyles = makeStyles((theme) => ({
  commonHeader: {
    display: "flex",
    flex: 1,
    alignItems: "center",
  },
}));

const CommonHeader = (props) => {
  const { item } = props;
  const classes = useStyles();
  const theme = useTheme();

  return (
    <>
      <div className={classes.commonHeader}>
        <Typography variant="h6" data-test-card={item.name} noWrap={true}>
          {item.name}
        </Typography>

        <div style={{ flexGrow: 1 }} />

        <IconButton
          onClick={(e) => {}}
          size="small"
          style={{ marginRight: "12px", display: "none" }}
        >
          <StarBorderIcon color="primary" />
        </IconButton>
      </div>
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <Typography
          variant="body1"
          noWrap={true}
          style={{ color: theme.palette.gray1, paddingRight: "6px" }}
        >
          {item.enabled ? (
            <span>{msg.sideCard.enabled}</span>
          ) : (
            <span>{msg.sideCard.disabled}</span>
          )}
        </Typography>
        {item.objectProperties.find((obj) => obj.key === "statusAlarm")
          ?.value === "on" && (
          <NotificationsIcon
            fontSize={"small"}
            style={{ color: theme.palette.gray1 }}
          />
        )}
        {item.objectProperties.find((obj) => obj.key === "statusAlarm")
          ?.value === "triggered" && (
          <NotificationsActiveIcon
            fontSize={"small"}
            style={{ color: theme.palette.red }}
          />
        )}
      </Box>
    </>
  );
};

export default CommonHeader;
