import { gql, useLazyQuery } from "@apollo/client";
import { Button, CircularProgress } from "@mui/material";
import Grid from "@mui/material/Grid";
import { useEffect, useState } from "react";
import { create } from "react-modal-promise";
import { msg } from "../../messages";
import CommonModal from "../CommonModal";
import CustomAutocomplete from "../CustomAutocomplete";

const GET_OBJECTS = gql`
  query listObjects {
    objects(
      filter: { schemaType: { in: ["device", "dataset", "application"] } }
      orderBy: NAME_ASC
    ) {
      name
      id
    }
  }
`;

const SelectDevice = (props) => {
  const [loading, setLoading] = useState(false);
  const [objectId, setObjectId] = useState(props.value || null);

  const [loadObjects, { data }] = useLazyQuery(GET_OBJECTS, {
    fetchPolicy: "cache-and-network",
  });

  useEffect(() => {
    loadObjects();
  }, []);

  const list = () => {
    if (data) {
      return data.objects.map((item) => {
        return { value: item.id, title: item.name };
      });
    }
    return [];
  };

  const submit = () => props.onResolve();

  const reject = () => props.onReject();

  const handleSave = async () => {
    setLoading(true);
    props.handleSave(props.id, objectId).finally(() => {
      setLoading(false);
      submit();
    });
  };

  return (
    <CommonModal
      key="EditDevice"
      modalOpen={props.isOpen}
      title={msg.editDeviceModal.title}
      handleClose={reject}
      buttons={
        <>
          <Button color="inherit" onClick={reject}>{msg.default.cancel}</Button>
          <Button color="primary" disabled={loading} onClick={handleSave}>
            {loading ? <CircularProgress size={23} /> : msg.default.save}
          </Button>
        </>
      }
    >
      <Grid container direction="column" spacing={2}>
        <Grid item>
          <CustomAutocomplete
            name="Object"
            label={msg.default.object}
            list={list()}
            value={objectId}
            onChange={(e) => {
              setObjectId(e.target.value);
            }}
          />
        </Grid>
      </Grid>
    </CommonModal>
  );
};

export default create(SelectDevice);
