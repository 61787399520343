import { gql, useLazyQuery } from "@apollo/client";
import React, { useEffect, useState } from "react";
import { useDebounce } from "react-use";
import uniqBy from "lodash.uniqby";
import OnWidgetAutocomplete from "../../base/OnWidgetAutocomplete";
import { orderBy } from "lodash";

export const OBJECTS_QUERY = gql`
    query listDevices($filter: ObjectFilter, $first: Int) {
        objects(orderBy: NAME_ASC, filter: $filter, first: $first) {
            id
            name
        }
    }
`;

export const LINKED_OBJECTS = gql`
    query linkedObjects($id: UUID!, $schemaId: UUID!) {
        object(id: $id) {
            objectsToObjectsByObject1Id(filter: {
                object2: {
                    schemaId: {
                        equalTo: $schemaId
                    }
                    enabled: { equalTo: true }
                }
            }) {
                id
                object2 {
                    id
                    name
                }
            }
        }
    }
`;

const SelectObject = (props) => {
  const [objects, setObjects] = useState([]);
  const [inputValue, setInputValue] = useState(null);
  const [value, setValue] = useState(null);
  const [debouncedValue, setDebouncedValue] = useState(null);

  const [objectsLoad, { loading }] = useLazyQuery(OBJECTS_QUERY, {
    fetchPolicy: "network-only",
    onCompleted: (e) => {
      const objects2 = [
        ...e.objects.map((item) => ({ value: item.id, title: item.name })),
      ];

      if (props.value) {
        objects2.push(props.value);
      }
      setObjects(uniqBy(objects2, "value"));
    },
    variables: {
      first: 20,
    },
  });

  const [linkedObjects, { loading: loadingLinkedObjects }] = useLazyQuery(LINKED_OBJECTS, {
    fetchPolicy: "network-only",
    onCompleted: (e) => {
      const linkedObjects = e.object.objectsToObjectsByObject1Id.map((item) => ({
        value: item.object2.id,
        title: item.object2.name,
      }));
      const objects2 = [...linkedObjects];

      if (props.value) {
        objects2.push(props.value);
      }
      setObjects(uniqBy(objects2, "value"));
    },
  });

  const handleOnOpen = () => {
    if (props.schemaId) {
      if (props.settingsLinkedOnly) {
        linkedObjects({
          variables: {
            id: props.item.id,
            schemaId: props.schemaId,
          },
        });
      } else {
        objectsLoad({
          variables: {
            first: 20,
            filter: {
              schemaId: {
                equalTo: props.schemaId,
              },
              enabled: {
                equalTo: true,
              },
            },
          },
        });
      }
    }
  };

  useDebounce(
    () => {
      if (inputValue !== null) {
        setDebouncedValue(inputValue);
      }
    },
    500,
    [inputValue],
  );

  useEffect(() => {
    if (!props.settingsLinkedOnly) {
      if (props.schemaId && (debouncedValue !== null && debouncedValue !== 0)) {
        objectsLoad({
          variables: {
            first: 20,
            filter: {
              schemaId: {
                equalTo: props.schemaId,
              },
              enabled: {
                equalTo: true,
              },
              name: {
                includesInsensitive: debouncedValue,
              },
            },
          },
        });
      }
    }
  }, [debouncedValue]);


  const handleInputChange = (e) => {
    if (e && typeof e.currentTarget.value === "string") {
      setInputValue(e.currentTarget.value?.trim());
    }
  };

  const handleChange = (e) => {
    setValue(e);
    props.handleChange(e);
  };

  return (
    <OnWidgetAutocomplete
      options={orderBy(objects, ['title'], ['asc'])}
      loading={loading || loadingLinkedObjects}
      handleOnOpen={handleOnOpen}
      value={props.value}
      fgColor={props.fgColor}
      bgColor={props.bgColor}
      label={props.label}
      handleChange={handleChange}
      handleInputChange={handleInputChange}
    />
  );
};

export default SelectObject;
