import React from "react";
import TextField from "@mui/material/TextField";
import { styled } from "@mui/system";
import Autocomplete from "@mui/material/Autocomplete";
import { getBackgroundInput } from "../utils/getBackgroundInput";
import match from "autosuggest-highlight/match";
import parse from "autosuggest-highlight/parse";

const CustomAutocomplete = styled(Autocomplete, {
  shouldForwardProp: (prop) => prop !== "settingsStyle" && prop !== "fgColor",
})(({ fgColor, settingsStyle }) => {
  return {
    '& .MuiOutlinedInput-root .MuiAutocomplete-endAdornment': {
      right: '2px'
    },
    "& .MuiInputBase-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: `${fgColor}`,
      borderWidth: "1px",
    },
    "& .MuiInputBase-root": {
      backgroundColor: getBackgroundInput(settingsStyle),
      height: "32px",
      borderRadius: "5px",
      padding: "0 !important",
      "& .MuiAutocomplete-input::-webkit-input-placeholder": {
        color: fgColor,
        opacity: 1,
      },
      "& .MuiAutocomplete-input": {
        color: fgColor,
        fontSize: "13px",
        padding: "1px 20px 1px 8px !important",
      },

      "& .MuiIconButton-root": {
        color: fgColor,
      },

      "& .MuiOutlinedInput-notchedOutline": {
        borderColor: `${fgColor}`,
        borderWidth: "1px",
      },

      "&:hover .MuiOutlinedInput-notchedOutline": {
        borderColor: fgColor,
      },

    },
  };
});

const OnWidgetAutocomplete = (
  {
    options,
    settingsStyle,
    fgColor,
    value,
    loading,
    label,
    handleChange,
    handleInputChange,
    handleOnOpen,
  }) => {

  return (
    <CustomAutocomplete
      settingsStyle={settingsStyle}
      fgColor={fgColor}
      autoComplete
      ListboxProps={{
        style: {
          maxHeight: "320px",
        },
      }}
      disableClearable
      value={value}
      onChange={(e, value) => {
        handleChange(value);
      }}
      noOptionsText="No objects found"
      loading={loading}
      onOpen={handleOnOpen}
      onInputChange={handleInputChange}
      componentsProps={{
        paper: {
          sx: {
            width: "100%",
            maxWidth: '500px',
          },
        },
        popper: {
          style: {
            width: "fit-content",
          },
          placement: 'bottom-start',
          modifiers: [
            {
              name: "offset",
              options: {
                offset: [0, 8],
              },
            },
          ],
        },
      }}
      isOptionEqualToValue={(option, value) => {
        if (typeof value === "string") {
          return value === option.value;
        } else {
          return value.value === option.value;
        }
      }}
      getOptionLabel={(option) => option.title || ""}
      fullWidth={true}
      options={options}
      disablePortal
      renderInput={(params) => (
        <TextField {...params} size="small" placeholder={label} />
      )}
      renderOption={(props, option, { inputValue }) => {
        const matches = match(option.title, inputValue, {
          insideWords: false,
          requireMatchAll: true,
        });
        const parts = parse(option.title, matches);

        return (
          <li {...props} key={option.value} style={{ textAlign: "left" }}>
            <div>
              {parts.map((part, index) => (
                <span
                  key={index}
                  style={{
                    fontWeight: part.highlight ? 700 : 400,
                  }}
                >
                    {part.text}
                  </span>
              ))}
            </div>
          </li>
        );
      }}
    />
  );
};

export default OnWidgetAutocomplete;
