import { gql, useApolloClient, useMutation } from "@apollo/client";
import { zodResolver } from "@hookform/resolvers/zod";

import { Button } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import Grid from "@mui/material/Grid";
import { debounce } from "lodash";
import { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { create } from "react-modal-promise";
import { useSelector } from "react-redux";
import { z } from "zod";
import { msg } from "../../messages";
import CommonModal from "../CommonModal";
import CustomInput from "../CustomInput";

function asyncDebounce(func, wait) {
  const debounced = debounce(async (resolve, reject, bindSelf, args) => {
    try {
      const result = await func.bind(bindSelf)(...args);
      resolve(result);
    } catch (error) {
      reject(error);
    }
  }, wait);

  // This is the function that will be bound by the caller, so it must contain the `function` keyword.
  function returnFunc(...args) {
    return new Promise((resolve, reject) => {
      debounced(resolve, reject, this, args);
    });
  }

  return returnFunc;
}

const VALIDATE_PASSWORD = gql`
  query validatePasswordChange($newPassword: String, $userId: UUID) {
    validatePasswordChange(newPassword: $newPassword, userId: $userId)
  }
`;

const UPDATE_USER = gql`
  mutation update($input: UpdateUserInput!) {
    updateUser(input: $input) {
      clientMutationId
    }
  }
`;

const ChangePassword = (props) => {
  const client = useApolloClient();
  const [isValidating, setIsValidating] = useState(false);
  const [userUpdate] = useMutation(UPDATE_USER);
  const user = useSelector((state) => state.user);

  const validationSchema = z
    .object({
      password: z
        .string()
        .trim()
        .nonempty({ message: "Password required field" })
        .superRefine(async (value, ctx) => {
          const { valid, comment } = await validFnDb(value);

          if (!valid) {
            ctx.addIssue({
              code: z.ZodIssueCode.custom,
              message: comment,
            });
          }
        }),
      confirm: z
        .string()
        .trim()
        .nonempty({ message: "Password required field" }),
    })
    .refine((data) => data.password === data.confirm, {
      message: "Passwords must be the same",
      path: ["confirm"],
    });

  const {
    control,
    handleSubmit,
    getValues,
    formState: { errors, isSubmitting },
  } = useForm({
    resolver: zodResolver(validationSchema),
    defaultValues: {
      password: "",
      confirm: "",
    },
  });

  const validFn = async (value) => {
    setIsValidating(true);
    try {
      const { data } = await client.query({
        query: VALIDATE_PASSWORD,
        variables: {
          newPassword: value,
        },
      });
      return JSON.parse(data.validatePasswordChange);
    } catch {
      return { isValid: false, comment: "Server error" };
    } finally {
      setIsValidating(false);
    }
  };

  const validFnDb = asyncDebounce(validFn, 500);

  const handleSave = () => {
    userUpdate({
      variables: {
        input: {
          id: user.id,
          patch: {
            password: getValues().password,
          },
        },
      },
    }).then(() => {
      handleClose();
    });
  };

  const submit = () => props.onResolve();

  const handleClose = () => submit();

  return (
    <CommonModal
      key="EditPassword"
      modalOpen={props.isOpen}
      title={"Change password"}
      handleClose={handleClose}
      buttons={
        <>
          <Button color="inherit" onClick={handleClose}>{msg.default.cancel}</Button>
          <Button
            color="primary"
            disabled={isSubmitting}
            onClick={handleSubmit(handleSave)}
          >
            {isSubmitting ? <CircularProgress size={23} /> : msg.default.save}
          </Button>
        </>
      }
    >
      <form>
        <Grid container direction="column" spacing={2}>
          <Grid item>
            <Controller
              name="password"
              control={control}
              render={({ field }) => (
                <CustomInput
                  {...field}
                  loading={isValidating}
                  type={"password"}
                  name="password"
                  id="new-password"
                  autoComplete="new-password"
                  label={"New password"}
                  clearFieldIcon={true}
                  error={!!errors.password?.message}
                  helperText={errors.password?.message}
                />
              )}
            />
          </Grid>
          <Grid item>
            <Controller
              name="confirm"
              control={control}
              render={({ field }) => (
                <CustomInput
                  {...field}
                  type={"password"}
                  name="confirm"
                  autoComplete="new-password"
                  label={"Repeat password"}
                  clearFieldIcon={true}
                  error={!!errors.confirm?.message}
                  helperText={errors.confirm?.message}
                />
              )}
            />
          </Grid>
        </Grid>
      </form>
    </CommonModal>
  );
};

export default create(ChangePassword);
