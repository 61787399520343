import { gql, useLazyQuery, useMutation } from "@apollo/client";
import { Button, CircularProgress } from "@mui/material";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { useEffect, useState } from "react";
import { create } from "react-modal-promise";
import { msg } from "../../messages";
import { isCounter } from "../../utils/objectType";
import CommonModal from "../CommonModal";
import CustomAutocomplete from "../CustomAutocomplete";
import CustomInput from "../CustomInput";
import CustomSwitch from "../CustomSwitch";

const UPDATE_OBJECT = gql`
  mutation update($input: UpdateObjectInput!) {
    updateObject(input: $input) {
      clientMutationId
    }
  }
`;

const GET_SCHEMAS = gql`
  query listSchemas($schemaType: SchemaTypes!) {
    schemata(filter: { type: { equalTo: $schemaType } }) {
      name
      id
    }
  }
`;

const UPDATE_PROPERTY = gql`
  mutation updateProperty($input: UpdateObjectPropertyInput!) {
    updateObjectProperty(input: $input) {
      clientMutationId
    }
  }
`;

const EditCounter = (props) => {
  const [updateObject, { loading }] = useMutation(UPDATE_OBJECT);
  const [updateProperty] = useMutation(UPDATE_PROPERTY);
  const [name, setName] = useState("");
  const [enabled, setEnabled] = useState(true);
  const [description, setDescription] = useState("");

  const [schemaId, setSchemaId] = useState("");
  const [linkedOnly, setLinkedOnly] = useState(false);

  const [loadSchemas, { data }] = useLazyQuery(GET_SCHEMAS, {
    variables: {
      schemaType: "DEVICE",
    },
    fetchPolicy: "cache-and-network",
  });

  const schemaIdProperty = () => {
    return props.object.objectProperties.find(
      (item) => item.key === "settingsSchemaid"
    );
  };

  const linkedOnlyProperty = () => {
    return props.object.objectProperties.find(
      (item) => item.key === "settingsLinkedOnly"
    );
  };

  useEffect(() => {
    setName(props.object.name);
    setEnabled(props.object.enabled);
    setDescription(props.object.description || "");
    loadSchemas();

    if (isCounter(props.object.schemaTags)) {
      setSchemaId(schemaIdProperty().value);
      setLinkedOnly(linkedOnlyProperty().value);
    }
  }, [props.object.id]);

  const schemaNames = () => {
    if (data) {
      return data.schemata.map((item) => {
        return { value: item.id, title: item.name };
      });
    }
    return [];
  };

  const handleSave = async () => {
    await updateObject({
      variables: {
        input: {
          id: props.object.id,
          patch: {
            enabled,
            description,
            name,
          },
        },
      },
    })
      .then(() => {
        if (isCounter(props.object.schemaTags)) {
          return Promise.allSettled([
            updateProperty({
              variables: {
                input: {
                  id: schemaIdProperty().id,
                  patch: {
                    value: schemaId,
                  },
                },
              },
            }),
            updateProperty({
              variables: {
                input: {
                  id: linkedOnlyProperty().id,
                  patch: {
                    value: linkedOnly,
                  },
                },
              },
            }),
          ]);
        } else {
          return Promise.resolve();
        }
      })
      .then(() => {
        submit();
      });
  };

  const submit = () => props.onResolve();
  const reject = () => props.onReject();

  return (
    <CommonModal
      key="EditDevice"
      modalOpen={props.isOpen}
      title={msg.editDeviceModal.title}
      handleClose={reject}
      buttons={
        <>
          <Button color="inherit" onClick={reject}>{msg.default.cancel}</Button>
          <Button color="primary" disabled={loading} onClick={handleSave}>
            {loading ? <CircularProgress size={23} /> : msg.default.save}
          </Button>
        </>
      }
    >
      <Grid container direction="column" spacing={2}>
        <Grid item>
          <CustomInput
            name="name"
            label={msg.addNewObjectModal.name}
            clearFieldIcon={true}
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        </Grid>
        <Grid
          item
          container
          xs={12}
          justifyContent="space-between"
          alignItems="center"
        >
          <CustomSwitch
            name="notificationsEmailNotifications"
            label={msg.addNewObjectModal.enabled}
            value={enabled}
            onChange={(e) => setEnabled(!enabled)}
          />
        </Grid>
        <Grid item>
          <Typography
            color="primary"
            variant="subtitle2"
            style={{ marginBottom: "12px" }}
          >
            {msg.addNewObjectModal.description}
          </Typography>
          <CustomInput
            name="name"
            label={msg.addNewObjectModal.description}
            clearFieldIcon={true}
            value={description}
            multiline={true}
            onChange={(e) => {
              setDescription(e.target.value);
            }}
          />
        </Grid>

        {isCounter(props.object.schemaTags) && (
          <>
            <Grid item>
              <Typography
                color="primary"
                variant="subtitle2"
                style={{ marginBottom: "12px" }}
              >
                Settings
              </Typography>
            </Grid>
            <Grid item>
              <CustomAutocomplete
                name="schemaName"
                label={msg.addNewObjectModal.schemaName}
                list={schemaNames()}
                value={schemaId}
                onChange={(e) => setSchemaId(e.target.value)}
              />
            </Grid>
            <Grid
              item
              container
              xs={12}
              justifyContent="space-between"
              alignItems="center"
            >
              <CustomSwitch
                name="notificationsEmailNotifications"
                label={msg.addNewObjectModal.linkedOnly}
                value={linkedOnly}
                onChange={() => setLinkedOnly(!linkedOnly)}
              />
            </Grid>
          </>
        )}
      </Grid>
    </CommonModal>
  );
};

export default create(EditCounter);
