import { COLOR_SCHEMAS } from "../../../../constants";

const getBackgroundInput = (settingsStyle) => {
  switch (settingsStyle) {
    case COLOR_SCHEMAS.DARK_ON_LIGHT:
      return '#fff'
    default:
      return 'rgba(255, 255, 255, 0.15)'
  }
}

export {
  getBackgroundInput
}
