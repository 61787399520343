import { gql, useApolloClient } from "@apollo/client";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import { useEffect, useReducer, useState } from "react";
import toast from "react-hot-toast";
import { create } from "react-modal-promise";
import CommonModal from "../components/CommonModal";
import CustomAutocomplete from "../components/CustomAutocomplete";
import { msg } from "../messages";
import {
  DEVICES_LINKED_QUERY,
  DEVICES_LINKED_STAT_QUERY,
  DEVICE_PROP_LINKED_QUERY,
  UPDATE_PROP_MUTATION,
  WIDGET_PROP_LINKED_ID_QUERY,
} from "../queries";
import { isMonitoringItem, isMonitoringObject } from "../utils/objectType";
import { sortByDescAndGroup } from "../utils/sortByDescAndGroup";

export const OBJECT_PROPS_QUERY_WITH_FRAGMENTS = gql`
  query listProperties($deviceId: UUID!) {
    object(id: $deviceId) {
      schemaTags
      objectsToObjectsByObject1Id(filter: { forced: { equalTo: 1 } }) {
        object2 {
          id
          name
          schemaTags
          objectProperties(orderBy: [GROUP_NAME_ASC, PROPERTY_ASC]) {
            id
            groupName
            property
            value
            key
            linkedPropertyId
            spec {
              description
              property
              propertyI18N
            }
          }
        }
      }
      objectProperties(orderBy: [GROUP_NAME_ASC, PROPERTY_ASC]) {
        id
        groupName
        property
        key
        linkedPropertyId
        value
        spec {
          description
          property
          propertyI18N
        }
      }
    }
  }
`;

const EditWidgetSourceModal = (props) => {
  const client = useApolloClient();

  const [listOfSources, setListOfSources] = useState([]);
  const [listOfProps, setListOfProps] = useState([]);

  let defaultValues = {};

  const [values, setValues] = useReducer(
    (prev, updated) => ({ ...prev, ...updated }),
    defaultValues
  );

  const setProperties = (result) => {
    if (isMonitoringObject(result.data.object.schemaTags)) {
      const monitoringProperties =
        result.data.object.objectsToObjectsByObject1Id
          .filter((item) => isMonitoringItem(item.object2.schemaTags))
          .map((item) => ({
            title: item.object2.objectProperties.find(
              (prop) => prop.key === "infoName"
            ).value,
            value: item.object2.objectProperties.find(
              (prop) => prop.key === "stateValue"
            ).id,
          }))
          .flat(3);

      setListOfProps([...monitoringProperties]);
    } else {
      const ownProperties = result.data.object.objectProperties.map((item) => ({
        value: item.id,
        groupName: item.groupName,
        description: item.spec.description || item.spec.property,
        title: `${item.groupName} / ${
          item.spec.description || item.spec.property
        }`,
      }));

      const fragmentProperties = result.data.object.objectsToObjectsByObject1Id
        .map((item) =>
          item.object2.objectProperties.map((property) => ({
            ...property,
            fragmentName: item.object2.name,
          }))
        )
        .flat(3)
        .map((item) => ({
          value: item.id,
          groupName: item.groupName,
          description: item.spec.description || item.spec.property,
          title: `${item.fragmentName} / ${item.groupName} / ${
            item.spec.description || item.spec.property
          }`,
        }));

      setListOfProps([...ownProperties, ...fragmentProperties].sort(sortByDescAndGroup));
    }
  };

  // get props of device on device selection
  const handleDeviceChange = async (e) => {
    if (!e.target.value) {
      setListOfProps([]);
      setValues({ property: null });
      return;
    }

    try {
      const result = await client.query({
        query: OBJECT_PROPS_QUERY_WITH_FRAGMENTS,
        variables: { deviceId: e.target.value },
        fetchPolicy: "network-only",
      });

      setProperties(result);
    } catch (err) {
      toast.error(err.toString());
    }
  };

  useEffect(() => {
    const queryProps = async () => {
      try {
        const result_linkedPropId = await client.query({
          query: WIDGET_PROP_LINKED_ID_QUERY,
          variables: { propId: props.propId },
          fetchPolicy: "network-only",
        });

        let result;

        if (props.schemaTags[3] === "datachart") {
          result = await client.query({
            query: DEVICES_LINKED_STAT_QUERY,
            variables: { widgetId: props.widgetId },
            fetchPolicy: "network-only",
          });
        } else {
          result = await client.query({
            query: DEVICES_LINKED_QUERY,
            variables: { widgetId: props.widgetId },
            fetchPolicy: "network-only",
          });
        }

        setListOfSources(
          result.data.objects.map((item) => {
            return { value: item.id, title: item.name };
          })
        );

        if (result_linkedPropId.data.objectProperty.linkedPropertyId) {
          const result_linked = await client.query({
            query: DEVICE_PROP_LINKED_QUERY,
            variables: {
              linkedPropId:
                result_linkedPropId.data.objectProperty.linkedPropertyId,
            },
            fetchPolicy: "network-only",
          });

          const getTargetObjectId = () => {
            const fragments =
              result_linked.data.objectProperty.object
                .objectsToObjectsByObject2Id[0];

            if (fragments.forced === 0) {
              return fragments.object2.id;
            } else {
              return fragments.object1.id;
            }
          };

          const result_linked_property = await client.query({
            query: OBJECT_PROPS_QUERY_WITH_FRAGMENTS,
            variables: { deviceId: getTargetObjectId() },
            fetchPolicy: "network-only",
          });

          setProperties(result_linked_property);
          defaultValues["source"] = getTargetObjectId();
          defaultValues["property"] =
            result_linkedPropId.data.objectProperty.linkedPropertyId;
        } else {
          defaultValues["source"] = null;
          defaultValues["property"] = null;
        }
        setValues(defaultValues);
      } catch (err) {
        toast.error(err.toString());
      }
    }; //queryProps

    queryProps();
  }, []);

  const handleUpdate = async () => {
    try {
      const result_update = await client.mutate({
        mutation: UPDATE_PROP_MUTATION,
        variables: {
          propId: props.propId,
          linkedPropId: values["property"],
        },
      });

      toast.success(msg.editWidgetModal.updated);
    } catch (err) {
      toast.error(err.toString());
    }

    submit();
  }; //handleUpdate

  const submit = () => props.onResolve();
  const reject = () => props.onReject();
  const handleClose = () => reject();

  const handleInputChange = (e) => {
    let { name, value, checked } = e.target;

    if (name === "source") handleDeviceChange(e);

    if (checked) value = checked;

    setValues({ [name]: value });
  };

  return (
    <>
      <CommonModal
        modalOpen={props.isOpen}
        title={msg.editWidgetSourceModal.editSource}
        handleClose={handleClose}
        buttons={
          <>
            <Button
              onClick={handleClose}
              data-test-widget-cancel="WidgetCancel"
            >
              {msg.editWidgetSourceModal.buttonCancel}
            </Button>
            <Button
              color="primary"
              onClick={handleUpdate}
              data-test-widget-update="WidgetUpdate"
            >
              {msg.editWidgetSourceModal.buttonSave}
            </Button>
          </>
        }
      >
        <Grid container direction="column" spacing={2}>
          <Grid item>
            <CustomAutocomplete
              data-test-widget-source="WidgetSource"
              name="source"
              label={msg.editWidgetSourceModal.source}
              list={listOfSources}
              value={values["source"] ?? null}
              onChange={handleInputChange}
              clearFieldIcon={true}
            />
          </Grid>

          <Grid item>
            <CustomAutocomplete
              data-test-widget-property="WidgetProperty"
              name="property"
              label={msg.editWidgetSourceModal.property}
              list={listOfProps}
              value={values["property"] ?? null}
              onChange={handleInputChange}
              clearFieldIcon={true}
            />
          </Grid>
        </Grid>
      </CommonModal>
    </>
  );
};

export default create(EditWidgetSourceModal);
