import dayjs from "dayjs";

const RANGE_PRESETS = [
  {
    label: "Last 7 days",
    value: [dayjs().add(-7, "d"), dayjs()],
  },
  {
    label: "Last 14 days",
    value: [dayjs().add(-14, "d"), dayjs()],
  },
  {
    label: "Last 30 days",
    value: [dayjs().add(-30, "d"), dayjs()],
  },
  {
    label: "Last 90 days",
    value: [dayjs().add(-90, "d"), dayjs()],
  },
  {
    label: "Current month",
    value: [dayjs().startOf('month'), dayjs().endOf('month')],
  },
  {
    label: "Previous month",
    value: [dayjs().subtract(1, 'month').startOf('month'), dayjs().subtract(1, 'month').endOf('month')],
  },
  {
    label: "Previous week",
    value: [dayjs().subtract(1, 'week').startOf('week'), dayjs().subtract(1, 'week').endOf('week')],
  }
];

export {
  RANGE_PRESETS
}
