import { ApolloQueryResult, useApolloClient } from "@apollo/client";
import CloseIcon from "@mui/icons-material/Close";
import { FormControl } from "@mui/material";
import Avatar from "@mui/material/Avatar";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import makeStyles from "@mui/styles/makeStyles";
import React, { FC, useEffect, useState } from "react";
import { ICONS_QUERY } from "../queries";
// eslint-disable-next-line import/extensions
import { castToType } from "../utils/misc.js";
import useMedia from "../utils/useMedia";

type CustomSelectIconType = {
  filter: string[];
  onChange: (e: any) => void;
  name: string;
  label: string;
  clearFieldIcon: boolean;
  value: string;
  disabled?: boolean;
  propType: string;
  "data-test": string;
};

type IconType = { id: string; name: string };

const CustomSelectIcon: FC<CustomSelectIconType> = (props) => {
  const { getImageById } = useMedia();
  const [icons, setIcons] = useState<IconType[]>([]);
  const client = useApolloClient();

  // State needed to change default value of icons
  const [firstIcon, setFirstIcon] = useState("");

  const getIcons = async () => {
    try {
      const result: ApolloQueryResult<{
        objects: IconType[];
      }> = await client.query({
        query: ICONS_QUERY,
        variables: { filter: props.filter },
      });
      setIcons(result.data.objects);
      setFirstIcon(result.data.objects[0]?.id);
    } catch (err) {
      if (err instanceof Error) {
        console.log(err.message);
      }
    }
  }; //getIcons

  useEffect(() => {
    getIcons();
  }, []);

  useEffect(() => {
    if (props.value) {
      const e = {
        target: { name: props.name, value: props.value },
      };
      props.onChange(e);
    } else {
      if (firstIcon) {
        const e = {
          target: { name: props.name, value: firstIcon },
        };
        props.onChange(e);
      }
    }
  }, [props.value, firstIcon]);

  const useStyles = makeStyles((theme) => ({
    root: {
      marginRight: "8px",
      filter: "brightness(0.5)",
      width: "30px",
      height: "30px",
    },
    icon: {
      right: 30,
    },
    selectSelect: {
      paddingLeft: "0px",
    },
    img: {
      objectFit: "contain",
    },
  }));

  const classes = useStyles();

  const handleInputChangeCasted = (event, type) => {
    const { name, value, checked } = event.target;
    const e = {
      target: { name, value: castToType(value, type), checked },
    };
    props.onChange(e);
  };

  return (
    <>
      <FormControl fullWidth variant={"standard"}>
        <InputLabel id={`${props.name}-label`}>{props.label}</InputLabel>
        <Select
          MenuProps={{
            style: {
              maxHeight: "320px",
            },
          }}
          variant="standard"
          name={props.name}
          labelId={`${props.name}-label`}
          label={props.name}
          value={props.value || firstIcon}
          disabled={Boolean(props.disabled)}
          onChange={(e) => {
            handleInputChangeCasted(e, props.propType);
          }}
          {...(props.clearFieldIcon && firstIcon
            ? { classes: { icon: classes.icon, select: classes.selectSelect } }
            : {})}
          endAdornment={
            props.clearFieldIcon &&
            firstIcon && (
              <InputAdornment position="end">
                <IconButton
                  size="small"
                  aria-label="reset"
                  onClick={() => props.onChange({ target: { name: props.name, value: "" } })}
                  edge="end"
                >
                  <CloseIcon />
                </IconButton>
              </InputAdornment>
            )
          }
          renderValue={(id) => {
            return (
              <div style={{ display: "flex", alignItems: "center" }}>
                <img
                  style={{
                    filter: "brightness(0.5)",
                    height: "21px",
                    paddingRight: "8px",
                  }}
                  src={getImageById(id)}
                  alt="icon"
                />
                <span>{icons.find((icon) => icon.id === id)?.name}</span>
              </div>
            );
          }}
          {...props}
        >
          {icons.map((item) => {
            return (
              <MenuItem value={item.id} key={item.id}>
                <div style={{ display: "inline-flex", alignItems: "center" }}>
                  <Avatar src={getImageById(item.id)} classes={classes} variant={"square"}></Avatar>
                  <span>{item.name}</span>
                </div>
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
    </>
  );
};

export default React.memo(CustomSelectIcon);
