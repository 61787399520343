import {
  gql,
  useApolloClient,
  useLazyQuery,
  useMutation,
} from "@apollo/client";
import { Button, FormGroup, Typography } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import Grid from "@mui/material/Grid";
import { loader } from "graphql.macro";
import { useEffect, useReducer } from "react";
import toast from "react-hot-toast";
import { create } from "react-modal-promise";
import CommonModal from "../components/CommonModal";
import { msg } from "../messages";
import { WIDGET_QUERY } from "../queries";
import CustomSwitch from "./CustomSwitch";

const UPDATE_OBJECT_PROPERTY = loader(
  "../graphql/UpdateObjectWithProperties.graphql"
);
const GET_OBJECT = loader("../graphql/GetObjectQuery.graphql");

const EditWidgeTableChartModal = (props) => {
  const [updateProperty] = useMutation(UPDATE_OBJECT_PROPERTY);
  const client = useApolloClient();

  const [loadObject] = useLazyQuery(
    GET_OBJECT,
    {
      variables: { objectId: props.widgetId },
    },
    {
      fetchPolicy: "network-only",
    }
  );

  let defaultValues = {
    settingsTableColumns: {
      min: true,
      max: true,
      average: true,
      current: true,
      median: true,
      sum: true,
    },
  };

  const [values, setValues] = useReducer(
    (prev, updated) => ({ ...prev, ...updated }),
    defaultValues
  );

  const submit = () => props.onResolve();
  const reject = () => props.onReject();

  useEffect(() => {
    const queryProps = async () => {
      try {
        const result = await client.query({
          query: WIDGET_QUERY,
          variables: { objId: props.widgetId },
          fetchPolicy: "network-only",
        });

        const getPropValue = (prop) =>
          result.data.object.objectProperties.find((obj) => obj.key === prop)
            ?.value;
        const getPropByKey = (prop) =>
          result.data.object.objectProperties.find((obj) => obj.key === prop);

        defaultValues["settingsShowTable"] = getPropValue("settingsShowTable");
        defaultValues["settingsTableColumns"] = getPropValue(
          "settingsTableColumns"
        );

        setValues(defaultValues); // loaded values for each field
      } catch (err) {
        toast.error(err.toString());
      }
    };

    queryProps();
  }, []);

  const handleClose = () => reject();

  const handleInputChange = (e) => {
    let { name, value, checked } = e.target;
    if (typeof checked !== "undefined") value = checked;
    setValues({ [name]: value });
  };

  const handleChangeCheckbox = (e) => {
    let { name, value, checked } = e.target;
    setValues({
      ...values,
      settingsTableColumns: {
        ...values.settingsTableColumns,
        [name]: checked,
      },
    });
  };

  return (
    <>
      <CommonModal
        modalOpen={props.isOpen}
        title={"Charts summary"}
        forceTitle={true}
        contentStyles={{
          padding: "14px 16px 16px 14px",
        }}
        handleClose={handleClose}
        buttons={
          <>
            <Button color="inherit" onClick={handleClose}>
              {msg.editWidgetModal.buttonCancel}
            </Button>
            <Button
              color="primary"
              onClick={() => {
                const valuesReady1 = [
                  {
                    propertyKey: "settingsShowTable",
                    value: values.settingsShowTable,
                  },
                  {
                    propertyKey: "settingsTableColumns",
                    value: values.settingsTableColumns,
                  },
                ];

                toast
                  .promise(
                    Promise.resolve().then(() =>
                      updateProperty({
                        variables: {
                          input: {
                            detailedObject: [
                              {
                                objectId: props.widgetId,
                                keyedProperties: valuesReady1,
                              },
                            ],
                          },
                        },
                      })
                    ),
                    {
                      loading: "Updating charts...",
                      success: () => `Charts updated`,
                      error: (err) => `${err.toString()}`,
                    }
                  )
                  .then(() => {
                    return loadObject();
                  })
                  .then(() => {
                    submit();
                  });
              }}
            >
              {msg.editWidgetModal.buttonUpdate}
            </Button>
          </>
        }
      >
        <Grid container direction="column" spacing={2}>
          <Grid
            item
            container
            justifyContent="space-between"
            alignItems="center"
          >
            <CustomSwitch
              name="settingsShowTable"
              label={"Show summary"}
              value={values["settingsShowTable"] ?? ""}
              onChange={handleInputChange}
            />
          </Grid>
          <Grid item>
            <Typography variant="subtitle2" color="primary">
              Columns
            </Typography>
          </Grid>
          <Grid item>
            <FormGroup>
              <FormControlLabel
                disabled={!values["settingsShowTable"]}
                sx={{ mb: 1 }}
                control={
                  <Checkbox
                    checked={values.settingsTableColumns.current}
                    onChange={handleChangeCheckbox}
                    name="current"
                  />
                }
                label="Current"
              />
              <FormControlLabel
                disabled={!values["settingsShowTable"]}
                sx={{ mb: 1 }}
                control={
                  <Checkbox
                    checked={values.settingsTableColumns.max}
                    onChange={handleChangeCheckbox}
                    name="max"
                  />
                }
                label="Maximum"
              />
              <FormControlLabel
                disabled={!values["settingsShowTable"]}
                sx={{ mb: 1 }}
                control={
                  <Checkbox
                    checked={values.settingsTableColumns.min}
                    onChange={handleChangeCheckbox}
                    name="min"
                  />
                }
                label="Minimum"
              />
              <FormControlLabel
                disabled={!values["settingsShowTable"]}
                sx={{ mb: 1 }}
                control={
                  <Checkbox
                    checked={values.settingsTableColumns.average}
                    onChange={handleChangeCheckbox}
                    name="average"
                  />
                }
                label="Average"
              />
              <FormControlLabel
                disabled={!values["settingsShowTable"]}
                sx={{ mb: 1 }}
                control={
                  <Checkbox
                    checked={values.settingsTableColumns.median}
                    onChange={handleChangeCheckbox}
                    name="median"
                  />
                }
                label="Median"
              />
              <FormControlLabel
                disabled={!values["settingsShowTable"]}
                sx={{ mb: 1 }}
                control={
                  <Checkbox
                    checked={values.settingsTableColumns.sum}
                    onChange={handleChangeCheckbox}
                    name="sum"
                  />
                }
                label="Sum"
              />
            </FormGroup>
          </Grid>
        </Grid>
      </CommonModal>
    </>
  );
};

export default create(EditWidgeTableChartModal);
