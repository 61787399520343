import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import isEqual from "lodash/isEqual";

const initialState: {
  [key: string]: unknown;
} = null;

export const profileSlice = createSlice({
  name: "profile",
  initialState,
  reducers: {
    updateProfile: (state, action: PayloadAction<{ [key: string]: unknown }>) => {
      if (!isEqual(state, action.payload)) {
        return { ...state, ...action.payload };
      }
    },
  },
});

export const { updateProfile } = profileSlice.actions;

export const profileReducer = profileSlice.reducer;
