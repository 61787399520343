import { gql, useLazyQuery } from "@apollo/client";
import { useEffect, useState } from "react";
import { useDebounce } from "react-use";
import CustomAutocomplete from "../CustomAutocomplete";

export const OBJECTS_QUERY = gql`
  query listDevices($filter: ObjectFilter, $first: Int) {
    objects(orderBy: NAME_ASC, filter: $filter, first: $first) {
      id
      name
    }
  }
`;

const objectsAutocompletePure = (props) => {
  const [objects, setObjects] = useState([]);
  const [inputValue, setInputValue] = useState("");
  const [value, setValue] = useState("");
  const [debouncedValue, setDebouncedValue] = useState("");

  const [objectsLoad, objectsResult] = useLazyQuery(OBJECTS_QUERY, {
    onCompleted: (e) => {
      setObjects(
        e.objects.map((item) => ({ value: item.id, title: item.name }))
      );
    },
    fetchPolicy: "network-only",
    variables: {
      first: 20,
      filter: {
        ...props.filter,
        enabled: {
          equalTo: true,
        },
        name: {
          includesInsensitive: debouncedValue,
        },
      },
    },
  });

  const [, cancel] = useDebounce(
    () => {
      setDebouncedValue(inputValue);
    },
    500,
    [inputValue]
  );

  useEffect(() => {
    setValue("");
    objectsLoad();
    }, []);

  useEffect(() => {
    if (debouncedValue.length > 3) {
      objectsLoad();
    }
  }, [debouncedValue]);

  useEffect(() => {
    if (props.objectId) {
      const targetValue = objects.find((item) => item.value === props.objectId);

      if (targetValue) {
        handleChange({
          target: {
            name: props.name,
            rawValue: targetValue,
            value: targetValue?.value,
          },
        });
      }
    }
  }, [objects]);

  const handleInputChange = (e) => {
    if (e && e.currentTarget.value !== 0 && e.currentTarget.value) {
      setInputValue(e.currentTarget?.value?.trim());
    }
  };
  const handleChange = (e) => {
    setValue(e.target.value);
    props.handleChange(e);
  };

  return (
    <>
      <CustomAutocomplete
        clearFieldIcon={true}
        error={props.error}
        helperText={props.helperText}
        autoComplete
        filterOptions={(x) => x}
        name={props.name}
        label={"Object name"}
        list={objects}
        value={value}
        loading={objectsResult.loading}
        onChange={handleChange}
        onInputChange={handleInputChange}
      />
    </>
  );
};

export default objectsAutocompletePure;
