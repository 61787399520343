import { gql, useApolloClient, useLazyQuery } from "@apollo/client";
import { useTheme } from "@mui/material/styles";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { highlightSelectedStyle } from "../../constants";
import { msg } from "../../messages";
import { GET_DATA_SUBSCRIPTION } from "../../queries";
import useColors from "../../utils/useColors";
import WidgetEditControls from "../WidgetEditControls";
import { OrientationFormat } from "../create-widget/databox/DataboxTypes";
import { VerticalView } from "./databox/VerticalView";
import { HorizontalView } from "./databox/HorizontalView";

const GET_PROPERTY = gql(`
  query getObjectProperty($id: UUID!) {
      objectProperty(id: $id) {
          id
          spec {
            units
          }
      }
  }
`);

const W_DataBox = (props) => {
  const { id, objectProperties, selected } = props;
  const { getColorBasedOnStyle } = useColors();
  const theme = useTheme();
  const client = useApolloClient();
  const getPropValue = (prop) =>
    objectProperties.find((obj) => obj.key === prop)?.value;
  const mode = getPropValue("settingsFormat");
  const orientation = getPropValue("settingsOrientation");
  const size = getPropValue("settingsSize");
  let iconId = getPropValue("settingsIcon");
  let valueCurrentIcon = getPropValue("valueCurrentIcon");
  let statusAlarmInitial = getPropValue("statusAlarm");
  const valueInitial = getPropValue("valueValue") ?? msg.misc.na;
  const [isShowTitle, setIsShowTitle] = useState(getPropValue("settingsShowTitle"));
  const style = getPropValue("settingsStyle");
  const valueCurrentColor = getPropValue("valueCurrentColor");
  const [valueCurrentLabel, setLabel] = useState(getPropValue("valueCurrentLabel"));
  const [value, setValue] = useState(valueInitial);
  const [unit, setUnit] = useState("");
  const [alarm, setAlarm] = useState(statusAlarmInitial);
  const [colors, setColors] = useState(
    getColorBasedOnStyle(style, valueCurrentColor),
  );
  const isEditMode = useSelector((state) => state.settings.isEditMode);

  const [valueUnitsLazy] = useLazyQuery(GET_PROPERTY);

  useEffect(() => {
    const idLinkedProp = () =>
      objectProperties.find((obj) => obj.key === "valueValue")
        ?.linkedPropertyId;

    if (idLinkedProp()) {
      valueUnitsLazy({
        fetchPolicy: "no-cache",
        variables: {
          id: idLinkedProp(),
        },
      }).then(({ data }) => {
        setUnit(data.objectProperty.spec.units);
      });
    }
  }, [objectProperties.find((obj) => obj.key === "valueValue")]);

  useEffect(() => {
    const observer = client.subscribe({
      query: GET_DATA_SUBSCRIPTION,
      variables: { objId: id },
    });

    const subscription = observer.subscribe(({ data }) => {
      if (data.Objects.relatedNode?.key === "valueValue") {
        setValue(data.Objects.relatedNode?.value);
      } else if (data.Objects.relatedNode?.key === "valueCurrentColor") {
        setColors(getColorBasedOnStyle(style, data.Objects.relatedNode?.value));
      } else if (data.Objects.relatedNode?.key === "valueCurrentLabel") {
        setLabel(data.Objects.relatedNode?.value);
      } else if (data.Objects.relatedNode?.key === "settingsShowTitle") {
        setIsShowTitle(data.Objects.relatedNode?.value);
      } else if (data.Objects.relatedNode?.key === "settingsStyle") {
        setColors(
          getColorBasedOnStyle(
            data.Objects.relatedNode?.value,
            valueCurrentColor,
          ),
        );
      } else if (data.Objects.relatedNode?.key === "statusAlarm") {
        setAlarm(data.Objects.relatedNode?.value);
      }
    });

    return () => subscription.unsubscribe();
  }, [id]);

  useEffect(() => {
    setColors(
      getColorBasedOnStyle(
        getPropValue("settingsStyle"),
        getPropValue("valueCurrentColor"),
      ),
    );
  }, [props.objectProperties]);

  return (
    <>
      <div
        data-test-widget-databox={props.object.name}
        onClick={props.handleClickWidget}
        style={{
          position: "relative",
          height: `100%`,
          backgroundColor: colors?.bg || "transparent",
          justifyContent: "center",
          alignItems: "center",
          pointerEvents: isEditMode ? "none" : "auto",
          filter: selected ? highlightSelectedStyle : "",
          borderRadius: "2px",
          background:
            alarm === "triggered"
              ? `linear-gradient(225deg, ${theme.palette.wRed} ${
                size === "tiny" ? "10px" : "15px"
              }, ${colors?.bg || "transparent"} ${
                size === "tiny" ? "10px" : "15px"
              } )`
              : `${colors?.bg || "transparent"}`,
        }}
      >
        {orientation === OrientationFormat.vertical && <VerticalView
          colors={colors}
          mode={mode}
          size={size}
          value={`${value?.toString() || ""} ${unit}`}
          label={valueCurrentLabel}
          isShowTitle={isShowTitle}
          title={props.object.name}
          iconId={valueCurrentIcon || iconId}
        />}

        {orientation === OrientationFormat.horizontal && <HorizontalView
          colors={colors}
          mode={mode}
          size={size}
          value={`${value?.toString() || ""} ${unit}`}
          label={valueCurrentLabel}
          isShowTitle={isShowTitle}
          title={props.object.name}
          iconId={valueCurrentIcon || iconId}
        />}
        <WidgetEditControls {...props} />
      </div>
    </>
  );
};

export default W_DataBox;
